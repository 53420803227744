import { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useCreate } from '../../../hooks/Api';
import useFocusOnEnter from '../../../components/focus';
import SubmitField from '../../../components/HRMS Components/Button/Submit';
import Status from '../../../components/HRMS Components/Select/Status';
import { Alert } from '../../../components/Model/Model';
import Date_Picker from '../../../components/HRMS Components/Date and Time/Date';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import moment from "moment";

const Add_Leave_Application = () => {
  let pageName = "Leave Application"
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_HRMS_LEAVE_APPLICATION_API?.toString();

  const { mutate: gst, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname);
  const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

  const User = localStorage.getItem('user_id')

  
  const handleReset = () => {
    reset();
  };

  const onSubmit = (branddetail: any) => {
    if (!StartDate && !EndDate) {
      delete branddetail.leave_from
      delete branddetail.leave_to

    } else if (StartDate && !EndDate) {
      delete branddetail.leave_to

    } else if (!StartDate && EndDate) {
      delete branddetail.leave_from
    }
    const formData = new FormData();
    for (const [key, value] of Object.entries(branddetail)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue[0]);
      }
      if (typeof value === "object" && value !== null) {
        formData.append(key, "");
      }
    }
    gst(formData);
  };

  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setShow(true);
      setshowmessage("success");
      navigate("/listLeaveApplication", { state: { showToast: true } });
    } else if (isError) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [data, error]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);

  let options = [
    { label: 'Casual Leave (CL)', value: 'Casual Leave (CL)' },
    { label: 'Sick Leave (SL)', value: 'Sick Leave (SL)' },
    { label: 'Privilege Leave (PL)', value: 'Privilege Leave (PL)' }
  ]

  const [StartDate, setStartDate]: any = useState('')
  const [EndDate, setEndDate]: any = useState('')
  const [forStartDate, setForStartDate]: any = useState('')
  const [forEndDate, setForEndDate]: any = useState('')
  const [totalDays, setTotalDays]: any = useState()

  const handleStartDate = (e: any) => {
    setStartDate(e)
    setForStartDate(e)
  }

  const handleEndDate = (e: any) => {
    setEndDate(e)
    setForEndDate(e)
  }
  const disabledDate: RangePickerProps['disabledDate'] = (current) => { return current && current < dayjs().startOf('day'); };

  useEffect(() => {
    if (StartDate && EndDate) {

      var DateOne = moment([StartDate.$y, StartDate.$M + 1, StartDate.$D]);
      var DateTwo = moment([EndDate.$y, EndDate.$M + 1, EndDate.$D]);
      setTotalDays(DateTwo.diff(DateOne, 'days') + 1)
      setValue("leave_from", `${StartDate.$D}-${StartDate.$M + 1}-${StartDate.$y}`)
      setValue("leave_to", `${EndDate.$D}-${EndDate.$M + 1}-${EndDate.$y}`)
      setValue("leave_duration", totalDays)
      setValue("user", User)
    }
  }, [StartDate, EndDate, totalDays])


  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/listColor" className="text-decoration-none text-black">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black"> HRMS </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/listLeaveApplication" className="text-decoration-none text-black">{pageName}</Link>
                  </li>
                  <li className="breadcrumb-item">Add {pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form ref={focus} onKeyUp={(event) => onEnterKey(event)} onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body" >
                  {show && showmessage === "danger" ? (<Alert color={showmessage} message={error?.response?.data?.message} />) : ("")}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        <Status name='leave_type' label='Leave Type' message='Leave Type is required' register={register} isRequired={true} errors={errors?.leave_type?.message} options={options} />
                        <Date_Picker name='leave_from' label='Start Date' message='Start Date is required' register={register} isRequired={true} hrmserrors={errors?.leave_from?.message} value={forStartDate} isDisabled={disabledDate} onChange={(e: any) => handleStartDate(e)} />
                        <Date_Picker name='leave_to' label='End Date' message='End Date is required' register={register} isRequired={true} hrmserrors={errors?.leave_to?.message} value={forEndDate} isDisabled={disabledDate} onChange={(e: any) => handleEndDate(e)} />
                      </div>
                    </div>
                  </div>
                  <SubmitField Submit={handleSubmit(onSubmit)} Reset={handleReset} Cancel={() => navigate("/listLeaveApplication")} />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Add_Leave_Application