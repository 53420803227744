import React, { useEffect, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../../images/Export/Camera.png";
import pdfLogo from "../../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import { useFetchAccountDropDown, useFetchCityDropDown, useFetchGradeDropDown, useFetchPincodeDropDown, useFetchZoneDropDown } from "../../../hooks/DropDown_Api";
import { DatePicker } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from 'dayjs';
import moment from "moment";
import Modal from "react-bootstrap/esm/Modal";
import { Deletesinglewithdependancy } from "../../../components/modal";
import Table from "../../../components/Dynamic_Table/Table/Table";
import Offcanvas from 'react-bootstrap/Offcanvas';
import "./Address.css"
import Axiosinstance from "../../../hooks/Interceptor";
import Edit_Basic_Details from "../Basic Details/Edit_Basic_Details";
import Edit_Personal_Details from "../Personal Details/Edit_Personal_Details";
import Edit_Contact_Format from "../Contact_Format/Edit_Contact_Format";
import Edit_Address_Details from "../Address_Details/Edit_Address_Details";
import Edit_Sales_Document from "../Sales_Document/Edit_Sales_Document";
import Edit_Bank_Format from "../Bank_Format/Edit_Bank_Format";
import Edit_Customer_Condition from "../Customer Condition/Edit_Customer_Condition";
import Edit_Transport_Format from "../Transport_Format/Edit_Transport_Format";
import Add_Basic_Details from "../Basic Details/Add_Basic_Details";
import Add_Personal_Details from "../Personal Details/Add_Personal_Details";
import Add_Contact_Format from "../Contact_Format/Add_Contact_Format";
import Add_Address_Details from "../Address_Details/Add_Address_Details";
import Add_Sales_Document from "../Sales_Document/Add_Sales_Document";
import Add_Bank_Format from "../Bank_Format/Add_Bank_Format";
import Add_Customer_Condition from "../Customer Condition/Add_Customer_Condition";
import Add_Transport_Format from "../Transport_Format/Add_Transport_Format";

const Edit_Customer_Details = () => {
    let pageName = "Person Details"
    const navigate = useNavigate()
    const { id } = useParams()

    const location = useLocation();
  const stateData = location.state;


    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()

   
    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameForBasicDetails}/${id}`);
                    localStorage.setItem('Add Data',JSON.stringify(response?.data?.data))
                    // Do something with response.data
                } catch (error) {
                    console.error("Error fetching data:", error);
                    // Handle the error appropriately
                }
            }
        };

        fetchData();
    }, [id]);

    const apiname = process.env.REACT_APP_CUSTOMER_DETAILS_API?.toString()
    const { data,refetch:data_refetch } = useFetchSingle(id, apiname)

    const { mutate, isSuccess, isError, error, data: customer_response }: any = useUpdateSingle()
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();
    const [openEditModel, setOpenEditModel] = useState(false)
    const [visitingCard, setVisitingCard] = useState(null)
    const [valuesofform, setValuesofform] = useState<any>()

    const [additionalFields, setAdditionalFields]: any = useState([]);


    const [showOffcancas, setShowOffcancas] = useState(false);

    const handleClose = () => setShowOffcancas(false);
    const handleShow = () => setShowOffcancas(true);


    const onLogoChange = (e: any) => {
        setVisitingCard(e.target.files[0].name)
    }

    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()

    const [addressValue, setAddressValue] = useState('')

    const handleAddressValue = (e: any) => {
        setAddressValue(e.target.value)
        setValue("address", e.target.value)

    }
    const handleAddress = () => {
        const newAddress = {
            address: address,
            city_and_village: city,
            pincode: pincode,

        };
        setAdditionalFields([...additionalFields, newAddress])
        setShowOffcancas(false)
        reset()
    }


    const onSubmit = (Customerdata: any) => {
        // 
        // if (address != "") {
        //     const addressData = {
        //     address: address,
        //     city_and_village: city?.value,
        //     pincode: pincode?.value,
        //     };

        //     delete Customerdata?.city_and_village
        //     delete Customerdata?.pincode
        //     Customerdata["address"] = JSON.stringify([addressData])

        // }


        // if (addressValue != "") {
        //     const addressData = {
        //     address: addressValue,
        //     city_and_village: forCityValue?.value,
        //     pincode: valuePincode?.value,
        //     };

        //     delete Customerdata?.city_and_village
        //     delete Customerdata?.pincode
        //     Customerdata["address"] = JSON.stringify(addressData)

        // }


        if (!visitingCard) {
            delete Customerdata?.customer_visiting_card_image
        }
        if (additionalFields.length == 0) {
            delete Customerdata?.address
            delete Customerdata?.city_and_village
            delete Customerdata?.pincode
        }


        if (isGST === "no") {
            delete Customerdata?.customer_gst_type
            delete Customerdata?.gst_no
            delete Customerdata?.gst_category
        }
        if (isTDS === "no") {
            delete Customerdata?.tds_details_number
        }
        if (isTargettedMonthly === "no") {
            delete Customerdata?.targeted_monthly_amount
        }


        const formData = new FormData();
        for (const [key, value] of Object.entries(Customerdata)) {
            const formvalue: any = value

            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (Array.isArray(value) && key === "city_and_village") {
                value.forEach((val, index) => formData.append(`city_and_village`, val))
            }
            else if (key === "customer_visiting_card_image") {
                if (typeof value === 'object' && value !== null && formvalue.length > 0) {
                    formData.append(key, formvalue[0])
                }
                else {
                    formData.append(key, "")
                }
            }

        }
        if (address != "") {

            let temp = additionalFields?.map((e: any) => {
                return {
                    address: e?.address,
                    city_and_village: e?.city_and_village?.value,
                    pincode: e?.pincode?.value
                }
            })

            formData.append('address', JSON.stringify(temp))

        }

        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }

        mutate(data);
        setValuesofform(data)
    }


    

    const [isGST, setIsGST] = useState("no")
    const [isTDS, setIsTDS] = useState("no")
    const [isTargettedMonthly, setIsTargettedMonthly] = useState("no")







    const handleGSTType = (e: any) => {
        setIsGST(e.target.value)
    }



    const handleTDS = (e: any) => {
        setIsTDS(e.target.value)
    }

    const handleTragettedMonthlyBox = (e: any) => {
        setIsTargettedMonthly(e.target.value)
    }



    const [selectedCity, setSelectedCity]: any = useState("")
    const [selectedAddress, setSelectedAddress]: any = useState("")
    const [forCityValue, setForCityValue]: any = useState("")
    const [cityOptions, setCityOptions]: any = useState("")
    const [totalCity, setTotalCity]: any = useState(0)

    const { data: cityData, refetch: cityRefetch } = useFetchCityDropDown()


    useEffect(() => {
        setCityOptions(cityData)
    }, [cityData])


    const [forState, setForState] = useState("")
    const [State, setState] = useState("")
    const [forCountry, setForCountry] = useState("")
    const [Country, setCountry] = useState("")
    const [forDistrict, setForDistrict] = useState("")
    const [District, setDistrict] = useState("")
    const [forTaluka, setForTaluka] = useState("")
    const [Taluka, setTaluka] = useState("")
    const [address, setAddress] = useState("")
    const [city, setCity]: any = useState()
    const [pincode, setPincode]: any = useState({})

    const handleChangAddress = async (e: any) => {



        setAddress(e.target.value)

    };
    const handleChangeCityVillage = async (e: any) => {


        // setNewProductDiscount(e.target.value)


        // setForCityValue(e?.value)
        setState(e?.state)
        setCountry(e?.country)
        setDistrict(e?.district)
        setTaluka(e?.taluka)
        setCity(e)
        // setValue("city_and_village",e.value)

    };




    const [pincodeValue, setPincodeValue] = useState()
    const handleChangeAddressPincode = async (e: any) => {




        const updatedFields = [...additionalFields];

        const objectToUpdate = updatedFields.find(item => item.pincode === e?.value);

        if (objectToUpdate) {
            objectToUpdate.pincode = e?.value;
        }

        setAdditionalFields(updatedFields);


        setPincode(e)

    };

    const handleChangeCity = (e: any) => {

        setForCityValue(e)
        setValue("city_and_village", e?.value)


        setForState(e?.state)
        setState(e?.state)
        setForCountry(e?.country)
        setCountry(e?.country)
        setForDistrict(e?.district)
        setDistrict(e?.district)
        setForTaluka(e?.taluka)
        setTaluka(e?.taluka)


    }


    const [zoneOptions, setZoneOptions]: any = useState()

    const { data: zoneData, refetch: zoneRefetch } = useFetchZoneDropDown()


    useEffect(() => {
        setZoneOptions(zoneData)
    }, [zoneData])

    const [selectedZone, setSelectedZone]: any = useState("")
    const [valueZone, setValueZone]: any = useState("")


    const handleChangeZone = (e: any) => {

        setValueZone(e)
        setValue("zone", e?.value)

    }


    const [pincodeOptions, setPincodeOptions]: any = useState()

    const { data: pincodeData, refetch: pincodeRefetch } = useFetchPincodeDropDown()


    useEffect(() => {
        setPincodeOptions(pincodeData)
    }, [pincodeData])


    const [valuePincode, setValuePincode]: any = useState("")
    const [selectedPincode, setSelectedPincode]: any = useState("")


    const handleChangePincode = (e: any) => {

        setValuePincode(e)
        setValue("pincode", e?.value)

    }



    const [selectedAccount, setSelectedAccount]: any = useState("")
    const [optionsAccount, setOptionsAccount]: any = useState([])
    const [forAccountValue, setForAccountValue]: any = useState("")

    const { data: accountData, refetch: accountRefetch } = useFetchAccountDropDown()

    useEffect(() => {
        setOptionsAccount(accountData)
    }, [accountData])


    // useEffect(() => {
    //     if (searchTerm.length >= 1) {
    //         accountRefetch()
    //     }
    // }, [])

    const handleChangeAccount = (e: any) => {
        setValue("account_type", e?.value)
        setForAccountValue(e)
    }


    const [selectedGrade, setSelectedGrade]: any = useState("")
    const [forGradeValue, setForGradeValue]: any = useState("")

    const [optionsGrade, setoptionsGrade]: any = useState([])

    const { data: gradeData, refetch: gradeRefetch } = useFetchGradeDropDown()

    useEffect(() => {
        setoptionsGrade(gradeData)
    }, [gradeData])



    const handleChangeGrade = (e: any) => {
        // setValue("customer_grade", e?.value)    
        setForGradeValue(e)
    }



    const [requirementDate, setRequirementDate]: any = useState(null)

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return current && current < dayjs().startOf('day');
    };


    const handleRequirementDate = (e: any) => {

        setRequirementDate(dayjs(e, 'DD/MM/YYYY'))
        setValue("company_date_of_birth", `${e?.$D}-${e?.$M + 1}-${e?.$y}`)
    }



    // let dateOne = data?.company_date_of_birth
    // let fromDateValue = dateOne



    let dateOne = data?.company_date_of_birth
    let fromDateValue = dateOne



    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")
    const [expirationDate, setExpirationDate] = useState()

    const [confirmmodal, setConfirmmodal] = useState(false)

    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setValuesofform(null)
            setShow(true)
            navigate('/listPersonDetail', { state: { showEditToast: true } })
        }

    }, [data, isSuccess, yessuccess,
        nosuccess, customer_response])

    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }


    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    const handlePan = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.toUpperCase())
            .join(' ');
    }





    const [dateValue, setDateValue]: any = useState()

    useEffect(() => {
        if (data) {
            reset(data)

            setForState(data?.address[0]?.city_and_village?.state?.name)
            setForCountry(data?.address[0]?.city_and_village?.country?.name)
            setForDistrict(data?.address[0]?.city_and_village?.district?.name)
            setForTaluka(data?.address[0]?.city_and_village?.taluka?.name)
            setIsGST(data?.gst_type_registration)
            setIsTDS(data?.tds_details)
            setIsTargettedMonthly(data?.targeted_monthly_box)

            setSelectedCity(data?.address[0]?.city_and_village?.id)
            setSelectedAddress(data?.address[0]?.address)
            setValue("address", data?.address[0]?.address)
            setValue("city_and_village", data?.address[0]?.city_and_village?.id)


            setSelectedZone(data?.zone?.id)
            setValue("zone", data?.zone?.id)

            setValue("pincode", data?.address[0]?.pincode?.id)
            setSelectedPincode(data?.address[0]?.pincode?.id)

            setSelectedAccount(data?.account_type?.id)
            setValue("account_type", data?.account_type?.id)
            setSelectedGrade(data.customer_grade?.id)
            setValue("customer_grade", data?.customer_grade?.id)


        }

    }, [data]);



   

    const [isTab,setIsTab] = useState('Basic Details')

    



    const handleDeleteProduct = (id:any) => {
      
        const updatedFields = additionalFields.filter((field:any) => field.id !== id);
     setAdditionalFields(updatedFields);
     
    
   };

    const filter = {



    };

    const addButtonName = "Address";
    const addButtonPageName = "Address"
    const permissionClass = {
        front: "sales",
        back: "salesinquiry"
    }
    const stickyStart = 1;
    const stickyEnd = 2;

    
    
    let rawData = localStorage.getItem('Add Data');
    let addData:any = rawData ? JSON.parse(rawData) : null;

    let personal_detail:any =  addData ? addData?.personal_detail?.id : null;
    let contact_detail = addData ? addData.contact_detail?.id : null;
    let address_detail = addData ? addData.address?.length>0 : null;
    let document = addData ? addData.document?.length>0 : null;
    let bank = addData ? addData.bank?.id : null;
    let customer_condition = addData ? addData.customer_condition : null;
    let transport_detail = addData ? addData.transport_detail?.id : null;

    let rawDataPersonType = localStorage.getItem('PersonType');
    let addDataPersonType = rawDataPersonType ? JSON.parse(rawDataPersonType) : null;
    let personTypeLabel = addDataPersonType?.label === 'Employee'

console.log(bank,"bank");



    useEffect(()=>{
        if(stateData?.detail){
            if(stateData?.detail === 'Basic Details'){
                setIsTab('Basic Details')
            }
            if(stateData?.detail === 'Personal Details'){
                setIsTab('Personal Details')
            }
            if(stateData?.detail === 'Contact Details'){
                setIsTab('Contact Details')
            }
            if(stateData?.detail === 'Addresses'){
                setIsTab('Addresses')
            }
            if(stateData?.detail === 'Document Details'){
                setIsTab('Document Details')
            }
            if(stateData?.detail === 'Bank'){
                setIsTab('Bank')
            }
            if(stateData?.detail === 'Condition'){
                setIsTab('Condition')
            }
            if(stateData?.detail === 'Transport'){
                setIsTab('Transport')
            }
            
        }
    },[stateData])


//     const apinamepersonal= process.env.REACT_APP_SALES_PERSONAL_DETAILS_API?.toString()

//         useEffect(()=>{
//             if(addData?.personal_detail){
                
//                 const getCall = async()=>{
//                 try {
//                     const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinamepersonal}/?id=${addData?.personal_detail}`);
// // setIsTab(response.data.results)
                  
//                   } catch (error) {
//                     console.error('Error:', error);
//                   }
//                 }
//                 getCall()
//             }
//     },[addData])




    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item active">Edit {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="content">


                    <div className="container-fluid">

                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <Link className={isTab==='Basic Details' ? "nav-link active" : "nav-link"} onClick={()=>setIsTab('Basic Details')} aria-current="page" to="">Basic Details</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={isTab==='Personal Details' ? "nav-link active" : "nav-link"} onClick={()=>setIsTab('Personal Details')} aria-current="page" to="">Personal Details</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={isTab==='Contact Details' ? "nav-link active" : "nav-link"} onClick={()=>setIsTab('Contact Details')} aria-current="page" to="">Contact Details</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={isTab==='Addresses' ? "nav-link active" : "nav-link"} onClick={()=>setIsTab('Addresses')} to="">Addresses</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={isTab==='Document Details' ? "nav-link active" : "nav-link"} onClick={()=>setIsTab('Document Details')} to="">Document Details</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={isTab==='Bank' ? "nav-link active" : "nav-link"} onClick={()=>setIsTab('Bank')} to="">Bank</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={isTab==='Condition' ? "nav-link active" : "nav-link"} onClick={()=>setIsTab('Condition')} to="">Condition</Link>
                            </li>
                            {!personTypeLabel ?
                            <li className="nav-item">
                                <Link className={isTab==='Transport' ? "nav-link active" : "nav-link"} onClick={()=>setIsTab('Transport')} to="">Transport</Link>
                            </li>
                            :null}

                        </ul>

                        <div className="">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Cavity</span></h5>
                            </div> */}

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" >
                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                                    {isTab==='Basic Details'? addData?<Edit_Basic_Details/>:<Add_Basic_Details/>:null}
                                                    {isTab==='Personal Details'?personal_detail?<Edit_Personal_Details/>:<Add_Personal_Details/>:null}
                                                    {isTab==='Contact Details'?contact_detail?<Edit_Contact_Format/>:<Add_Contact_Format/>:null}
                                                    {isTab==='Addresses'?address_detail?<Edit_Address_Details/>:<Add_Address_Details/>:null}
                                                    {isTab==='Document Details'?document?<Edit_Sales_Document/>:<Add_Sales_Document/>:null}
                                                    {isTab==='Bank'?bank?<Edit_Bank_Format/>:<Add_Bank_Format/>:null}
                                                    {isTab==='Condition'?customer_condition?<Edit_Customer_Condition/>:<Add_Customer_Condition/>:null}
                                                    {isTab==='Transport'?transport_detail?<Edit_Transport_Format/>:<Add_Transport_Format/>:null}
                                              
                                    </div>
                                    </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
        </div>
    )
}

export default Edit_Customer_Details