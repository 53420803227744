
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import { useFetch, useUpdateSingle } from "../../hooks/Api";
// import Actions from "../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
import noImage from "../../images/noImage.png"
import Modal from "react-bootstrap/esm/Modal";
// import { useFetchGst, useUpdateGst } from "../hooks/GST";


interface Data {
  selection: any

  carton_code: any
  name_and_grade: any
  paper_color: any
  carton_special_running: any
  brand: any
  product_brand_type: any
  material_name_and_type_category_and_hsn_code_and_gst: any
  gst: any
  carton_length: any
  carton_width: any
  carton_height: any
  carton_empty_weight: any
  carton_ply: any
  carton_empty_bunch_packing_nos: any
  carton_moisture: any
  bursting_strength: any
  kan: any
  kan_length: any
  kan_width: any
  printing: any
  printing_length: any
  printing_width: any
  printing_color_name: any
  printing_total_color: any
  carton_image:any
  carton_drawing:any
  
  created_at:any
  created_by:any
  updated_by:any
  updated_at:any


  status: any;

  actions: any;
}


export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },
  {
    id: `carton_code`,
    Header: `Carton Code`,
  },

  {
    id: `name_and_grade`,
    Header: `Name & Grade`,
  },
  {
    id: `paper_color`,
    Header: 'Paper Color',
  },
  {
    id: `printing`,
    Header: 'Printing',
  
  },
  
  {
    id: `brand`,
    Header: 'Brand Name',
   
  },
  {
    id: `brand_type`,
    Header: 'Brand Type',
    
  },
  {
    id: `printing_color_name`,
    Header: 'Printing Color Name',

   
  },
  {
    id: `printing_total_color`,
    Header: 'Total Color',

    
  },
  {
    id: `carton_length`,
    Header: 'Carton Length',
   
  },
  {
    id: `carton_width`,
    Header: 'Carton Width',
   
  },
  {
    id: `carton_height`,
    Header: 'Carton Height',
    
  },
  {
    id: `kan`,
    Header: 'kan',
    
  },
  {
    id: `kan_length`,
    Header: 'Kan Length',
   
  },
  {
    id: `kan_width`,
    Header: 'Kan Width',
   
  },
  {
    id: `carton_ply`,
    Header: 'Carton Ply',
    
  },
  {
    id: `printing_width`,
    Header: 'Printing Width',

   
  },
  {
    id: `printing_length`,
    Header: 'Printing Length',
   
  },
  {
    id: `carton_empty_weight`,
    Header: 'Carton Empty Weight',
   
  },
  {
    id: `carton_empty_bunch_packing_nos`,
    Header: 'Bunch Packing Nos',
   
  },
  {
    id: `carton_special_running`,
    Header:'Special / Running',
   
  },
  {
    id: `hsn`,
    Header: 'HSN Code',
    
  },
  {
    id: `gst`,
    Header: `GST`,
    
  },
  
  {
    id: `bursting_strength`,
    Header: 'Bursting Strength',

  
  },
  
  
  
  {
    id: `carton_moisture`,
    Header: 'Carton Moisture',
    
  },
  
  {
    id: 'status',
    Header: 'Status',
  },
  

  
  
      {
        id: 'created_at',
        Header: 'Created At',
      },
    
    
      {
        id: 'created_by',
        Header: 'Created By',
    
    
      },
      {
        id: 'updated_at',
        Header: 'Updated At',
      },
      {
        id: 'updated_by',
        Header: 'Updated By',
      },
  

  // {
  //   id: 'Actions',
  //   Header: 'Actions',


  // },
 
]


export const Columns_Carton: any = [



  
  {
    id: `srno`,
    Header: () => <div style={{display:"inline"}}>Sr<br/>No</div>,
    sticky:"left",
  },
  {
    id: `carton_code`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Code</div>,
    // Header: `Tape Roll Code`,
    accessor:`carton_code`,
    sticky:"left",
    Cell: ({ row }: any) => row.original.carton_code
  },
  {
    id: 'name_and_type',
    Header: () => <div style={{ display: "inline" }}>Product Name &<br />Type & Category</div>,
    // Header: 'Name And Grade',
    accessor: 'name_and_type',
    // sticky: "left",
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.material_name_and_type_category_and_hsn_code_and_gst?.grade_type?.material_type_name} {row.original.material_name_and_type_category_and_hsn_code_and_gst?.particular_sector_name?.name} {row.original.material_name_and_type_category_and_hsn_code_and_gst?.product_categories?.name}
        </div>
      )
    }

  },
  {
    id: 'hsn_code',
    Header: () => <div style={{ display: "inline" }}>Material Name & Type &<br />Category & HSN & GST%</div>,
    // Header: 'Hsn Code',
    accessor: 'hsn_code',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.material_name_and_type_category_and_hsn_code_and_gst?.grade_type?.material_type_name}::
          {row.original.material_name_and_type_category_and_hsn_code_and_gst?.particular_sector_name?.name === "0.000" || row.original.material_name_and_type_category_and_hsn_code_and_gst?.particular_sector_name?.name === null ? <span style={{ color: 'red', fontSize: "20px" }}>0.000</span> : row.original.material_name_and_type_category_and_hsn_code_and_gst?.particular_sector_name?.name}::
          {row.original.material_name_and_type_category_and_hsn_code_and_gst?.product_categories?.name === "0.000" || row.original.material_name_and_type_category_and_hsn_code_and_gst?.product_categories?.name === null ? <span style={{ color: 'red', fontSize: "20px" }}>****</span> : row.original.material_name_and_type_category_and_hsn_code_and_gst?.product_categories?.name}::
          {row.original.material_name_and_type_category_and_hsn_code_and_gst?.hsn_code}::
          {row.original.material_name_and_type_category_and_hsn_code_and_gst?.gst?.india_GST}
        </div>
      )
    }

  },
  {
    id: 'gst_type',
    Header: () => <div style={{ display: "inline" }}>GST<br />Type</div>,
    // Header: 'Hsn Code',
    accessor: 'gst_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row?.original?.material_name_and_type_category_and_hsn_code_and_gst?.gst_type?.name}
        </div>
      )
    }
  },
  {
    id: 'type_name',
    Header: () => <div style={{ display: "inline" }}>Type Name & Density<br />& Sutra & GST % </div>,
    // Header: 'Hsn Code',
    accessor: 'type_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.material_name_and_type_category_and_hsn_code_and_gst?.grade_type?.material_type_name}::{row.original.material_name_and_type_category_and_hsn_code_and_gst?.grade_type?.material_density ? row.original.material_name_and_type_category_and_hsn_code_and_gst?.grade_type?.material_density : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}::
          {row.original.material_name_and_type_category_and_hsn_code_and_gst?.grade_type?.grade_sutra ? row.original.material_name_and_type_category_and_hsn_code_and_gst?.grade_type?.grade_sutra : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}::{row.original.material_name_and_type_category_and_hsn_code_and_gst?.grade_type?.gst?.india_GST}
        </div>
      )
    }
  },
  {
    id: 'use_code_name',
    Header: () => <div style={{ display: "inline" }}>Use Product Code<br />& Name & Type</div>,
    // Header: 'Hsn Code',
    accessor: 'use_code_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.hsn_code?.use_code_name ? row.original.hsn_code?.use_code_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }
  },
  {
    id: 'use_code_qty',
    Header: () => <div style={{ display: "inline" }}>Use Product<br />Code Qty</div>,
    // Header: 'Hsn Code',
    accessor: 'use_code_qty',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.hsn_code?.use_code_qty ? row.original.hsn_code?.use_code_qty : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }
  },

  {
    id: `material_use`,
    Header: () => <div style={{display:"inline"}}>Material<br/>Use</div>,
    accessor:`material_use`,
    Cell: ({ row }: any) => row.original.material_use?.name
  },

  {
    id: `product_color_name`,
    Header: () => <div style={{display:"inline"}}>Product Color<br/>Name</div>,
    accessor:`product_color_name`,
    Cell: ({ row }: any) => row.original.product_color_name?.company_color_name?row.original.product_color_name?.company_color_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },

  
  {
    id: `product_width_mm`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Width mm</div>,
    accessor: 'product_width_mm',
    Cell: ({ row }: any) => {
      return (
        <>
          <div>
            {row.original.product_width_mm}
          </div>
        </>
      )
    }
  },
  {
    id: `product_length_mm`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Length mm</div>,
    accessor: 'product_length_mm',
    Cell: ({ row }: any) => {
      return (
        <>
          <div>
            {row.original.product_length_mm}
          </div>
        </>
      )
    }
  },
  {
    id: `product_height_mm`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Height mm</div>,
    accessor: 'product_height_mm',
    Cell: ({ row }: any) => {
      return (
        <>
          <div>
            {row.original.product_height_mm}
          </div>
        </>
      )
    }
  },

  {
    id: `product_kan`,
    Header: () => <div style={{display:"inline"}}>Product Kan<br/>Yes-No</div>,
    accessor: 'product_kan',
    Cell: ({row}:any) => {
      const convertToUpper = row.original?.product_kan
      if (!convertToUpper) return "";
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }
  },
  {
    id: `product_kan_width`,
    Header: () => <div style={{display:"inline"}}>Product Kan<br/>Width mm</div>,
    accessor: 'product_kan_width',
    Cell: ({ row }: any) => {
      return (
        <>
          <div>
            {row.original.product_kan_width?row.original.product_kan_width:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
          </div>
        </>
      )
    }
  },
  {
    id: `product_kan_length`,
    Header: () => <div style={{display:"inline"}}>Product Kan<br/>Length mm</div>,
    accessor: 'product_kan_length',
    Cell: ({ row }: any) => {
      return (
        <>
          <div>
            {row.original.product_kan_length?row.original.product_kan_length:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
          </div>
        </>
      )
    }
  },

  {
    id: `product_ply`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Ply</div>,
    accessor: 'product_ply',
    Cell: ({ row }: any) =>{

      return(
      <div>
        {row.original?.product_ply.length}
      </div>
      )
    }
  },
  
  {
    id: `product_sealing_type`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Sealing Type</div>,
    accessor: 'product_sealing_type',
    Cell: ({ row }: any) => {
      return (
        <>
          <div>
            {row.original.product_sealing_type?.name?row.original.product_sealing_type?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
          </div>
        </>
      )
    }
  },

  {
    id: `product_printing`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Printing</div>,
    accessor: 'product_printing',
    Cell: ({row}:any) => {
      const convertToUpper = row.original?.product_printing
      if (!convertToUpper) return "";
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }
  },
  
  {
    id: `product_printing_type`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Printing Type</div>,
    accessor: 'product_printing_type',
    Cell: ({ row }: any) => {
      return (
        <>
          <div>
            {row.original.product_printing_type?.name?row.original.product_printing_type?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
          </div>
        </>
      )
    }
  },

  {
    id: `product_printing_color_name`,
    Header: () => <div style={{display:"inline"}}>Product Printing<br/>Color Name</div>,

    accessor: 'product_printing_color_name',
    Cell: ({ row }: any) => {
      let printingColor = row.original?.product_printing_color_name?.map((e:any)=>{
        return e?.company_color_name
      })
      if (printingColor.length === 0) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return printingColor?.join(",")
    }
  },
  {
    id: `product_printing_total_color`,
    Header: () => <div style={{display:"inline"}}>Product Printing<br/>Total Color</div>,

    accessor: 'product_printing_total_color',
    Cell: ({ row }: any) => {
      return (
        <>
          <div>
            {row.original.product_printing_total_color?row.original.product_printing_total_color:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
          </div>
        </>
      )
    }
  },
  {
    id: `product_brand`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Brand Name</div>,
    accessor: 'product_brand',
    Cell: ({row}:any) => {
      const convertToUpper = row.original.product_brand?.name
      if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }
  },
  {
    id: `product_brand_type`,
    Header: () => <div style={{display:"inline"}}>Brand<br/>Our-Other</div>,
    accessor: 'product_brand_type',
    Cell: ({row}:any) => {
      const convertToUpper = row.original.product_brand?.type
      if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }
  },
  
 
  {
    id: `product_printing_width`,
    Header: () => <div style={{display:"inline"}}>Product Printing<br/>Width mm</div>,

    accessor: 'product_printing_width',
    Cell: ({ row }: any) => {
      return (
        <>
          <div>
            {row.original.product_printing_width?row.original.product_printing_width:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
          </div>
        </>
      )
    }
  },
  {
    id: `product_printing_length`,
    Header: () => <div style={{display:"inline"}}>Product Printing<br/>Length mm</div>,

    accessor: 'product_printing_length',
    Cell: ({ row }: any) => {
      return (
        <>
          <div>
            {row.original.product_printing_length?row.original.product_printing_length:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
          </div>
        </>
      )
    }
  },
  
  {
    id: `product_empty_weight`,
    Header: () => <div style={{display:"inline"}}>Product Empty<br/>Automatic Kg.</div>,
    accessor: 'product_empty_weight',
    Cell: ({ row }: any) =>

      <div>
        {row.original?.product_empty_weight?row.original?.product_empty_weight:<span>0.000</span>}
      </div>
  },
  {
    id: `product_empty_weight_manual `,
    Header: () => <div style={{display:"inline"}}>Product Empty<br/>Manual Kg.</div>,
    accessor: 'product_empty_weight_manual ',
    Cell: ({ row }: any) =>

      <div>
        {row.original?.product_empty_weight_manual?row.original?.product_empty_weight_manual:<span>0.000</span>}
      </div>
  },
  {
    id: `product_empty_bunch_packing_nos`,
    Header: () => <div style={{display:"inline"}}>Product Empty<br/>Bunch Packing</div>,
    accessor: 'product_empty_bunch_packing_nos',
    Cell: ({ row }: any) => {
      return (
        <>
          <div>
            {row.original.product_empty_bunch_packing_nos?row.original.product_empty_bunch_packing_nos:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
          </div>
        </>
      )
    }
  },
  
  {
    id: 'uom',
    Header: () => <div style={{ display: "inline" }}>U.O.M</div>,
    // Header: '1 KG In nos',
    accessor:'uom',
    Cell: ({ row }: any) => {
      const convertToUpper = row.original.uom?.name?.abbreviation
          if (!convertToUpper) return "";
          return convertToUpper.split(" ").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
      // return(
      //   <div>
      //     {row.original.uom?.name?.abbreviation}
      //   </div>
      // )
}

  },

  {
    id: 'product_packing_type',
    Header: () => <div style={{ display: "inline" }}>Product Packing<br/>Type</div>,
    // Header: '1 KG In nos',
    accessor:'product_packing_type',
    Cell: ({ row }: any) => {
    
      return(
        <div>
          {row.original.product_packing_type?.name}
        </div>
      )
}

  },
  {
    id: 'stock_minimum_bunch_qty',
    Header: () => <div style={{ display: "inline" }}>Stock Minimum<br/>Bunch Qty</div>,
    // Header: '1 KG In nos',
    accessor:'stock_minimum_bunch_qty',
    Cell: ({ row }: any) => {
    
      return(
        <div>
          {row.original.stock_minimum_bunch_qty}
        </div>
      )
}

  },
  {
    id: 'stock_maximum_bunch_qty',
    Header: () => <div style={{ display: "inline" }}>Stock Maximum<br/>Bunch Qty</div>,
    // Header: '1 KG In nos',
    accessor:'stock_maximum_bunch_qty',
    Cell: ({ row }: any) => {
    
      return(
        <div>
          {row.original.stock_maximum_bunch_qty}
        </div>
      )
}

  },

  {
    id: `bursting_strength`,
    Header: () => <div style={{display:"inline"}}>Bursting<br/>Strength</div>,

    accessor: 'bursting_strength',
    Cell: ({ row }: any) => {
      return (
        <>
          <div>
            {row.original.bursting_strength}
          </div>
        </>
      )
    }
  },
  {
    id: `carton_moisture`,
    Header: () => <div style={{display:"inline"}}>Carton<br/>Moisture %</div>,
    accessor: 'carton_moisture',
    Cell: ({ row }: any) => {
      return (
        <>
          <div>
            {row.original.carton_moisture?.name}
          </div>
        </>
      )
    }
  },

  {
    id: 'product_by',
    Header: () => <div style={{ display: "inline" }}>Product<br />By</div>,
    // Header: 'Special Or Running',
    accessor: 'product_by',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.product_by?.name}
        </div>
      )
      // if (!convertToUpper) return "";
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      // return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },
  {
    id: 'product_type',
    Header: () => <div style={{ display: "inline" }}>Product<br />Type</div>,
    // Header: 'Special Or Running',
    accessor: 'product_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.product_type?.name}
        </div>
      )
      // if (!convertToUpper) return "";
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      // return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },
  {
    id: 'product_maximum_debit_day',
    Header: () => <div style={{ display: "inline" }}>Product Maximum<br />Debit Day</div>,
    // Header: 'Bursting Strength',
    accessor: 'product_maximum_debit_day',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.product_maximum_debit_day}
        </div>
      )
    }

  },
  {
    id: 'product_location',
    Header: () => <div style={{ display: "inline" }}>Carton<br />Location</div>,
    // Header: 'Special Or Running',
    accessor: 'product_location',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.product_location?.name}
        </div>
      )
      // if (!convertToUpper) return "";
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      // return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },

  
  {
    id: 'material_name',
    Header: () => <div style={{ display: "inline" }}>Material<br />Name</div>,
    accessor:'material_name',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.material_name_and_type_category_and_hsn_code_and_gst?.grade_type?.material_type_name}
        </div>
      )
    }

  },
  {
    id: 'material_type_name',
    Header: () => <div style={{ display: "inline" }}>Material<br />Type</div>,
    accessor:'material_type_name',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.material_name_and_type_category_and_hsn_code_and_gst?.particular_sector_name?.name}
        </div>
      )
    }

  },
  {
    id: 'material_category',
    Header: () => <div style={{ display: "inline" }}>Material<br />Category</div>,
    accessor:'material_category',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.material_name_and_type_category_and_hsn_code_and_gst?.product_categories?.name}
        </div>
      )
    }

  },
  {
    id: 'material_hsn',
    Header: () => <div style={{ display: "inline" }}>HSN<br />Code</div>,
    accessor:'material_hsn',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.material_name_and_type_category_and_hsn_code_and_gst?.hsn_code}
        </div>
      )
    }

  },
 
  {
    id: 'material_gst',
    Header: () => <div style={{ display: "inline" }}>Product<br />GST%</div>,
    accessor:'material_gst',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.material_name_and_type_category_and_hsn_code_and_gst?.gst?.india_GST}
        </div>
      )
    }

  },
  {
    id: 'product_density',
    Header: () => <div style={{ display: "inline" }}>Product<br />Density</div>,
    accessor:'product_density',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.material_name_and_type_category_and_hsn_code_and_gst?.product_density?row.original.material_name_and_type_category_and_hsn_code_and_gst?.product_density:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
    }

  },
  
  
  {
    id: `remark`,
    Header: () => <div style={{display:"inline"}}>Remark</div>,
    accessor: 'remark',
    Cell: ({ row }: any) =>

      <div>
        {row.original?.remark?row.original?.remark:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },
  
  
  
  
  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',
  },
  

  
 
  
    
      {
        id: 'created_at',
        Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
        accessor: 'created_at',
        Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
      },
    
    
      {
        id: 'created_by',
        Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
        accessor: 'created_by',
        Cell: ({ row }: any) => row.original.created_by?.username
    
    
      },
      {
        id: 'updated_at',
        Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
        accessor: 'updated_at',
        Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
      },
      {
        id: 'updated_by',
        Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
        accessor: 'updated_by',
        Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
      },
      {
        id: `product_image`,
        Header: () => <div style={{ display: "inline" }}>Product<br />Image All</div>,
        Cell: ({ row }: any) => {
          const [deleteConfirm, setDeleteConfirm] = useState(false)
          const [currentIndex, setCurrentIndex] = useState(0);
          const [fieldImages, setFieldImages] = useState([])
    
          const handleShowImages = (field: any) => {
            console.log(field, "field")
            setDeleteConfirm(true)
            setFieldImages(field.product_image)
          }
          const goToPrev = () => {
            const isFirstSlide = currentIndex === 0;
            const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
            setCurrentIndex(newIndex);
          };
    
          const goToNext = () => {
            const isLastSlide = currentIndex === fieldImages?.length - 1;
            const newIndex = isLastSlide ? 0 : currentIndex + 1;
            setCurrentIndex(newIndex);
          };
          return (
            <>
              <div >
    
                {!row.original.product_image ? <>
                  <a
    
                    href="#"
    
                  >
                    <img src={noImage} height={60} width={100} />
                  </a>
                </> : <>
                  <a
                    onClick={() => handleShowImages(row.original)}
                    href={`#galleryModal${row.original.name}`}
    
                    data-large-src={(row.original.product_image.length > 0 ? row.original.product_image.at(-1).doc : noImage)} data-toggle="modal">
                    <img src={row.original.product_image[0]?.doc ? row.original.product_image[0]?.doc : noImage} alt='no image' width={50} height={50} />
                  </a>
                </>}
    
              </div>
    
              <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
    
                <Modal.Body>
                  <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
    
                    </div>
    
    
    
                    <div className="carousel-inner">
                      {row.original.product_image?.map((imgSrc: any, index: any) => (
                        <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                          <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                        </div>
                      ))}
                    </div>
    
                    <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> 
                  <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>
    
                </Modal.Footer>
              </Modal>
            </>)
        }
      },
    
    
    
      {
        id: `bunch_packing_image`,
        Header: () => <div style={{ display: "inline" }}>Bunch Packing<br /> Image All</div>,
        Cell: ({ row }: any) => {
          const [deleteConfirm, setDeleteConfirm] = useState(false)
          const [currentIndex, setCurrentIndex] = useState(0);
          const [fieldImages, setFieldImages] = useState([])
    
          const handleShowImages = (field: any) => {
            console.log(field, "field")
            setDeleteConfirm(true)
            setFieldImages(field.bunch_packing_image)
          }
          const goToPrev = () => {
            const isFirstSlide = currentIndex === 0;
            const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
            setCurrentIndex(newIndex);
          };
    
          const goToNext = () => {
            const isLastSlide = currentIndex === fieldImages?.length - 1;
            const newIndex = isLastSlide ? 0 : currentIndex + 1;
            setCurrentIndex(newIndex);
          };
          return (
            <>
              <div >
    
                {!row.original.bunch_packing_image ? <>
                  <a
    
                    href="#"
    
                  >
                    <img src={noImage} height={60} width={100} />
                  </a>
                </> : <>
                  <a
                    onClick={() => handleShowImages(row.original)}
                    href={`#galleryModal${row.original.name}`}
    
                    data-large-src={(row.original.bunch_packing_image.length > 0 ? row.original.bunch_packing_image.at(-1).doc : noImage)} data-toggle="modal">
                    <img src={row.original.bunch_packing_image[0]?.doc ? row.original.bunch_packing_image[0]?.doc : noImage} alt='no image' width={50} height={50} />
                  </a>
                </>}
    
              </div>
    
              <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
    
                <Modal.Body>
                  <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
    
                    </div>
    
    
    
                    <div className="carousel-inner">
                      {row.original.bunch_packing_image?.map((imgSrc: any, index: any) => (
                        <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                          <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                        </div>
                      ))}
                    </div>
    
                    <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> 
                  <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>
    
                </Modal.Footer>
              </Modal>
            </>)
        }
      },
    
    
    
    
      {
        id: `drawing_image`,
        Header: () => <div style={{ display: "inline" }}>Drawing<br />Image All</div>,
        Cell: ({ row }: any) => {
          const [deleteConfirm, setDeleteConfirm] = useState(false)
          const [currentIndex, setCurrentIndex] = useState(0);
          const [fieldImages, setFieldImages] = useState([])
    
          const handleShowImages = (field: any) => {
            console.log(field, "field")
            setDeleteConfirm(true)
            setFieldImages(field.drawing_image)
          }
          const goToPrev = () => {
            const isFirstSlide = currentIndex === 0;
            const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
            setCurrentIndex(newIndex);
          };
    
          const goToNext = () => {
            const isLastSlide = currentIndex === fieldImages?.length - 1;
            const newIndex = isLastSlide ? 0 : currentIndex + 1;
            setCurrentIndex(newIndex);
          };
          return (
            <>
              <div >
    
                {!row.original.drawing_image ? <>
                  <a
    
                    href="#"
    
                  >
                    <img src={noImage} height={60} width={100} />
                  </a>
                </> : <>
                  <a
                    onClick={() => handleShowImages(row.original)}
                    href={`#galleryModal${row.original.name}`}
    
                    data-large-src={(row.original.drawing_image.length > 0 ? row.original.drawing_image.at(-1).doc : noImage)} data-toggle="modal">
                    <img src={row.original.drawing_image[0]?.doc ? row.original.drawing_image[0]?.doc : noImage} alt='no image' width={50} height={50} />
                  </a>
                </>}
    
              </div>
    
              <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
    
                <Modal.Body>
                  <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
    
                    </div>
    
    
    
                    <div className="carousel-inner">
                      {row.original.drawing_image?.map((imgSrc: any, index: any) => (
                        <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                          <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                        </div>
                      ))}
                    </div>
    
                    <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> 
                  <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>
    
                </Modal.Footer>
              </Modal>
            </>)
        }
      },
     
    
    
    
    
        
    
    
      {
        id: `product_pdf`,
        Header: () => <div style={{ display: "inline" }}>Product<br />PDF  All</div>,
    
        Cell: ({ row }: any) => {
          const [deleteConfirm, setDeleteConfirm] = useState(false)
    
          return (
            <>
              <div>
                {!row.original.product_pdf ? <>
                  <a
    
                    href="#"
    
                  >
                    <span className="text-danger">No Document Uploaded</span>
                  </a>
                </> : <>
                  <a
                    onClick={() => setDeleteConfirm(row.original)}
                    href={`#galleryModal${row.original.name}`}
    
                    data-large-src={(row.original.product_pdf.length > 0 ? row.original.product_pdf.at(-1).doc : null)} data-toggle="modal">
                    <p>{(row.original.product_pdf?.length > 0 ? row.original.product_pdf?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}</p>
    
                  </a>
                </>}
                
              </div>
              <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
    
                <Modal.Body>
                  {row.original.product_pdf?.map((document: any) => (
                    <li style={{ color: 'black' }}>
                      <a style={{ color: 'black', textDecoration: 'none' }} target="_blank" href={document?.doc}>
                        {document?.doc?.split('/').pop()}
                      </a>
                    </li>))}
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>
    
                </Modal.Footer>
              </Modal>
            </>
          )
        }
    
      },
      {
        id: `bunch_packing_pdf`,
        Header: () => <div style={{ display: "inline" }}>Bunch Packing<br />PDF All</div>,
        Cell: ({ row }: any) => {
          const [deleteConfirm, setDeleteConfirm] = useState(false)
    
          return (
            <>
              <div>
                {!row.original.bunch_packing_pdf ? <>
                  <a
    
                    href="#"
    
                  >
                    <span className="text-danger">No Document Uploaded</span>
                  </a>
                </> : <>
                  <a
                    onClick={() => setDeleteConfirm(row.original)}
                    href={`#galleryModal${row.original.name}`}
    
                    data-large-src={(row.original.bunch_packing_pdf.length > 0 ? row.original.bunch_packing_pdf.at(-1).doc : null)} data-toggle="modal">
                    <p>{(row.original.bunch_packing_pdf?.length > 0 ? row.original.bunch_packing_pdf?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}</p>
    
                  </a>
                </>}
                
              </div>
              <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
    
                <Modal.Body>
                  {row.original.bunch_packing_pdf?.map((document: any) => (
                    <li style={{ color: 'black' }}>
                      <a style={{ color: 'black', textDecoration: 'none' }} target="_blank" href={document?.doc}>
                        {document?.doc?.split('/').pop()}
                      </a>
                    </li>))}
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>
    
                </Modal.Footer>
              </Modal>
            </>
          )
        }
      },
      {
        id: `drawing_pdf`,
        Header: () => <div style={{ display: "inline" }}>Drawing<br />PDF All</div>,
        Cell: ({ row }: any) => {
          const [deleteConfirm, setDeleteConfirm] = useState(false)
    
          return (
            <>
              <div>
                {!row.original.drawing_pdf ? <>
                  <a
    
                    href="#"
    
                  >
                    <span className="text-danger">No Document Uploaded</span>
                  </a>
                </> : <>
                  <a
                    onClick={() => setDeleteConfirm(row.original)}
                    href={`#galleryModal${row.original.name}`}
    
                    data-large-src={(row.original.drawing_pdf.length > 0 ? row.original.drawing_pdf.at(-1).doc : null)} data-toggle="modal">
                    <p>{(row.original.drawing_pdf?.length > 0 ? row.original.drawing_pdf?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}</p>
    
                  </a>
                </>}
                
              </div>
              <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
    
                <Modal.Body>
                  {row.original.drawing_pdf?.map((document: any) => (
                    <li style={{ color: 'black' }}>
                      <a style={{ color: 'black', textDecoration: 'none' }} target="_blank" href={document?.doc}>
                        {document?.doc?.split('/').pop()}
                      </a>
                    </li>))}
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>
    
                </Modal.Footer>
              </Modal>
            </>
          )
        }
      },

  {
    id: 'Actions',
    Header: 'Actions',


  },
  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Carton, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}carton/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



