import { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import InputField from '../../../components/HRMS Components/Inpute/input';
import { useCreate } from '../../../hooks/Api';
import useFocusOnEnter from '../../../components/focus';
import SubmitField from '../../../components/HRMS Components/Button/Submit';
import ReactSelect from '../../../components/HRMS Components/Select/select';
import { Alert } from '../../../components/Model/Model';
import Date from '../../../components/HRMS Components/Date and Time/Date';
import { useFetchBankAccountDropDown } from '../../../hooks/DropDown_Api';
import DatePicker, { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import Date_Picker from '../../../components/HRMS Components/Date and Time/Date';

const Add_Bank_Transaction = () => {

  let pageName = "Bank Transaction"
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_ACCOUNTING_BANK_TRANSACTION_ENTRY_API?.toString();

  const { mutate: gst, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname);
  const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();
  const [OptionsBankAccount, setOptionsBankAccount]: any = useState([]);
  const [BankAccount, setBankAccount]: any = useState();
  const { data: BankAccountData, refetch: BankAccountRefetch } = useFetchBankAccountDropDown()

  useEffect(() => {
    setOptionsBankAccount(BankAccountData)
  }, [BankAccountData])

  const handleChangeBankAccount = (e: any) => {
    setBankAccount(e)
    setValue("bank_account", e?.value)
  }

  const [OptionsTransactionType, setOptionsTransactionType]: any = useState([])
  const [TransactionType, setTransactionType]: any = useState([])
  useEffect(() => {
    const Data = [
      { label: 'Bank Deposit', value: 'Deposit' },
      { label: 'Bank Withdraw', value: 'Withdraw' },
    ]
    setOptionsTransactionType(Data)
  }, [])

  const handleTransactionType = (e:any) =>{
    setTransactionType(e)
    setValue("type",e?.value)
  }

  const [forDate, setForDate]: any = useState('')
  const handleDate = (date: any,dateString:any) => {
    
    setForDate(date)
    const hours = date?.H  
    const minutes = date?.m
    console.log(hours,"hours",minutes);
    
    setValue("in_date_time",  dateString)

  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => { return current && current < dayjs().startOf('day'); };

  const handleReset = () => {
    reset();
  };

  const onSubmit = (branddetail: any) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(branddetail)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue[0]);
      }
      if (typeof value === "object" && value !== null) {
        formData.append(key, "");
      }
    }
    gst(formData);
  };


  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setShow(true);
      setshowmessage("success");
      navigate("/listBankTransaction", { state: { showToast: true } });
    } else if (isError) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [data, error]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/listColor" className="text-decoration-none text-black">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black"> HRMS </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/listBankTransaction" className="text-decoration-none text-black">{pageName}</Link>
                  </li>
                  <li className="breadcrumb-item">Add {pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form ref={focus} onKeyUp={(event) => onEnterKey(event)} onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body" >
                  {show && showmessage === "danger" ? (<Alert color={showmessage} message={error?.response?.data?.message} />) : ("")}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        <ReactSelect label='Transaction Type' name='type' message='Transaction Type is required' register={register} isRequired={true} isModal={false} isEdit={false} isMulti={false} errors={errors?.type?.message} options={OptionsTransactionType}  Selvalue={TransactionType} onChange={(e)=>handleTransactionType(e)}/>
                        <ReactSelect label='Transaction Account' name='bank_account' message='Transaction Account is required' register={register} isRequired={true} isModal={false} isEdit={false} isMulti={false} errors={errors?.bank_account?.message} options={OptionsBankAccount} Selvalue={BankAccount} onChange={(e) => handleChangeBankAccount(e)} />
                        <Date_Picker name='in_date_time' label='Date Time' isPencil={true} message='Date is required' isShowTime={true} register={register} isRequired={true} hrmserrors={errors?.in_date_time?.message} value={forDate} isDisabled={disabledDate} onChange={(date, dateString) => handleDate(date, dateString)} />
                        <InputField type='text' name='note' label='Bank Transaction Note' isPencil={true} message='Bank Transaction note is required' register={register} isRequired={true} hrmserrors={errors?.note?.message} />
                        <InputField type='number' name='amount' label='Bank Transaction Amount' isPencil={true} message='Bank Transaction Amount is required' register={register} isRequired={true} hrmserrors={errors?.amount?.message} />

                      </div>
                    </div>
                  </div>
                  <SubmitField Submit={handleSubmit(onSubmit)} Reset={handleReset} Cancel={() => navigate("/listBankTransaction")} />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Add_Bank_Transaction