import React, { useEffect, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGSTEdit, useUpdateGst } from "../../hooks/GST";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../hooks/Api";
import Select from "react-select"
import axios from 'axios'
import { useFetchMouldMachineDropDown, useFetchMachineLocationDropDown, useFetchMaterialUseDropDown, useFetchMouldHSNDropDown, useFetchMouldBrandDropDown, useFetchMouldCompanyNameDropDown, useFetchMouldShapeDropDown } from "../../hooks/DropDown_Api";
import { Alert } from "../../components/Model/Model";
import moment from "moment";
import dayjs from 'dayjs';
import { DatePicker, Upload, UploadFile } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import { Deletesinglewithdependancy } from "../../components/modal";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
import CreatableSelect from "react-select/creatable";
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import { HSN_Model } from "../../components/Dynamic_Form_Models/HSN_Model";
import { Machine_Model } from "../../components/Dynamic_Form_Models/Machine_Modle";

import Modal from "react-bootstrap/Modal";
import Actions, { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}


interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});


const Edit_Mould = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_MOULD_API?.toString()
    const { data } = useFetchSingle(id, apiname)
    const { mutate, isSuccess, isError, error, data: gstUpdated_response, isLoading }: any = useUpdateSingle()

    const { register, handleSubmit, setError, formState: { errors, isDirty }, reset, setValue, getValues }: any = useForm();
    const [openMachine, setOpenMachine] = useState(false)

    // Mould Shape Drop Down
    const [optionsMouldShape, setOptionsMouldShape]: any = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [mould_shapeId, setMould_shapeId] = useState(0)

    const [options, setOptions]: any = useState([])
    const [logo, setlogo]: any = useState([])
    const [logoPdf, setlogoPdf]: any = useState([])
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const [expirationDate, setExpirationDate] = useState()
    const [openHSN, setOpenHSN] = useState(false)

    const [selMachine, setSelMachine] = useState('')
    const [selectedMachine, setselectedMachine] = useState('')
    const [selMachineMould, setSelMachineMould] = useState('')
    const [openEditModel, setOpenEditModel] = useState(false)

    const [Warranty, setWarranty] = useState()
    const onWarrantyChange = (e: any) => {
        setWarranty(e.target.value)
    }
    const [Guarantee, setGuarantee] = useState()
    const onGuaranteeChange = (e: any) => {
        setGuarantee(e.target.value)
    }


    const [selectedMachineMould, setSelectedMachineMould]: any = useState([])
    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()

    const [valueCavity, setValueCavity]: any = useState();
    const [valueCavityShow, setValueCavityShow]: any = useState();
    // console.log(valueCavity, "valueCavity");

    const [isLoadingValue, setIsLoadingValue] = useState(false);
    const [selectedCavity, setSelectedCavity] = useState('')
    const [valueCavityCreatable, setValueCavityCreatable] = useState<Option | null>();

    const apinameForCavity = process.env.REACT_APP_MOULD_SHAPE_API?.toString()

    const { mutate: mutateCavity, data: dataCavity, isError: cavityIsError, isLoading: cavityLoading, isSuccess: cavitySuccess, error: cavityError }: any = useCreate(apinameForCavity)


    const { data: mouldShapeData, refetch: mouldShapeRefetch } = useFetchMouldShapeDropDown(searchTerm)

    useEffect(() => {
        setOptionsMouldShape(mouldShapeData)
    }, [mouldShapeData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            mouldShapeRefetch()
        }
    }, [])



    const handleChangeMouldShape = (e: any) => {

        mouldShapeRefetch()

        setApiName(apinameForCavity)
        setDeleteApiName(apinameForCavity)
        setEditId(e?.value)
        setEditData(mouldShapeData)
        // setValueGumName(e)
        setRenderKey(prevKey => prevKey + 1);


        setValueCavity(e)
        setValueCavityShow(e)
        setValue("mould_shape", e?.value)
        setValueCavityCreatable(null)
    }

    const handleCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateCavity({ name: newOption.name, status: newOption.status })
            setOptionsMouldShape((prev: any) => [...prev, newOption]);
            setValueCavityCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (cavitySuccess && optionsMouldShape) {
                await mouldShapeRefetch();
                setValue("mould_shape", mouldShapeData[0].value)
            }

        })();

        return () => {
        };
    }, [cavitySuccess, mouldShapeData]);
    const { data: machineData, refetch: machineRefetch } = useFetchMouldMachineDropDown()
    useEffect(() => {

        setOptions(machineData)
    }, [machineData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            machineRefetch()
        }
    }, [])


    const handleChangeMachine = (e: any) => {

        const machine = e.map((value: any) => {
            return value.value
        })
        // setValue("color", color)
        setSelMachineMould(e)
        setSelectedMachineMould([])

        setSelMachineMould(e)
        setValue("tfm_machine_usd_mould", machine)
        // setSelMachine(e)
    }

    const [uploadFile, setUploadFile]: any = useState<UploadFile[]>([])
    const [uploadFileDoc, setUploadFileDoc]: any = useState<UploadFile[]>([])


    const [optionsHSN, setOptionsHSN]: any = useState([])
    const [selectedHSN, setSelectedHSN]: any = useState("")
    const [selHSN, setSelHSN] = useState("")




    const { data: HSNData, refetch: HSNRefetch } = useFetchMouldHSNDropDown()

    useEffect(() => {
        setOptionsHSN(HSNData)
    }, [HSNData])

    const handleChangeHSN = (e: any) => {
        setSelHSN(e)
        setValue("mould_name_type_category_hsn_code_and_gst", e?.value)

    }


    const apinameForMouldUse = process.env.REACT_APP_MATERIAL_USE_API?.toString()
    const { mutate: mutateMouldUse, data: dataMouldUse, isError: MouldUseIsError, isLoading: MouldUseLoading, isSuccess: MouldUseSuccess, error: MouldUseError }: any = useCreate(apinameForMouldUse)

    const [valueMouldUse, setValueMouldUse]: any = useState();
    const [selectedMouldUse, setSelectedMouldUse]: any = useState();
    const [optionsMouldUse, setOptionsMouldUse]: any = useState([]);
    const [valueMouldUseCreatable, setValueMouldUseCreatable] = useState<Option | null>();

    const { data: MouldUseData, refetch: MouldUseRefetch } = useFetchMaterialUseDropDown()

    useEffect(() => {
        setOptionsMouldUse(MouldUseData)
    }, [MouldUseData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            MouldUseRefetch()
        }
    }, [])


    const handleChangeMouldUse = (e: any) => {

        MouldUseRefetch()

        setApiName(apinameForMouldUse)
        setDeleteApiName(apinameForMouldUse)
        setEditId(e?.value)
        setEditData(MouldUseData)
        // setValueMouldUse(e)
        setRenderKey(prevKey => prevKey + 1);

        setValueMouldUse(e)
        setValue("mould_special_running", `${e?.value}`)
        setValueMouldUseCreatable(null)
    }





    const handleCreateMouldUse = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMouldUse({ name: newOption.name, status: newOption.status })
            setOptionsMouldUse((prev: any) => [...prev, newOption]);
            setValueMouldUseCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MouldUseSuccess && optionsMouldUse) {
                await MouldUseRefetch();
                setValue("mould_special_running", MouldUseData[0].value)
            }

        })();

        return () => {
        };
    }, [MouldUseSuccess, MouldUseData]);

    const [optionsMouldLocation, setOptionsMouldLocation]: any = useState()
    const [valueMouldLocationCreatable, setValueMouldLocationCreatable] = useState<Option | null>();
    const [valueMouldLocation, setValueMouldLocation]: any = useState();
    const [selectedLocation, setSelectedLocation]: any = useState();
    const apinameForMouldLocation = process.env.REACT_APP_MACHINE_LOCATION_API?.toString()
    const { mutate: mutateMouldLocation, data: dataMouldLocation, isError: MouldLocationIsError, isLoading: MouldLocationLoading, isSuccess: MouldLocationSuccess, error: MouldLocationError }: any = useCreate(apinameForMouldLocation)



    const { data: MouldLocationData, refetch: MouldLocationRefetch } = useFetchMachineLocationDropDown()
    // console.log(machineLocationData, "optionsLocation");
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsMouldLocation(MouldLocationData)
    }, [MouldLocationData])
    const handleChangeMouldLocation = (e: any) => {

        MouldLocationRefetch()

        setApiName(apinameForMouldLocation)
        setDeleteApiName(apinameForMouldLocation)
        setEditId(e?.value)
        setEditData(MouldLocationData)
        setValueMouldLocation(e)
        setRenderKey(prevKey => prevKey + 1);


        // setValueMouldLocation(e)
        setValue("mould_location", `${e?.value}`)
        setValueMouldLocationCreatable(null)
    }

    const handleCreateMouldLocation = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMouldLocation({ name: newOption.name, status: newOption.status })
            setOptionsMouldLocation((prev: any) => [...prev, newOption]);
            setValueMouldLocationCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MouldLocationSuccess && optionsMouldLocation) {
                await MouldLocationRefetch();
                setValue("mould_location", MouldLocationData[0]?.value)
            }

        })();

        return () => {
        };
    }, [MouldLocationSuccess, MouldLocationData]);


    const [valueMouldBrandCreatable, setValueMouldBrandCreatable] = useState<Option | null>();
    const [valueMouldBrand, setValueMouldBrand]: any = useState();
    const [optionsMouldBrand, setOptionsMouldBrand]: any = useState();
    const [selectedBrand, setSelectedBrand]: any = useState();
    const apinameForMouldBrand = process.env.REACT_APP_MOULD_BRAND_NAME_API?.toString()
    const { mutate: mutateMouldBrand, data: dataMouldBrand, isError: MouldBrandIsError, isLoading: MouldBrandLoading, isSuccess: MouldBrandSuccess, error: MouldBrandError }: any = useCreate(apinameForMouldBrand)



    const { data: MouldBrandData, refetch: MouldBrandRefetch } = useFetchMouldBrandDropDown()
    // console.log(MouldBrandData, "optionsBrand");
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsMouldBrand(MouldBrandData)
    }, [MouldBrandData])
    const handleChangeMouldBrand = (e: any) => {


        MouldBrandRefetch()

        setApiName(apinameForMouldBrand)
        setDeleteApiName(apinameForMouldBrand)
        setEditId(e?.value)
        setEditData(MouldBrandData)
        setValueMouldBrand(e)
        setRenderKey(prevKey => prevKey + 1);


        setValue("mould_brand", `${e?.value}`)
        setValueMouldBrandCreatable(null)
    }

    const handleCreateMouldBrand = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMouldBrand({ name: newOption.name, status: newOption.status })
            setOptionsMouldBrand((prev: any) => [...prev, newOption]);
            setValueMouldBrandCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MouldBrandSuccess && optionsMouldBrand) {
                await MouldBrandRefetch();
                setValue("mould_brand", MouldBrandData[0]?.value)
            }

        })();

        return () => {
        };
    }, [MouldBrandSuccess, MouldBrandData]);


    const apinameForCompanyName = process.env.REACT_APP_MOULD_COMPANY_NAME_API?.toString()
    const { mutate: mutateCompanyName, data: dataCompanyName, isError: CompanyNameIsError, isLoading: CompanyNameLoading, isSuccess: CompanyNameSuccess, error: CompanyNameError }: any = useCreate(apinameForCompanyName)

    const [valueCompanyName, setValueCompanyName]: any = useState();
    const [selectedCompany, setSelectedCompany]: any = useState();
    const [optionsCompanyName, setOptionsCompanyName]: any = useState([]);
    const [valueCompanyNameCreatable, setValueCompanyNameCreatable] = useState<Option | null>();

    const { data: CompanyNameData, refetch: CompanyNameRefetch } = useFetchMouldCompanyNameDropDown()

    useEffect(() => {
        setOptionsCompanyName(CompanyNameData)
    }, [CompanyNameData])




    const handleChangeCompanyName = (e: any) => {

        CompanyNameRefetch()

        setApiName(apinameForCompanyName)
        setDeleteApiName(apinameForCompanyName)
        setEditId(e?.value)
        setEditData(CompanyNameData)
        // setValueCompanyName(e)
        setRenderKey(prevKey => prevKey + 1);

        setValueCompanyName(e)
        setValue("mould_company", `${e?.value}`)
        setValueCompanyNameCreatable(null)
    }





    const handleCreateCompanyName = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateCompanyName({ name: newOption.name, status: newOption.status })
            setOptionsCompanyName((prev: any) => [...prev, newOption]);
            setValueCompanyNameCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (CompanyNameSuccess && optionsCompanyName) {
                await CompanyNameRefetch();
                setValue("mould_company", CompanyNameData[0].value)
            }

        })();

        return () => {
        };
    }, [CompanyNameSuccess, CompanyNameData]);

    useEffect(() => {
        if (valueCavityShow?.mouldA === false) {
            setValue("product_mould_diameter_mm", "")
        }
        if (valueCavityShow?.mouldB === false) {
            setValue("product_length_diameter", "")
        }
        if (valueCavityShow?.mouldC === false) {
            setValue("product_width_diameter", "")
        }

    }, [valueCavityShow])

    const [logoId, setLogoId] = useState([])
    const [ProductDrawingId, setProductDrawingId] = useState([])


    useEffect(() => {
        if (data) {
            reset(data)
            setWarranty(data?.warranty_yes_no)
            setGuarantee(data?.guarantee_yes_no)
            setselectedMachine(data?.mould_shape?.id)
            setValue("mould_shape", data?.mould_shape?.id)
            setSelectedHSN(data.mould_name_type_category_hsn_code_and_gst?.id)
            setValue("mould_name_type_category_hsn_code_and_gst", data.mould_name_type_category_hsn_code_and_gst?.id)
            setSelectedCavity(data?.mould_shape?.id)
            setValue("mould_shape", data?.mould_shape?.id)
            setSelectedMachineMould(data?.tfm_machine_usd_mould?.map((e: any) => e.id))
            setValue("tfm_machine_usd_mould", data?.tfm_machine_usd_mould?.map((e: any) => e.id))
            setValueCavityShow(data?.mould_shape)
            setSelectedMouldUse(data?.mould_special_running?.id)
            setValue("mould_special_running", data?.mould_special_running?.id)
            setSelectedLocation(data?.mould_location?.id)
            setValue("mould_location", data?.mould_location?.id)
            setSelectedBrand(data?.mould_brand?.id)
            setValue('mould_brand', data?.mould_brand?.id)
            setSelectedCompany(data?.mould_company?.id)
            setValue('mould_company', data?.mould_company?.id)
            setValueProductionPriceList(data?.product_list_diameter_mm)
            setValueFormingCuttingLength(data?.forming_cutting_area_sheet_length_mm)
            setValueFormingCuttingWidth(data?.forming_cutting_area_sheet_width_mm)
            setValueMouldCutterGap(data?.cutter_gap)
            setValueTFMMaximum(data?.tfm_chain_infield_maximum_mm)
            setValueTFMMinimum(data?.tfm_chain_infield_minimum_mm)
            setValueCavityHoleHeight(data?.mould_cavity_hole_height_mm)
            setValueMouldLip(data?.mould_lip_mm)
            setValueSize(data?.product_mould_diameter_mm)
            setValueLength(data?.product_length_diameter)
            setValueWidth(data?.product_width_diameter)
            if (data?.purchase_date) {

                setToDate(dayjs(data?.purchase_date, 'DD/MM/YYYY'))
            }
            if (data?.manufacture_date) {

                setForFromDate(dayjs(data?.manufacture_date, 'DD/MM/YYYY'))
            }

            let tempImages = data?.image?.map((img: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${index}`,
                    name: `image-${index}.jpg`,
                    status: 'done',
                    url: img?.doc,
                    thumbUrl: img?.doc,
                }

                return ctx
            })
            let tempPdf = data?.pdf?.map((pdf: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${pdf?.id}`,
                    name: `${pdf.title}`,
                    status: 'done',
                    url: pdf?.doc,
                    thumbUrl: pdf?.doc,
                }

                return ctx
            })

            setUploadFileDoc(tempPdf)

            setUploadFile(tempImages)
        }
    }, [data]);


    const onSubmit = (materialtypeDetails: any) => {
        if (Guarantee === "no") {
            materialtypeDetails.guarantee_months = ""
        }
        if (Warranty === "no") {
            materialtypeDetails.warranty_time = ""
        }

        if (valueCavityShow?.mouldA === false) {
            materialtypeDetails.cavity_size_mm = ""
        }
        if (valueCavityShow?.mouldB === false) {
            materialtypeDetails.cavity_length_mm = ""
        }
        if (valueCavityShow?.mouldC === false) {
            materialtypeDetails.cavity_width_mm = ""
        }
        const formData = new FormData();
        for (const [key, value] of Object.entries(materialtypeDetails)) {
            const formvalue: any = value
            // if (key === "image") {
            //     for (let i = 0; i < logo.length; i++) {

            //         formData.append('image', logo[i])
            //     }
            // }
            // if (key === "pdf") {
            //     for (let i = 0; i < logoPdf.length; i++) {

            //         formData.append('pdf', logoPdf[i])
            //     }
            // }

            if (key === "image") {
                logoId.map((old: any) => {
                    formData.append('mould_image', old?.id)
                })
                for (let i = 0; i < logo.length; i++) {

                    formData.append('image', logo[i])
                }
            }
            if (key === "pdf") {
                ProductDrawingId.map((old: any) => {
                    formData.append('mould_drawing', old?.id)
                })
                for (let i = 0; i < logoPdf.length; i++) {

                    formData.append('pdf', logoPdf[i])
                }
            }

            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            if (Array.isArray(value) && key === "tfm_machine_usd_mould") {
                value.forEach((val, index) => formData.append(`tfm_machine_usd_mould`, val))
            }
            // if (key === 'product_mould_diameter_mm') {
            //     let decimalNumber = Number(valueSize);
            //     const formattedString = decimalNumber.toFixed(2);
            //     formData.append('product_mould_diameter_mm', formattedString)
            // }
            // if (key === 'product_length_diameter') {
            //     let decimalNumber = Number(valueLength);
            //     const formattedString = decimalNumber.toFixed(2);
            //     formData.append('product_length_diameter', formattedString)
            // }
            // if (key === 'product_width_diameter') {
            //     let decimalNumber = Number(valueWidth);
            //     const formattedString = decimalNumber.toFixed(2);
            //     formData.append('product_width_diameter', formattedString)
            // }

            if (key === 'product_list_diameter_mm') {
                let decimalNumber = Number(valueProductionPriceList);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('product_list_diameter_mm', formattedString)
            }
            if (key === 'forming_cutting_area_sheet_length_mm') {
                let decimalNumber = Number(valueFormingCuttingLength);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('forming_cutting_area_sheet_length_mm', formattedString)
            }
            if (key === 'forming_cutting_area_sheet_width_mm') {
                let decimalNumber = Number(valueFormingCuttingWidth);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('forming_cutting_area_sheet_width_mm', formattedString)
            }
            if (key === 'cutter_gap') {
                let decimalNumber = Number(valueMouldCutterGap);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('cutter_gap', formattedString)
            }
            if (key === 'tfm_chain_infield_maximum_mm') {
                let decimalNumber = Number(valueTFMMaximum);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('tfm_chain_infield_maximum_mm', formattedString)
            }
            if (key === 'tfm_chain_infield_minimum_mm') {
                let decimalNumber = Number(valueTFMMinimum);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('tfm_chain_infield_minimum_mm', formattedString)
            }
            if (key === 'mould_cavity_hole_height_mm') {
                let decimalNumber = Number(valueCavityHoleHeight);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('mould_cavity_hole_height_mm', formattedString)
            }
            if (key === 'mould_cutter_thickness_mm') {
                let decimalNumber = Number(valueMouldCuttermm);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('mould_cutter_thickness_mm', formattedString)
            }
            // if (key === 'mould_cutter_id_mm') {
            //     let decimalNumber = Number(valueMouldCutterIdmm);
            //     const formattedString = decimalNumber.toFixed(2);
            //     formData.append('mould_cutter_id_mm', formattedString)
            //     continue
            // }
            if (valueMouldCutterIdmm && key === 'mould_cutter_id_mm') {
                let decimalNumber = Number(valueMouldCutterIdmm);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('mould_cutter_id_mm', formattedString)
                continue
            }
            if (valueMouldCutterOdmm && key === 'mould_cutter_od_mm') {
                let decimalNumber = Number(valueMouldCutterOdmm);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('mould_cutter_od_mm', formattedString)
                continue
            }
            // if (key === 'mould_cutter_od_mm') {
            //     let decimalNumber = Number(valueMouldCutterOdmm);
            //     const formattedString = decimalNumber.toFixed(2);
            //     formData.append('mould_cutter_od_mm', formattedString)
            //     continue
            // }
            if (key === 'sheet_width') {
                let decimalNumber = Number(ValueSheetSizeWidth);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('sheet_width', formattedString)
                continue
            }
            if (key === 'length') {
                let decimalNumber = Number(ValueMouldLengthmm);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('length', formattedString)
                continue
            }
            // if (key === 'mould_width_mm') {
            //     let decimalNumber = Number(ValueMouldWidthmm);
            //     const formattedString = decimalNumber.toFixed(2);
            //     formData.append('mould_width_mm', formattedString)
            //     continue
            // }
            if (ValueMouldWidthmm && key === 'mould_width_mm') {
                let decimalNumber = Number(ValueMouldWidthmm);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('mould_width_mm', formattedString)
                continue
            }
            // if (key === 'mould_lip_mm') {
            //     let decimalNumber = Number(valueMouldLip);
            //     const formattedString = decimalNumber.toFixed(2);
            //     formData.append('mould_lip_mm', formattedString)
            // }

            // if (key === 'mould_lip_mm') {
            //     let decimalNumber = Number(valueMouldLip);
            //     const formattedString = decimalNumber.toFixed(2);
            //     formData.append('mould_lip_mm', formattedString)
            // }

        }

        if ('product_mould_diameter_mm' in materialtypeDetails) {
            let decimalNumber = Number(materialtypeDetails.product_mould_diameter_mm);
            if (materialtypeDetails.hasOwnProperty('product_mould_diameter_mm') && Number(materialtypeDetails.product_mould_diameter_mm) === 0.00) {
                formData.append('product_mould_diameter_mm', "");
            } else {

                formData.append('product_mould_diameter_mm', decimalNumber.toFixed(2));
            }

        }
        if ('product_length_diameter' in materialtypeDetails) {
            let decimalNumber = Number(materialtypeDetails.product_length_diameter);

            if (materialtypeDetails.hasOwnProperty('product_length_diameter') && Number(materialtypeDetails.product_length_diameter) === 0.00) {
                formData.append('product_length_diameter', "");
            } else {

                formData.append('product_length_diameter', decimalNumber.toFixed(2));
            }
            // formData.append('cavity_length_mm', decimalNumber.toFixed(2));
        }
        if ('product_width_diameter' in materialtypeDetails) {
            let decimalNumber = Number(materialtypeDetails.product_width_diameter);
            if (materialtypeDetails.hasOwnProperty('product_width_diameter') && Number(materialtypeDetails.product_width_diameter) === 0.00) {
                formData.append('product_width_diameter', "");
            } else {

                formData.append('product_width_diameter', decimalNumber.toFixed(2));
            }
            // formData.append('cavity_width_mm', decimalNumber.toFixed(2));
        }

        
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }
        mutate(data);
        setValuesofform(data)


    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")


    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setValuesofform(null)
            setShow(true)
            navigate('/listMould', { state: { showEditToast: true } })
        }

    }, [data, isSuccess, yessuccess,
        nosuccess, gstUpdated_response])

    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    // useEffect(() => {
    //     if (isSuccess) {
    //         setShow(true)
    //         setshowmessage("success")
    //         navigate('/listMould', { state: { showEditToast: true } })
    //     }
    //     else if (isError) {
    //         setShow(true)
    //         setshowmessage("danger")
    //     }
    // }, [gstUpdated_response,data, isSuccess, yessuccess, isError, error,
    //     nosuccess])

    // useEffect(() => {
    //     setTimeout(() => {
    //         setShow(false)
    //     }, 3000)

    // }, [show]);



    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])


    const onLogoChange = (e: any) => {
        setlogo(e.target.files)
    }

    const onLogoPDFChange = (e: any) => {
        setlogoPdf(e.target.files)
    }


    var from = data?.purchase_date;
    var one = moment(from, "DD-MM-YYYY").add(1, 'd').toISOString();
    const [toDate, setToDate]: any = useState("")

    const handleToDate = (date: any, dateString: any) => {
        if (date == null) {
            setToDate("")
            setValue("purchase_date", ``)
            return
        }
        setToDate(dayjs(date, 'DD/MM/YYYY'))
        setValue("purchase_date", `${date?.$y}-${date?.$M + 1}-${date?.$D}`)
    }
    const [forFromDate, setForFromDate]: any = useState()
    const [fromDate, setFromDate]: any = useState()

    const handleFromDate = (e: any) => {
        if (e == null) {
            setForFromDate("")
            setValue("manufacture_date", ``)
            return
        }
        setForFromDate(dayjs(e, 'DD/MM/YYYY'))
        setValue("manufacture_date", `${e?.$y}-${e?.$M + 1}-${e?.$D}`)
        // setForFromDate(e)
    }


    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return current && current < dayjs().startOf('day');
    };
    let dateTwo = data?.purchase_date
    let toDateValue = dateTwo
    let dateOne = data?.manufacture_date
    let DateValue = dateOne

    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }


    const FileOnChange = (e: any) => {
        e.file.status = "done"
        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)
        setLogoId(tempFiles)
        setlogo(tempFiles)
        setUploadFile(e.fileList)

    }
    const DocOnChange = (e: any) => {
        e.file.status = "done"

        // const tempFiles = e.fileList.map((file: any) => {
        //     return file.originFileObj
        // }).filter((e: any) => e != undefined)

        let tempOldFiles: any = []
        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }

                tempOldFiles.push(ctx)
            }
        })



        const tempFiles = e.fileList.map((file: any) => {
            // console.log(file,"file");
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setProductDrawingId(tempFiles)
        setlogoPdf(tempFiles)
        setUploadFileDoc(e.fileList)

    }

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    const [valueProductionDiameter, setValueProductionDiameter] = useState('')
    const [valueProductionPriceList, setValueProductionPriceList] = useState('')
    const [valueFormingCuttingLength, setValueFormingCuttingLength] = useState('')
    const [valueFormingCuttingWidth, setValueFormingCuttingWidth] = useState('')
    const [valueMouldCutterGap, setValueMouldCutterGap] = useState('')
    const [valueTFMMaximum, setValueTFMMaximum] = useState('')
    const [valueTFMMinimum, setValueTFMMinimum] = useState('')
    const [valueCavityHoleHeight, setValueCavityHoleHeight] = useState('')
    const [valueMouldLip, setValueMouldLip] = useState('')
    const [valueMouldCuttermm, setvalueMouldCuttermm] = useState('')
    const [valueMouldCutterIdmm, setvalueMouldCutterIdmm] = useState('')
    const [valueMouldCutterOdmm, setvalueMouldCutterOdmm] = useState('')
    const [ValueMouldLengthmm, setValueMouldLengthmm] = useState('')
    const [ValueSheetSizeWidth, setValueSheetSizeWidth] = useState('')
    const [ValueMouldWidthmm, setValueMouldWidthmm] = useState('')


    const [valueSize, setValueSize] = useState('')
    const [valueLength, setValueLength] = useState('')
    const [valueWidth, setValueWidth] = useState('')


    const handleNumbercode = (e: any) => {
        let temp = e.target.value.replace(/\D/g, '');
        if (temp.length > 10) temp = temp.substring(0, 10);
        if (temp.length > 6) {
            temp = temp.slice(0, 5) + ' ' + temp.slice(5);
        }
        e.target.value = temp;

        setValue('service_mobile_number', temp)
    }


    useEffect(() => {
        if (Guarantee === 'no') {
            setValue("guarantee_months", "")
        }
        if (Warranty === 'no') {
            setValue("warranty_time", "")
        }
    }, [Guarantee, Warranty])

    const [submittedHSN, setSubmittedHSN] = useState(false)


    useEffect(() => {
        if (submittedHSN) {
            setSelHSN(optionsHSN[0])

            setValue("mould_name_type_category_hsn_code_and_gst", optionsHSN[0].value)

        }

    }, [submittedHSN, optionsHSN])

    const [submittedMachine, setSubmittedMachine] = useState(false)
    const [forMachineValue, setForMachineValue]: any = useState([])


    useEffect(() => {
        if (submittedMachine) {
            setSelMachineMould(options[0])

            setValue("tfm_machine_usd_mould", options[0]?.value)

        }

    }, [submittedMachine, options])





    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
      const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
      const [apiName, setApiName]: any = useState()
  
  
      const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
      const [confirmforcemodal, setConfirmforcemodal] = useState(false);
  
      const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
      } = useDelete();
      const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
      } = useDeletemultiple();
      const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
      } = useDeletemultipleforce();
      const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
      } = useDeletemforce();
    
  
  
  
    const handleRefetch = () => {
        if (apiName === apinameForCavity) {
          mouldShapeRefetch()
        }
  
        if (apiName === apinameForMouldUse) {
          MouldUseRefetch()
        }
  
        if (apiName === apinameForMouldLocation) {
          MouldLocationRefetch()
        }
  
        if (apiName === apinameForMouldBrand) {
          MouldBrandRefetch()
        }
  
        if (apiName === apinameForCompanyName) {
          CompanyNameRefetch()
        }
  
      
      }
  
  
      useEffect(() => {
        if (OpenEditDropDownModel && valueCavity && apiName === apinameForCavity) {
          setValueCavity(null)
          setValueCavityCreatable(null)
        }
  
        if (OpenEditDropDownModel && valueMouldUse && apiName === apinameForMouldUse) {
          setValueMouldUse(null)
          setValueMouldUseCreatable(null)
        }
  
        if (OpenEditDropDownModel && valueMouldLocation && apiName === apinameForMouldLocation) {
          setValueMouldLocation(null)
          setValueMouldLocationCreatable(null)
        }
  
        if (OpenEditDropDownModel && valueMouldBrand && apiName === apinameForMouldBrand) {
          setValueMouldBrand(null)
          setValueMouldBrandCreatable(null)
        }
  
        if (OpenEditDropDownModel && valueCompanyName && apiName === apinameForCompanyName) {
          setValueCompanyName(null)
          setValueCompanyNameCreatable(null)
        }
  
  
      
       
       
    
      
    
      
        if(deletesucess && apiName === apinameForCavity){
          setValueCavity(null)
          setValueCavityCreatable(null)
        }
  
        if(deletesucess && apiName === apinameForMouldUse){
          setValueMouldUse(null)
          setValueMouldUseCreatable(null)
        }
  
        if(deletesucess && apiName === apinameForMouldLocation){
          setValueMouldLocation(null)
          setValueMouldLocationCreatable(null)
        }
  
        if(deletesucess && apiName === apinameForMouldBrand){
          setValueMouldBrand(null)
          setValueMouldBrandCreatable(null)
        }
  
        if(deletesucess && apiName === apinameForCompanyName){
          setValueCompanyName(null)
          setValueCompanyNameCreatable(null)
        }
  
       
       
  
      }, [OpenEditDropDownModel, apiName, deletesucess])
  
  
      const [isExist, setIsExist] = useState(true)
  
  
      const CustomOption = ({ innerProps, label, value }: any) => (
    
    
        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
          <span>{label}</span>
          <div style={{ float: "right", justifyContent: "end" }}>
    
    
    
          {label !== 'Square' && label !== 'Round' && label !== 'Triangle' && label !== 'Rectangles' ?
    
              <Custom_Actions_Buttons value={value}
                apiname={deleteApiName}
                mutate={singleDelete}
                // masterName={addButtonName}
                deleteid={deleteid}
                handleDeletedforce={handleDeletedforce}
                closereconfirmdelete={closereconfirmdelete}
                deletemodalask={deletemodalask}
              setEditOpen={setOpenEditDropDownModel}
              // permissionClass={permissionClass}
              />
              : ""}
    
          </div>
        </div>
    
    
      )
  
  
      const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
      }
  
  
   
      useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
      }, [deletesucess, editData?.results]);
      useEffect(() => {
        return () => {
          setDeleteid(undefined);
        };
      }, []);
      const handleConfirmDelete = () => {
        setDeleteConfirm(true);
      };
      const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
      };
    
      const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
      };
      const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
      };
      const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
      };
      const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
          deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
      };
      useEffect(() => {
        if (forcefullysuccess) {
          setShow(true);
          setshowmessage("success");
        } else if (forcefullyerror) {
          setShow(true);
          setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
          setShow(true);
          setshowmessage("success");
        } else if (forcefullydeleteerror) {
          setShow(true);
          setshowmessage("danger");
        }
      }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
      ]);
    
      useEffect(() => {
        if (deleteMultipleSuccess) {
          const showdepenedancymsg = deleteMultiple?.data?.data;
          const dependacyfound = showdepenedancymsg?.filter((color: any) => {
            if (
              color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
            ) {
              return color;
            }
          });
          if (dependacyfound?.length === deleteMultipleid?.length) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
          } else {
            setConfirmforcemodal(true);
          }
        } else if (deleteMultipleIsError) {
          setShow(true);
          setshowmessage("danger");
        } else if (deletesucess) {
          if (deletedata.data?.data?.length > 0) {
            setConfirmforcemodal(true);
          } else if (deleteid) {
            setShow(true);
            setshowmessage("success");
            deleteforce({ deleteid, apiname: deleteApiName });
          }
        }
      }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);
    
      const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
      };
  



    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit Mould Size</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Masters</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listMould" className="text-decoration-none text-black">Mould Size</Link></li>
                                    <li className="breadcrumb-item active">Edit Mould Size</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}

                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Mould Name & Type & Category & HSN & GST% <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            placeholder={'Select Mould Name & Type'}
                                                            {...register("mould_name_type_category_hsn_code_and_gst", { required: { value: true, message: "Mould Name & Type & Category & HSN & GST% is required" } })}
                                                            onChange={handleChangeHSN}
                                                            options={optionsHSN}
                                                            components={customIndicator}
                                                            value={selHSN === "" ? optionsHSN?.find((obj: any) => obj.value === selectedHSN) : selHSN}


                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenHSN(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.mould_name_type_category_hsn_code_and_gst?.message}</span>


                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Plant Mould	Number <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >

                                                        <input type="text" placeholder="Plant Mould Number" className="form-control"
                                                            {...register("plant_mould_number", { required: { value: true, message: "Plant Mould Number is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.plant_mould_number?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Model No.</label>
                                                    <div className="input-group" >

                                                        <input type="text" placeholder="Mould Model No." className="form-control"
                                                            {...register("model_number", { required: { value: true, message: "Mould Model No. is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.model_number?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Price-List Mould Size mm <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Price-List Mould Size mm" className="form-control"
                                                            {...register("product_list_diameter_mm", { onChange: ((e: any) => setValueProductionPriceList(e.target.value)), required: { value: true, message: "Price-List Mould Size mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.machine_code?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Mould Shape <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueCavity(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("mould_shape", { required: { value: true, message: "Machine Shape is required" } })}
                                                        placeholder={'Select Mould Usage'}
                                                        options={optionsMouldShape}
                                                        onCreateOption={handleCreate}
                                                        onMenuOpen={()=>mouldShapeRefetch()}
                                                        components={customCreatableIndicator}
                                                        onChange={handleChangeMouldShape}
                                                        // value={valueCavityCreatable == null ? valueCavity : valueCavityCreatable}
                                                        value={valueCavityCreatable == null ? valueCavity == null ? optionsMouldShape?.filter((obj: any) => obj.value === selectedCavity) : valueCavity : valueCavityCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>


                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1"> Production Mould Size mm
                                                        {/* <span style={{ color: 'red' }}>*</span> */}
                                                    </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder=" Production Mould Size mm" className="form-control"
                                                            disabled={(valueCavityShow?.mouldA ? false : true)}
                                                            {...register("product_mould_diameter_mm", { onChange: ((e: any) => setValueSize(e.target.value)), required: { value: valueCavityShow?.mouldA === "false" ? true : false, message: " Production Mould Size mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.product_mould_diameter_mm?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1"> Production Mould Width mm </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder=" Production Mould	Width mm" className="form-control"
                                                            disabled={(valueCavityShow?.mouldC ? false : true)}
                                                            {...register("product_width_diameter", { onChange: ((e: any) => setValueWidth(e.target.value)), required: { value: valueCavityShow?.mouldC === "false" ? true : false, message: " Production Mould Width mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.product_width_diameter?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1"> Production Mould Length mm </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder=" Production Mould	Length mm" className="form-control"
                                                            disabled={(valueCavityShow?.mouldB ? false : true)}
                                                            {...register("product_length_diameter", { onChange: ((e: any) => setValueLength(e.target.value)), required: { value: valueCavityShow?.mouldB === "false" ? true : false, message: " Production Mould	Length mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.product_length_diameter?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Cavity Hole Height mm
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Cavity Hole Height mm" className="form-control"
                                                            {...register("mould_cavity_hole_height_mm", { onChange: ((e: any) => setValueCavityHoleHeight(e.target.value)), required: { value: true, message: "Mould Cavity Hole Height mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.mould_cavity_hole_height_mm?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Cavity Qty <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Cavity Qty" className="form-control"
                                                            {...register("cavity_qty", { onChange: handleValue, required: { value: true, message: "Mould Cavity Qty is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.machine_code?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Lip
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    {/* <div className="input-group" >

                                                        <input type="number" placeholder="Mould Lip mm" className="form-control"
                                                            {...register("mould_lip_mm", { onChange: ((e: any) => setValueMouldLip(e.target.value)), required: { value: false, message: "Mould Lip mm is required" } })}
                                                        /> */}

                                                    <select className="form-select" {...register("mould_lip_mm",
                                                        {

                                                            required: { value: true, message: "Mould Lip mm is required" }, maxLength: 30,

                                                        }
                                                    )}>
                                                        <option value="" selected >Select Any</option>
                                                        <option value={"Bend Lip"}>Bend Lip</option>
                                                        <option value={"Flat Lip"}>Flat Lip</option>
                                                    </select>

                                                    {/* <div className="input-group-append">
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                    </div>
                                                </div> */}
                                                    <span className='text-red'>{errors?.mould_lip_mm?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Cutter Gap mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Cutter Gap mm" className="form-control"
                                                            {...register("cutter_gap", { onChange: ((e: any) => setValueMouldCutterGap(e.target.value)), required: { value: true, message: "Mould Cutter Gap mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.cutter_gap?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Cutter Thikness mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Cutter Thikness mm" className="form-control"
                                                            {...register("mould_cutter_thickness_mm", { onChange: ((e: any) => setvalueMouldCuttermm(e.target.value)),required: { value: true, message: "Mould Cutter Thikness mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.mould_cutter_thickness_mm?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Cutter ID mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Cutter ID mm" className="form-control"
                                                            {...register("mould_cutter_id_mm", { onChange: ((e: any) => setvalueMouldCutterIdmm(e.target.value)), required: { value: true, message: "Mould Cutter ID mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.mould_cutter_id_mm?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Cutter OD mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Cutter OD mm" className="form-control"
                                                            {...register("mould_cutter_od_mm", { onChange: ((e: any) => setvalueMouldCutterOdmm(e.target.value)), required: { value: true, message: "Mould Cutter OD mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.mould_cutter_id_mm?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">T.F.M. Chain In field Minimum mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="T.F.M. Chain In field Minimum mm" className="form-control"
                                                            {...register("tfm_chain_infield_minimum_mm", { onChange: ((e: any) => setValueTFMMinimum(e.target.value)), required: { value: true, message: "T.F.M. Chain In field Minimum mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.tfm_chain_infield_minimum_mm?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">T.F.M. Chain In field Maximum mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="T.F.M. Chain In field Maximum mm" className="form-control"
                                                            {...register("tfm_chain_infield_maximum_mm", { onChange: ((e: any) => setValueTFMMaximum(e.target.value)), required: { value: true, message: "T.F.M. Chain In field Maximum mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.tfm_chain_infield_maximum_mm?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Forming Cutting Area Sheet Length mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Forming Cutting Area Sheet Length mm" className="form-control"
                                                            {...register("forming_cutting_area_sheet_length_mm", { onChange: ((e: any) => setValueFormingCuttingLength(e.target.value)), required: { value: true, message: "Forming Cutting Area Sheet Length mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.forming_cutting_area_sheet_length_mm?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Forming Cutting Area Sheet Width mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Forming Cutting Area Sheet Width mm" className="form-control"
                                                            {...register("forming_cutting_area_sheet_width_mm", { onChange: ((e: any) => setValueFormingCuttingWidth(e.target.value)), required: { value: true, message: "Forming Cutting Area Sheet Width mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.forming_cutting_area_sheet_width_mm?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Sheet Role Length Meter <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Sheet Length mm" className="form-control"
                                                            {...register("sheet_length", { required: { value: true, message: "Mould Sheet Length mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.sheet_length?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Sheet Size Width mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Sheet Width mm" className="form-control"
                                                            {...register("sheet_width", { onChange: ((e: any) => setValueSheetSizeWidth(e.target.value)), required: { value: true, message: "Mould Sheet Width mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.sheet_width?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Length mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Length mm" className="form-control"
                                                            {...register("length", { required: { value: true, message: "Mould Length mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.length?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Width mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Width mm" className="form-control"
                                                            {...register("mould_width_mm", { required: { value: true, message: "Mould Width mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.mould_width_mm?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Weight Kg <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Weight Kg" className="form-control"
                                                            {...register("mould_weight_kg", { required: { value: true, message: "Mould Weight Kg is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.mould_weight_kg?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Machine Usd Mould & Plant Number <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("tfm_machine_usd_mould", { required: { value: true, message: "Machine Usd Mould & Plant Number is required" } })}
                                                            placeholder={'Machine Usd Mould & Plant Number'}
                                                            options={options}
                                                            components={customIndicator}
                                                            onChange={handleChangeMachine}
                                                            value={selMachineMould.length === 0 ? options?.filter((obj: any) => selectedMachineMould.includes(obj.value)) : selMachineMould}
                                                            isMulti
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenMachine(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.tfm_machine_usd_mould?.message}</span>


                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Mould Sp. - Running<span style={{ color: 'red' }}>*</span></label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMouldUse(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("mould_special_running", { required: { value: true, message: "Mould Sp. - Running is required" } })}
                                                        placeholder={'Select Mould Sp. - Running'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsMouldUse}
                                                        onMenuOpen={()=>MouldUseRefetch()}
                                                        onCreateOption={handleCreateMouldUse}
                                                        onChange={handleChangeMouldUse}
                                                        // value={valueMouldUseCreatable == null ? valueMouldUse : valueMouldUseCreatable}
                                                        value={valueMouldUseCreatable == null ? valueMouldUse == null ? optionsMouldUse?.filter((obj: any) => obj.value === selectedMouldUse) : valueMouldUse : valueMouldUseCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.mould_special_running?.message}</span>
                                                </div>
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">T.F.M. Machine Used Mould <span style={{ color: "red" }}>*</span></label>
                                                    <Select
                                                        {...register("tfm_machine_usd_mould", { required: { value: true, message: "T.F.M. Machine Usd Mould is required" } })}
                                                        placeholder={'T.F.M. Machine Usd Mould'}
                                                        options={options}
                                                        components={customIndicator}
                                                        onChange={handleChangeMachine}
                                                        value={selMachineMould.length === 0 ? options?.filter((obj: any) => selectedMachineMould.includes(obj.value)) : selMachineMould}
                                                        isMulti
                                                    />
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>


                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Sheet Length mm</label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Sheet Length mm" className="form-control"
                                                            {...register("sheet_length", { onChange: handleValue, required: { value: false, message: "Mould Sheet Length mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.sheet_length?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Sheet Width mm</label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Sheet Width mm" className="form-control"
                                                            {...register("sheet_width", { onChange: handleValue, required: { value: false, message: "Mould Sheet Width mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.sheet_width?.message}</span>

                                                </div>




                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Length mm</label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Length mm" className="form-control"
                                                            {...register("length", { onChange: handleValue, required: { value: false, message: "Mould Length mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.length?.message}</span>

                                                </div>




                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Cutter Thikness mm</label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Cutter Thikness mm" className="form-control"
                                                            {...register("mould_cutter_thickness_mm", { onChange: handleValue, required: { value: false, message: "Mould Cutter Thikness mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.mould_cutter_thickness_mm?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Forming Cutting Area Sheet Length mm</label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Forming Cutting Area Sheet Length mm" className="form-control"
                                                            {...register("forming_cutting_area_sheet_length_mm", { onChange: ((e: any) => setValueFormingCuttingLength(e.target.value)), required: { value: false, message: "Forming Cutting Area Sheet Length mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.forming_cutting_area_sheet_length_mm?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Forming Cutting Area Sheet Width mm</label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Forming Cutting Area Sheet Width mm" className="form-control"
                                                            {...register("forming_cutting_area_sheet_width_mm", { onChange: ((e: any) => setValueFormingCuttingWidth(e.target.value)), required: { value: false, message: "Forming Cutting Area Sheet Width mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.forming_cutting_area_sheet_width_mm?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Cutter Gap mm</label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Cutter Gap mm" className="form-control"
                                                            {...register("cutter_gap", { onChange: ((e: any) => setValueMouldCutterGap(e.target.value)), required: { value: false, message: "Mould Cutter Gap mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.cutter_gap?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">T.F.M. Chain In field Maximum mm</label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="T.F.M. Chain In field Maximum mm" className="form-control"
                                                            {...register("tfm_chain_infield_maximum_mm", { onChange: ((e: any) => setValueTFMMaximum(e.target.value)), required: { value: false, message: "T.F.M. Chain In field Maximum mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.tfm_chain_infield_maximum_mm?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">T.F.M. Chain In field Minimum mm</label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="T.F.M. Chain In field Minimum mm" className="form-control"
                                                            {...register("tfm_chain_infield_minimum_mm", { onChange: ((e: any) => setValueTFMMinimum(e.target.value)), required: { value: false, message: "T.F.M. Chain In field Minimum mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.tfm_chain_infield_minimum_mm?.message}</span>

                                                </div> */}






                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Purchase Date <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" >

                                                        <DatePicker
                                                            className="form-control"
                                                            format={dateFormatList}
                                                            value={toDate == undefined ? dayjs(toDateValue, "DD/MM/YYYY") : toDate}
                                                            // disabledDate={disabledDate}
                                                            {...register("purchase_date", { required: { value: true, message: "Field is required" } })}

                                                            onChange={handleToDate}
                                                        />


                                                    </div>
                                                    <span className='text-red'>{errors?.purchase_date?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Mould Manufacture Date <span style={{ color: 'red' }}>*</span></label>
                                                    <DatePicker
                                                        className="form-control"
                                                        format={dateFormatList}
                                                        value={forFromDate == undefined ? dayjs(DateValue, "DD/MM/YYYY") : forFromDate}
                                                        // disabledDate={disabledDate}
                                                        {...register("manufacture_date", { required: { value: true, message: "Field is required" } })}

                                                        onChange={handleFromDate}
                                                    />
                                                    <span className='text-red w-100'>{errors?.manufacture_date?.message}</span>


                                                </div>



                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Location <span style={{ color: 'red' }}>*</span>  </label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMouldLocation(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("mould_location", { required: { value: true, message: "Mould Location is required" } })}
                                                        placeholder={'Select Mould Location'}
                                                        components={customCreatableIndicator}
                                                        onMenuOpen={()=>MouldLocationRefetch()}
                                                        options={optionsMouldLocation}
                                                        onCreateOption={handleCreateMouldLocation}
                                                        onChange={handleChangeMouldLocation}
                                                        // value={valueMouldLocationCreatable == null ? valueMouldLocation : valueMouldLocationCreatable}
                                                        value={valueMouldLocationCreatable == null ? valueMouldLocation == null ? optionsMouldLocation?.filter((obj: any) => obj.value === selectedLocation) : valueMouldLocation : valueMouldLocationCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.mould_location?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Brand <span style={{ color: 'red' }}>*</span>  </label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMouldBrand(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("mould_brand", { required: { value: true, message: "Mould Brand is required" } })}
                                                        placeholder={'Select Mould Brand'}
                                                        components={customCreatableIndicator}
                                                        options={optionsMouldBrand}
                                                        onMenuOpen={()=>MouldBrandRefetch()}
                                                        onCreateOption={handleCreateMouldBrand}
                                                        onChange={handleChangeMouldBrand}
                                                        // value={valueMouldBrandCreatable == null ? valueMouldBrand : valueMouldBrandCreatable}
                                                        value={valueMouldBrandCreatable == null ? valueMouldBrand == null ? optionsMouldBrand?.filter((obj: any) => obj.value === selectedBrand) : valueMouldBrand : valueMouldBrandCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.mould_brand?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Company Name & City <span style={{ color: 'red' }}>*</span>  </label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueCompanyName(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("mould_company", { required: { value: true, message: "Mould Company Name & City is required" } })}
                                                        placeholder={'Select Mould Company Name & City'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsCompanyName}
                                                        onMenuOpen={()=>CompanyNameRefetch()}
                                                        onCreateOption={handleCreateCompanyName}
                                                        onChange={handleChangeCompanyName}
                                                        // value={valueCompanyNameCreatable == null ? valueCompanyName : valueCompanyNameCreatable}
                                                        value={valueCompanyNameCreatable == null ? valueCompanyName == null ? optionsCompanyName?.filter((obj: any) => obj.value === selectedCompany) : valueCompanyName : valueCompanyNameCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.mould_company?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Mould New-Old <span style={{ color: 'red' }}>*</span>  </label>
                                                    <select className="form-select" {...register("mould_new_old",
                                                        {
                                                            required: { value: true, message: "Mould New-Old is required" }, maxLength: 30,

                                                        }
                                                    )}>
                                                        <option value="" selected >Select Any</option>
                                                        <option value={"new"}>New</option>
                                                        <option value={"old"}>Old</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.mould_new_old?.message}</span>


                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Country Mobile Code <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type={"text"} isPencil={true} placeholder={"Country Mobile Code"} className="form-control"   {...register("country_code", { required: { value: true, message: "Country Mobile Code is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.country_code?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Service Mobile Number</label>
                                                    <div className="input-group" >

                                                        <input type={"text"} isPencil={true} placeholder={"Service Mobile Number"}
                                                            // maxLength={10}
                                                            // onInput={maxLengthCheck}
                                                            className="form-control"   {...register("service_mobile_number", {
                                                                required: false,

                                                                pattern: {
                                                                    // value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                                                    value: /^(\d{1,5})(?:\s(\d{1,5}))?(?:\s(\d{1,2}))?$/gm,
                                                                    message: 'Please enter a valid number',
                                                                },
                                                            })} color={"green"}
                                                            onChange={handleNumbercode}

                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.service_mobile_number?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Guarantee Yes-No <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("guarantee_yes_no",
                                                        {
                                                            onChange: onGuaranteeChange,
                                                            required: { value: true, message: "machine Status is required" }, maxLength: 30,

                                                        }
                                                    )}>
                                                        <option value="" selected >Select Any</option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"}>No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.guarantee_yes_no?.message}</span>


                                                </div>
                                                {/* {Guarantee ==="yes"? */}
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Guarantee Month</label>
                                                    <div className="input-group" >

                                                        <input type={"text"} isPencil={true} placeholder={"Guarantee Month"} className="form-control" disabled={Guarantee === "no" || ""}  {...register("guarantee_months", { onChange: handleValue, required: { value: false, message: "Guarantee Month is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.guarantee_months?.message}</span>

                                                </div>
                                                {/* :''} */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Warranty Yes-No <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("warranty_yes_no",
                                                        {
                                                            onChange: onWarrantyChange,
                                                            required: { value: true, message: "Warranty is required" }, maxLength: 30,

                                                        }
                                                    )}>
                                                        <option value="" selected >Select Any</option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"}>No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.warranty_yes_no?.message}</span>


                                                </div>
                                                {/* {Warranty === "yes" ? */}
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Warranty Month</label>
                                                    <div className="input-group" >

                                                        <input type={"text"} isPencil={true} placeholder={"Warranty Month"} className="form-control" disabled={Warranty === "no" || ""} {...register("warranty_time", { onChange: handleValue, required: { value: false, message: "Warranty Month is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.warranty_time?.message}</span>

                                                </div>
                                                {/* : ""} */}
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Remark</label>
                                                    <div className="input-group" >

                                                        <input type={"text"} isPencil={true} placeholder={"Remark"} className="form-control"   {...register("remark", { onChange: handleValue, required: { value: false, message: "Remark is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.remark?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select" {...register("status",
                                                        {
                                                            required: { value: true, message: " Status is required" }, maxLength: 30,

                                                        }
                                                    )}>
                                                        <option value="" selected >Select Any</option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>


                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Code</label>
                                                    <div className="input-group" >

                                                        <input type="text" disabled placeholder="Mould code" className="form-control"
                                                            {...register("mould_code", { required: { value: true, message: "Mould Code is required" } })}
                                                        />

                                                        {/* <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                    </div>
                                                    <span className='text-red'>{errors?.mould_code?.message}</span>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Mould Image All</label>


                                                    {uploadFile.length > 0 ?

                                                        <>
                                                            <Upload
                                                                {...register("image")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={FileOnChange}
                                                                multiple={true}
                                                                defaultFileList={[...uploadFile]}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"image"}

                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </> :
                                                        <>
                                                            <Upload
                                                                {...register("image")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={FileOnChange}
                                                                multiple={true}
                                                                onPreview={(e: any) => { return "" }}

                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </>
                                                    }





                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Mould PDF Drawing All</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                    </label>
                                                    {uploadFileDoc.length > 0 ?
                                                        <>
                                                            <Upload
                                                                {...register("pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={DocOnChange}
                                                                multiple={true}
                                                                defaultFileList={[...uploadFileDoc]}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"pdf"}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                                {/* <p>{logoPdf == null ? data?.pdf?.split("/").pop() : logoPdf}</p> */}

                                                            </Upload>
                                                        </> :
                                                        <>
                                                            <Upload
                                                                {...register("pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={DocOnChange}
                                                                multiple={true}
                                                                onPreview={(e: any) => { return "" }}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                                {/* <p>{logoPdf == null ? data?.pdf?.split("/").pop() : logoPdf}</p> */}

                                                            </Upload>

                                                        </>
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)}
                                                    // disabled={!isDirty}
                                                    >Submit</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listMould")}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section >
            </div >
            <HSN_Model refetchForm={HSNRefetch} open={openHSN} setOpen={setOpenHSN} setSubmitted={setSubmittedHSN} />
            <Machine_Model refetchForm={machineRefetch} open={openMachine} setOpen={setOpenMachine} setSubmitted={setSubmittedMachine} />

            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />



                   {/* Edit Modal HTML */}
               <Modal
        show={deleteConfirm}
        onHide={() => setDeleteConfirm(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete {""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {" "}
            <span className="text-center">Sure you want to delete it?</span>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleteConfirm(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="info"
            className="text-white"
            onClick={handleMultipleDeleted}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Deleteforce
        key={renderKey}
        handleDeletedforce={handleDeletedforce}
        reconfirmdelete={confirmforcemodal}
        masterName={""}
        closereconfirmdelete={closereconfirmdelete}
        referance={
          deletesucess
            ? deletedata
            : ""
        }
        apiname={apiName}
      />
      <Deletesingle
        key={renderKey}
        confirmmodal={deleteConfirmsingle}
        confirmmodalclose={confirmmodalclose}
        masterName={""}
        confirmmodalsubmit={confirmmodalsubmit}
        apiname={apiName}
      />

      <SimpleInput_Edit key={renderKey} 
      refetchForm={handleRefetch} 
      open={OpenEditDropDownModel} 
      setOpen={setOpenEditDropDownModel} 
      id={editId} 
      // superAccountId={superAccountId} 
      // optionsSuperAccount={optionsSuperAccount}
      // isSuperAccount={isSuperAccount}
      apiname={apiName} />
        </div >
    )
}

export default Edit_Mould


