import { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import InputField from '../../../components/HRMS Components/Inpute/input';
import { useCreate } from '../../../hooks/Api';
import useFocusOnEnter from '../../../components/focus';
import SubmitField from '../../../components/HRMS Components/Button/Submit';
import { useFetchBankAccountDropDown, useFetchHrmsAccountDropdown, useFetchTransactionAccountDropDown } from '../../../hooks/DropDown_Api';
import ReactSelect from '../../../components/HRMS Components/Select/select';
import { Alert } from '../../../components/Model/Model';
import Date from '../../../components/HRMS Components/Date and Time/Date';
import Createtable from '../../../components/HRMS Components/Select/Createtable';

interface Option {
  readonly label: string;
  //   readonly value: string;
  readonly name: string;
}
const createOption = (label: string) => ({
  label,
  // value: label,
  name: label,
  status: "active"
});

const Add_Other_Transaction = () => {

  let pageName = "Other Transaction"
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_ACCOUNTING_OTHER_TRANSACTION__ENTRY_API?.toString();

  const { mutate: gst, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname);
  const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

  const [TransactionTypeValue, setTransactionTypeValue]: any = useState()

  const TransactionType = [ 
    { label:'Payment' ,value:'Payment' },
    { label:'Receive' ,value:'Receive' }
  ]

  const handleTransactionType = (e:any) =>{
    setTransactionTypeValue(e)
    setValue("others_transaction_type",e?.value)
  }

  const [PaymentMethodValue, setPaymentMethodValue]: any = useState()

  const PaymentMethod = [ 
    { label:'Cash' ,value:'Cash' },
    { label:'Bank' ,value:'Bank' }
  ]

  const handlePaymentMethod = (e:any) =>{
    setPaymentMethodValue(e)
    setValue("payment_method",e?.value)
  }

  const [optionsBank, setOptionsBank]:any = useState([])
  const [BankValue, setBankValue]: any = useState("")
  
  const { data: BankData, refetch: BankRefetch } = useFetchBankAccountDropDown()
  
  useEffect(() => {
    setOptionsBank(BankData)
  }, [BankData])
  
  const handleChangeBank = (e: any) => {
    setBankValue(e)
    setValue("bank_account", e?.value)
  }
  
  const [forDate, setForDate]: any = useState('')
  const handleDate = (date: any,dateString:any) => {
    
    setForDate(date)
    setValue("date_time",  dateString)
  }
  
  const [optionsTransaction, setOptionsTransaction]:any = useState([])
  const [valueTransaction, setValueTransaction]: any = useState();

    const { data: TransactionData, refetch: TransactionRefetch } = useFetchTransactionAccountDropDown()

    useEffect(() => {
        setOptionsTransaction(TransactionData)
    }, [TransactionData])

    const handleChangeTransaction = (e: any) => {
        setValueTransaction(e)
        setValue("transaction_account", e?.value)
     }

  const handleReset = () => {
    reset();
  };

  const onSubmit = (branddetail: any) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(branddetail)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue[0]);
      }
      if (typeof value === "object" && value !== null) {
        formData.append(key, "");
      }
    }
    gst(formData);
  };


  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setShow(true);
      setshowmessage("success");
      navigate("/listOtherTransaction", { state: { showToast: true } });
    } else if (isError) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [data, error]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/listColor" className="text-decoration-none text-black">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black"> HRMS </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/listBankTransaction" className="text-decoration-none text-black">{pageName}</Link>
                  </li>
                  <li className="breadcrumb-item">Add {pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form ref={focus} onKeyUp={(event) => onEnterKey(event)} onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body" >
                  {show && showmessage === "danger" ? (<Alert color={showmessage} message={error?.response?.data?.message} />) : ("")}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        <ReactSelect label='Transaction Type' name='others_transaction_type' message='Transaction Type is required' register={register} isRequired={true} isModal={false} isEdit={false} isMulti={false} errors={errors?.transaction_type?.message} options={TransactionType} Selvalue={TransactionTypeValue} onChange={(e)=>handleTransactionType(e)}/>
                        <ReactSelect label='Transaction Account' name='transaction_account' message='Transaction Account is required' register={register} isRequired={true} isModal={false} isEdit={false} errors={errors?.transaction_account?.message} onChange={(e)=>handleChangeTransaction(e)}  options={optionsTransaction} Selvalue={valueTransaction}/>
                        <ReactSelect label='Pay Method' name='payment_method' message='Pay Method is required' register={register} isRequired={true} isModal={false} isEdit={false} isMulti={false} errors={errors?.payment_method?.message} options={PaymentMethod} Selvalue={PaymentMethodValue} onChange={(e)=>handlePaymentMethod(e)}/>
                        {PaymentMethodValue?.value === 'Bank' ?
                        <ReactSelect label='Bank Account' name='bank_account' message='Bank Account is required' register={register} isRequired={true} isModal={false} isEdit={false} isMulti={false} errors={errors?.bank_account?.message} Selvalue={BankValue} options={optionsBank} onChange={(e)=>handleChangeBank(e)}/>:'' }
                        <Date name='date_time' label='Date Time' isPencil={true} message='Date is required' register={register} isRequired={true} hrmserrors={errors?.date_time?.message} value={forDate} onChange={(date, dateString) => handleDate(date, dateString)} isShowTime={true} />
                        <InputField type='text' name='note' label='Transaction Note' isPencil={true} message=' Transaction note is required' register={register} isRequired={false} hrmserrors={errors?.note?.message} />
                        <InputField type='number' name='amount' label='Transaction Amount' isPencil={true} message=' Transaction Amount is required' register={register} isRequired={true} hrmserrors={errors?.amount?.message} />
                      
                      </div>
                    </div>
                  </div>
                  <SubmitField Submit={handleSubmit(onSubmit)} Reset={handleReset} Cancel={() => navigate("/listOtherTransaction")} />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Add_Other_Transaction