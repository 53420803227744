import { Upload } from 'antd'
import { Path } from 'react-hook-form'
import cameraLogo from "../../../images/Export/Camera.png";

interface InputField {
    id?: any,
    name?: any,
    type?: any,
    accept?: any,
    className?: any,
    placeholder?: any,
    htmlFor?: any,
    color?: any
    isPencil?: any
    message?: any
    Selvalue?: any,
    label?: Path<any>,
    register?: any,
    isRequired?: boolean,
    isMulti?: boolean,
    isEdit?: boolean,
    hrmserrors?: any;
    isDisabled?: any
    onChange?: (e:any) => void,
}

const ImageUplaod  = ({ name, className, label, register, isRequired = false, message, Selvalue, isEdit, onChange, isMulti=false }: InputField) => {

    return (
        
                    <div className={className ? className :"col-sm-3 mt-2 image-upload"}>
                        <label htmlFor="exampleInputEmail1" className={className ? className :"col-12"}>{label}</label>
                        <Upload
                            {...register(name, { required: { value: isRequired, message: message } })}
                            accept="image/png, image/jpeg"
                            listType="picture"
                            onChange={onChange}
                            multiple={isMulti}
                            maxCount={!isMulti && 1}
                            defaultFileList={!isEdit && Selvalue}
                            onPreview={(e: any) => { return "" }}
                            key={!isEdit && "image"}
                        >
                            <img src={cameraLogo} alt='' className={className ? className :"uploaded-image"} />
                        </Upload>

                    </div>

    )
}

export default ImageUplaod






