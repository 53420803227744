import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Navigate, useNavigate } from 'react-router-dom'
import Axiosinstance from '../hooks/Interceptor'


const FetchColorDropDown = (searchTerm?: any) => {

    // if(searchTerm){
    //     return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_COLOR_API}/fetch_all?searchTerm=${searchTerm}`)
    // }else{
    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_COLOR_API}/fetch_all/`)
    // }
}

export const useFetchColorDropDown = (searchTerm?: any) => {
    return useQuery(['color Dropdown', searchTerm], () => FetchColorDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                label: option.company_color_name,

                value: option.id,
                status: option.status,
                deleted: option.is_deleted
            }
            ))

            return options
        }
    })
}





const FetchBrandDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BRAND_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BRAND_API}/fetch_all/`)
    }
}

export const useFetchBrandDropDown = (searchTerm?: any) => {
    return useQuery(['brand Dropdown', searchTerm], () => FetchBrandDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                label: option.name,
                value: option.id,
                type: option.type,
                status: option.status,
                deleted: option.is_deleted
            }))

            return options
        }
    })
}



const FetchHSNDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HSN_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HSN_API}/fetch_all/`)
    }
}

export const useFetchHSNDropDown = (searchTerm?: any) => {
    return useQuery(['hsn Dropdown', searchTerm], () => FetchHSNDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                label: `${option.grade_type?.material_type_name}::${option?.particular_sector_name?.name}::${option.product_categories?.name}::${option?.hsn_code}`,
                value: option.id,
                gst: option.gst?.india_GST,
                gst_id: option.gst?.id,
                status: option.status,
                deleted: option.is_deleted
            }))
            return options
        }
    })
}
const FetchHSNMaterialNameDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HSN_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HSN_API}/fetch_all/`)
    }
}

export const useFetchHSNMaterialNameDropDown = (searchTerm?: any) => {
    return useQuery(['Material Name hsn Dropdown', searchTerm], () => FetchHSNMaterialNameDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                label: `${option.grade_type?.material_type_name}::${option?.particular_sector_name?.name}::${option.product_categories?.name}::${option?.hsn_code}::${option?.gst?.india_GST}`,
                value: option.id,
                hsn: option?.hsn_code,
                polymer: option?.grade_type?.grade_polymer,
                material: `${option?.grade_type?.material_type_name}::${option.grade_type?.material_density}::${option.grade_type?.grade_sutra}::${option.grade_type?.gst?.india_GST}`,
                material_id: option?.grade_type?.id,
                sutra: option?.grade_type?.grade_sutra,
                gst: option.gst?.india_GST,
                gst_id: option.gst?.id,
                status: option.status,
                deleted: option.is_deleted
            }))

            return options
        }
    })
}





const FetchMaterialDensityDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MATERIAL_DENSITY_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MATERIAL_DENSITY_API}/fetch_all/`)
    }
}

export const useFetchMaterialDensityDropDown = (searchTerm?: any) => {
    return useQuery(['materialdensity Dropdown', searchTerm], () => FetchMaterialDensityDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => (
                {
                    label: `${option.material_type_name}::${option.material_density}::${option.grade_sutra}`,
                    value: option.id,
                    sutra: option.grade_sutra,
                    density: option.material_density,
                    gst: option.gst.india_GST,
                    gst_id: option.gst.id,
                    // hsn: option.hsn_code,
                    polymer: option.grade_polymer,
                    status: option.status,
                    deleted: option.is_deleted

                }))

            return options
        }
    })
}



const FetchMouldShapeDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MOULD_SHAPE_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MOULD_SHAPE_API}/fetch_all/`)
    }
}

export const useFetchMouldShapeDropDown = (searchTerm?: any) => {
    return useQuery(['mouldShape Dropdown', searchTerm], () => FetchMouldShapeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: `${option.name}`,
                status: option.status,
                deleted: option.is_deleted,

                mouldA: option.mouldA,
                mouldB: option.mouldB,
                mouldC: option.mouldC
            }))

            return options
        }
    })
}


const FetchProductTypeDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRODUCT_TYPE_API}/fetch_all/`)
}

export const useFetchMaterialTypeDropDown = (searchTerm?: any) => {
    return useQuery(['productType', searchTerm], () => FetchProductTypeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({

                value: option.id,
                label: option.type,
                status: option.status,

            }))
            return options
        }
    })
}


const FetchCavityDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CAVITY_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CAVITY_API}/fetch_all/`)
    }
}

export const useFetchCavityDropDown = (searchTerm?: any) => {
    return useQuery(['cavity Dropdown', searchTerm], () => FetchCavityDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({

                value: option.id,
                label: option.product_name?.name,
                mould: option.mould_usage.id,
                status: option.status,
                deleted: option.is_deleted
            }))
            return options
        }
    })
}
const FetchMouldShapeDiameterDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MOULD_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MOULD_API}/fetch_all/`)
    }
}

export const useFetchMouldShapeDiameterDropDown = (searchTerm?: any) => {
    return useQuery(['Mould Shape Diameter Dropdown', searchTerm], () => FetchMouldShapeDiameterDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) =>
            ({


                value: option.id,
                label: `${option.mould_shape?.name}::${option?.product_mould_diameter_mm}::${option?.product_width_diameter ? option?.product_width_diameter : "0.000"}::${option?.product_length_diameter ? option?.product_length_diameter : "0.000"}::${option?.cavity_qty}`,
                status: option.status,
                deleted: option.is_deleted
            }))
            return options

        }
    })
}





const FetchGSTDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GST_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GST_API}/fetch_all/`)
    }
}

export const useFetchGSTDropDown = (searchTerm?: any) => {
    return useQuery(['gst Dropdown', searchTerm], () => FetchGSTDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({

                value: option.id,
                label: option.india_GST,
                status: option.status,
                deleted: option.is_deleted,

            }))
            return options
        }
    })
}


const FetchMouldDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MOULD_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MOULD_API}/fetch_all/`)
    }
}

export const useFetchMouldDropDown = (searchTerm?: any) => {
    return useQuery(['mould Dropdown', searchTerm], () => FetchMouldDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({

                value: option.id,
                label: `${option.mould_code}::${option.product_mould_diameter_mm}::${option.cavity_qty}`,
                mould_name: `${option.mould_name_type_category_hsn_code_and_gst?.grade_type?.material_type_name}::${option.mould_name_type_category_hsn_code_and_gst?.particular_sector_name?.name}::${option.mould_name_type_category_hsn_code_and_gst?.product_categories?.name}::${option.mould_name_type_category_hsn_code_and_gst?.hsn_code}::::${option.mould_name_type_category_hsn_code_and_gst?.gst?.india_GST}`,
                cavityQty: option.cavity_qty,
                status: option.status,
                deleted: option.is_deleted
            }))
            return options
        }
    })
}


const FetchPriceListDropDown = (searchTerm?: any) => {

    // if(searchTerm){
    // return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRICELIST_API}/fetch_all?searchTerm=${searchTerm}`)
    // }else{
    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRICELIST_API}/fetch_all/`)
    // }
}

export const useFetchPriceListDropDown = (searchTerm?: any) => {
    return useQuery(['priceList', searchTerm], () => FetchPriceListDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({

                value: option.id,
                label: option.code?.product_code?.product_name_and_grade?.material_type_name,
                producat_value: option.product_name_and_grade_and_type?.id,
                gst: option.gst_rate?.india_GST,
                gst_amount: option.gst_amount,
                ml_price_list: option.ml_price_list,
                stick_nos: option.stick_nos,
                carton_stick: option.carton_stick,
                product_total_qty: option.product_total_qty,
                product_weight_price_list: option.product_weight_price_list,
                carton_weight: option.carton_weight,
                status: option.status,
                deleted: option.is_deleted
            }))
            return options
        }
    })
}


const FetchPanniDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PANNI_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PANNI_API}/fetch_all/`)
    }
}

export const useFetchPanniDropDown = (searchTerm?: any) => {
    return useQuery(['panni Dropdown', searchTerm], () => FetchPanniDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                // label:option.panni_code,
                label: `${option.panni_code} ${option?.hsn_code?.grade_type?.material_type_name} ${option?.hsn_code?.particular_sector_name?.name}`,
                value: option.id,
                type: option.type,
                panniGadget: option.gadget,
                panniGadgetSize: option.gadget_size,
                panniKan: option.kan,
                panniKanLength: option.kan_length,
                panniKanWidth: option.kan_width,
                panniMaterial: option.material_use,
                panniSealing: option.sealing_type,
                panniLength: option.length,
                panniWidth: option.width,
                panniMicron: option.micron,
                panniGauge: option.gauge,
                panniOneNosWeight: option.panni_1_nos_weight_gram,
                panniOneKgNos: option.panni_1KG_in_nos,
                brand: option?.brand,
                brand_type: option.brand_type,
                color: option.printing_color_name,
                printing_total_color: option.printing_total_color,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}


const FetchStickDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_STICK_PACKING_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_STICK_PACKING_API}/fetch_all/`)
    }
}

export const useFetchStickDropDown = (searchTerm?: any) => {
    return useQuery(['Stick Dropdown', searchTerm], () => FetchStickDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}

const FetchCartonDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CARTON_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CARTON_API}/fetch_all/`)
    }
}

export const useFetchCartonDropDown = (searchTerm?: any) => {
    return useQuery(['Carton Dropdown', searchTerm], () => FetchCartonDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: `${option.carton_code}-${option?.material_name_and_type_category_and_hsn_code_and_gst?.grade_type?.material_type_name}`,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}

const FetchPiceCodeDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SINGLE_CODE_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SINGLE_CODE_API}/fetch_all/`)
    }
}

export const useFetchPiceCodeDropDown = (searchTerm?: any) => {
    return useQuery(['single PiceCode', searchTerm], () => FetchPiceCodeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.single_piece_code,
                tooltip: `${option.single_piece_code} ${option.material_type?.name} ${option.extra_response?.company_color_name} ${option.product_width_mm} ${option.product_length_mm}
                 ${option.thickness_micron} ${option.thickness_gauge} ${option.gadget_size_mm} ${option.product_kan_width_mm} ${option.product_kan_length_mm} ${option.product_sealing_type?.name} ${option.product_1_pcs_weight} ${option.product_our_other?.name}  ${option.product_special_running_type?.name}`,
                hsn_code: option.HSN_code,
                cavity_qty: option.cavity_qty,
                cavity_to_product: option.cavity_to_product,
                color: option.color,
                panni_code: option.panni,
              
                
                product_type: option.product_type?.name,
                company_color_name: option.extra_response?.company_color_name,
                product_grade_name: `${option.extra_response?.HSN_code?.material_type_name} ${option.extra_response?.HSN_code?.particular_sector_name} ${option.extra_response?.HSN_code?.product_categories_name}`,
                price_list_1_pcs_weight: option.price_list_1_pcs_weight,
                product_1_pcs_weight: option.product_1_pcs_weight,
                mould_code_and_cavity: `${option.cavity_to_product?.mould_usage?.mould_code}::${option.cavity_to_product?.mould_usage?.cavity_qty}`,
                product_ml_price_list: option.product_ml_price_list?.name,
                product_actual_ml: option.product_actual_ml?.name,
                product_ml_actual: option.product_ml_actual,
                product_final_height_mm: option.product_final_height_mm,
                product_current_height_mm: option.product_current_height_mm,
                product_lip: option.product_lip,
                current_lip: option.current_lip?.name,
                material_type: option.material_type?.name,
                product_length_mm: option.product_length_mm,
                product_width_mm: option.product_width_mm,
                thickness_micron: option.thickness_micron,
                thickness_gauge: option.thickness_gauge,
                gadget_yes_no: option.gadget_yes_no,
                gadget_size_mm: option.gadget_size_mm,
                product_kan_yes_no: option.product_kan_yes_no,
                product_kan_length_mm: option.product_kan_length_mm,
                product_kan_width_mm: option.product_kan_width_mm,
                product_sealing_type: option.product_sealing_type?.name,
                product_ml: option.product_ml,
                product_bursting_strength: option.product_bursting_strength,
                product_special_running_type: option.product_special_running_type?.name,
                product_our_other: option.product_our_other?.name,
                mould_shape: option.extra_response?.mould_shape?.mould_shape_name,
                product_length_diameter: option.extra_response?.mould_shape?.product_length_diameter,
                product_mould_diameter_mm: option.extra_response?.mould_shape?.product_mould_diameter_mm,
                product_width_diameter: option.extra_response?.mould_shape?.product_width_diameter,


                price_list_mould_diameter_mm: option.price_list_mould_diameter_mm?.name,
                production_mould_diameter_mm: option.production_mould_diameter_mm



            }))
            return options

        }
    })
}
const FetchSinglePiceCodeDropDown = (Product_Type?: any) => {

    return Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SINGLE_CODE_API}/search_filter/`,{
        product_type:Product_Type
        })
}

export const useFetchSinglePiceCodeDropDown = (Product_Type?: any) => {
    return useQuery(['PiceCode', Product_Type], () => FetchSinglePiceCodeDropDown(Product_Type), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.single_piece_code,
                tooltip: `${option.single_piece_code} ${option.material_type?.name} ${option.extra_response?.company_color_name} ${option.product_width_mm} ${option.product_length_mm}
                 ${option.thickness_micron} ${option.thickness_gauge} ${option.gadget_size_mm} ${option.product_kan_width_mm} ${option.product_kan_length_mm} ${option.product_sealing_type?.name} ${option.product_1_pcs_weight} ${option.product_our_other?.name}  ${option.product_special_running_type?.name}`,
                hsn_code: option.HSN_code,
                cavity_qty: option.cavity_qty,
                cavity_to_product: option.cavity_to_product,
                color: option.color,
                panni_code: option.panni,
              
                
                product_type: option.product_type?.name,
                company_color_name: option.extra_response?.company_color_name,
                product_grade_name: `${option.extra_response?.HSN_code?.material_type_name} ${option.extra_response?.HSN_code?.particular_sector_name} ${option.extra_response?.HSN_code?.product_categories_name}`,
                price_list_1_pcs_weight: option.price_list_1_pcs_weight,
                product_1_pcs_weight: option.product_1_pcs_weight,
                mould_code_and_cavity: `${option.cavity_to_product?.mould_usage?.mould_code}::${option.cavity_to_product?.mould_usage?.cavity_qty}`,
                product_ml_price_list: option.product_ml_price_list?.name,
                product_actual_ml: option.product_actual_ml?.name,
                product_ml_actual: option.product_ml_actual,
                product_final_height_mm: option.product_final_height_mm,
                product_current_height_mm: option.product_current_height_mm,
                product_lip: option.product_lip,
                current_lip: option.current_lip?.name,
                material_type: option.material_type?.name,
                product_length_mm: option.product_length_mm,
                product_width_mm: option.product_width_mm,
                thickness_micron: option.thickness_micron,
                thickness_gauge: option.thickness_gauge,
                gadget_yes_no: option.gadget_yes_no,
                gadget_size_mm: option.gadget_size_mm,
                product_kan_yes_no: option.product_kan_yes_no,
                product_kan_length_mm: option.product_kan_length_mm,
                product_kan_width_mm: option.product_kan_width_mm,
                product_sealing_type: option.product_sealing_type?.name,
                product_ml: option.product_ml,
                product_bursting_strength: option.product_bursting_strength,
                product_special_running_type: option.product_special_running_type?.name,
                product_our_other: option.product_our_other?.name,
                mould_shape: option.extra_response?.mould_shape?.mould_shape_name,
                product_length_diameter: option.extra_response?.mould_shape?.product_length_diameter,
                product_mould_diameter_mm: option.extra_response?.mould_shape?.product_mould_diameter_mm,
                product_width_diameter: option.extra_response?.mould_shape?.product_width_diameter,


                price_list_mould_diameter_mm: option.price_list_mould_diameter_mm?.name,
                production_mould_diameter_mm: option.production_mould_diameter_mm



            }))
            return options

        }
    })
}

const FetchDepartmentDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HR_DEPARTMENT_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HR_DEPARTMENT_API}/fetch_all/`)
    }
}

export const useFetchDepartmentDropDown = (searchTerm?: any) => {
    return useQuery(['Department', searchTerm], () => FetchDepartmentDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.department,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchMaintenanceDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MAINTENANCE_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MAINTENANCE_API}/fetch_all/`)
    }
}

export const useFetchMaintenanceDropDown = (searchTerm?: any) => {
    return useQuery(['Maintenance', searchTerm], () => FetchMaintenanceDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.maintenance_fault,
                reason: option.reason_detail,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchFaultDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MAINTENANCE_DETAIL_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MAINTENANCE_DETAIL_API}/fetch_all/`)
    }
}

export const useFetchFaultDropDown = (searchTerm?: any) => {
    return useQuery(['Fault', searchTerm], () => FetchFaultDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.maintenance_fault,
                reason: option.reason_detail,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}

const FetchActionDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MAINTENANCE_ACTION_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MAINTENANCE_ACTION_API}/fetch_all/`)
    }
}

export const useFetchActionDropDown = (searchTerm?: any) => {
    return useQuery(['Action', searchTerm], () => FetchActionDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.other_status,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}



const FetchDesignationDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_DESIGNATION_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_DESIGNATION_API}/fetch_all/`)
    }
}

export const useFetchDesignationDropDown = (searchTerm?: any) => {
    return useQuery(['Designation', searchTerm], () => FetchDesignationDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.designation,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}



const FetchEmployeeDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PERSON_EMPLOYEE_API}/fetch_all/`)

}

export const useFetchEmployeeDropDown = (searchTerm?: any) => {
    return useQuery(['Employee', searchTerm], () => FetchEmployeeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: `${option.code}-${option.surname} ${option.name} ${option.father_name[0]}`,
                code: option.code,
                name: option.name,
                customerName: option.customer_name,
                joiningDate: option.joining_date,
                department: option.extra_response?.department?.department_name,
                department_id: option.department,
                designation: option.extra_response?.designation?.designation_name,
                city: `${option.city_name_and_distict_name?.city_name ? option.city_name_and_distict_name?.city_name : option.city_name_and_distict_name?.village_name}-${option.city_name_and_distict_name?.district?.name}`,
                cityName: `${option.address[0]?.city?.city_name ? option.address[0]?.city?.city_name : option.address[0]?.city?.village_name}-${option.address[0]?.city?.district?.name}`,
                countryCode: option?.address[0]?.city?.country?.country_code_mobile,
                mobile: option?.extra_response?.contact_detail?.contact_number,
                email: option?.extra_response?.contact_detail?.email_address,
                reference: `${option?.reference?.name ? option?.reference?.name : option?.other_reference}`,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchCustomerDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PERSON_CUSTOMER_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PERSON_CUSTOMER_API}/fetch_all/`)
    }
}

export const useFetchCustomerDropDown = (searchTerm?: any) => {
    return useQuery(['Customer', searchTerm], () => FetchCustomerDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,

                label: `${option.code}::${option.customer_name}`,
                name: option?.customer_name,
                mobile: option.extra_response?.contact_detail?.contact_number,
                email: option.extra_response?.contact_detail?.email_address,
                city: `${option.extra_response?.city_name_and_distict_name?.city_name ? option.extra_response?.city_name_and_distict_name?.city_name : option.extra_response?.city_name_and_distict_name?.village_name}-${option.extra_response?.city_name_and_distict_name?.district_name}`,
                address: option?.address,
                transport_detail: option?.transport_detail,
                zone: option?.zone,
                lead: option?.lead_generate,
                pincode: option?.address[0]?.city?.pincode?.pincode,
                taluka: option?.address[0]?.city?.taluka?.name,
                district: option?.address[0]?.city?.district?.name,
                state: option?.address[0]?.city?.state?.name,
                country: option?.address[0]?.city?.country?.name,
                countryCode: option.extra_response?.city_name_and_distict_name?.country_code,
                reference: `${option?.extra_response?.reference?.name ? option?.extra_response?.reference?.name : option?.other_reference}`,
                grade: option?.customer_grade,
                // grade:option?.customer_grade?.grade,

                status: option.status,
                deleted: option.is_deleted

            }))


            return options
        }
    })
}









const FetchSidebar = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SIDEBAR}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SIDEBAR}/fetch_all/`)
    }
}

export const useFetchSidebar = () => {
    return useQuery(['Sidebar'], () => FetchSidebar(), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.title


            }))

            return data.data?.results
        }
    })
}


const FetchUnit = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNIT}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNIT}/fetch_all/`)
    }
}

export const useFetchUnit = () => {
    return useQuery(['Unit'], () => FetchUnit(), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.title

            }))

            return options
        }
    })
}

const FetchProductMLDropDown = (searchTerm?: any) => {

    // if(searchTerm){
    //     return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRICELIST_NAME_API}/fetch_all?searchTerm=${searchTerm}`)
    // }else{
    //     return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRICELIST_NAME_API}/fetch_all/`)
    // }
    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRODUCT_ML_API}/fetch_all/`)

}

export const useFetchProductMLDropDown = (searchTerm?: any) => {
    return useQuery(['Product ML', searchTerm], () => FetchProductMLDropDown(searchTerm), {
        select: (data: any) => {
            const options = data?.data?.results?.map((option: any) => ({


                value: option.id,
                label: option.name,
                status: option.status,
                //    deleted:option.is_deleted
            }))

            return options

        }
    })
}
const FetchPackingRollQtyDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_STRAP_PATTI_PACKING_ROLL_QTY_API}/fetch_all/`)

}

export const useFetchPackingRollQtyDropDown = (searchTerm?: any) => {
    return useQuery(['Strap Patti Packing Roll Kg', searchTerm], () => FetchPackingRollQtyDropDown(searchTerm), {
        select: (data: any) => {
            const options = data?.data?.results?.map((option: any) => ({


                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted
            }))


            return options

        }
    })
}
const FetchPackingBoriDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_STRAP_PATTI_ROLL_IN_PACKING_BORI_API}/fetch_all/`)

}

export const useFetchPackingBoriDropDown = (searchTerm?: any) => {
    return useQuery(['Strap Patti Roll In Packing Bori', searchTerm], () => FetchPackingBoriDropDown(searchTerm), {
        select: (data: any) => {
            const options = data?.data?.results?.map((option: any) => ({


                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted
            }))

            return options

        }
    })
}
const FetchProductActualMLDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRODUCT_ACTUAL_ML_API}/fetch_all/`)

}

export const useFetchProductActualMLDropDown = (searchTerm?: any) => {
    return useQuery(['Product Actual ML', searchTerm], () => FetchProductActualMLDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({


                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted
            }))
            return options
        }
    })
}
const FetchCurrentLipDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CURRENT_LIP_API}/fetch_all/`)

}

export const useFetchCurrentLipDropDown = (searchTerm?: any) => {
    return useQuery(['currentLip', searchTerm], () => FetchCurrentLipDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({


                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted
            }))
            return options
        }
    })
}
const FetchMouldDiameterMMDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRICE_LIST_MOULD_DIAMETER_MM_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRICE_LIST_MOULD_DIAMETER_MM_API}/fetch_all/`)
    }
}

export const useFetchMouldDiameterMMDropDown = (searchTerm?: any) => {
    return useQuery(['Mould Diameter MM', searchTerm], () => FetchMouldDiameterMMDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({


                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted
            }))
            return options
        }
    })
}
const FetchItemDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MAINTENANCE_ITEM_SOLUTION_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MAINTENANCE_ITEM_SOLUTION_API}/fetch_all/`)
    }
}

export const useFetchItemDropDown = (searchTerm?: any) => {
    return useQuery(['Item Repairing-New', searchTerm], () => FetchItemDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({


                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted
            }))
            return options
        }
    })
}
const FetchStockDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MAINTENANCE_ITEM_STOCK_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MAINTENANCE_ITEM_STOCK_API}/fetch_all/`)
    }
}

export const useFetchStockDropDown = (searchTerm?: any) => {
    return useQuery(['Item Stock', searchTerm], () => FetchStockDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({


                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted
            }))
            return options
        }
    })
}





const FetchPaymentMethodDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_CUSTOMER_PAYMENT_METHOD_API}/fetch_all/`)

}

export const useFetchPaymentMethodDropDown = (searchTerm?: any) => {
    return useQuery(['PaymentMethod', searchTerm], () => FetchPaymentMethodDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({


                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted
            }))
            return options
        }
    })
}


const FetchLatePaymentDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_LATE_PAYMENT_INTEREST_API}/fetch_all/`)

}

export const useFetchLatePaymentDropDown = (searchTerm?: any) => {
    return useQuery(['LatePayment', searchTerm], () => FetchLatePaymentDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({


                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted
            }))
            return options
        }
    })
}


const FetchCustomerRunningNotDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_CUSTOMER_RUNNING_NOT_API}/fetch_all/`)

}

export const useFetchCustomerRunningNotDropDown = (searchTerm?: any) => {
    return useQuery(['CustomerRunningNot', searchTerm], () => FetchCustomerRunningNotDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({


                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted
            }))
            return options
        }
    })
}


const FetchProductAllowDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_PRODUCT_ALLOW_API}/fetch_all/`)

}

export const useFetchProductAllowDropDown = (searchTerm?: any) => {
    return useQuery(['ProductAllow', searchTerm], () => FetchProductAllowDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({


                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted
            }))
            return options
        }
    })
}










const FetchCavityNameDropDown = (searchTerm?: any) => {



    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}cavityName/fetch_all/`)

}

export const useFetchCavityNameDropDown = (searchTerm?: any) => {
    return useQuery(['CavityName', searchTerm], () => FetchCavityNameDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchProductNameDropDown = (searchTerm?: any) => {



    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRODUCT_NAME_API}/fetch_all/`)

}

export const useFetchProductNameDropDown = (searchTerm?: any) => {
    return useQuery(['Product Name', searchTerm], () => FetchProductNameDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchCavityShapeDropDown = (searchTerm?: any) => {



    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CAVITY_SHAPE_API}/fetch_all/`)

}

export const useFetchCavityShapeDropDown = (searchTerm?: any) => {
    return useQuery(['Cavity Shape', searchTerm], () => FetchCavityShapeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}

const FetchMachineNameDropDown = (searchTerm?: any) => {



    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}machineName/fetch_all/`)

}

export const useFetchMachineNameDropDown = (searchTerm?: any) => {
    return useQuery(['MachineName', searchTerm], () => FetchMachineNameDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchCategoryDropDown = (searchTerm?: any) => {



    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRODUCT_CATEGORY_API}/fetch_all/`)

}

export const useFetchCategoryDropDown = (searchTerm?: any) => {
    return useQuery(['Category', searchTerm], () => FetchCategoryDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}

const FetchInquiryProductTypeDropDown  = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_INQUIRY_PRODUCT_TYPE_API}/fetch_all/`)

}

export const useFetchInquiryProductTypeDropDown = (searchTerm?: any) => {
    return useQuery(['Inquiry Product Type', searchTerm], () => FetchInquiryProductTypeDropDown (searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}


const FetchSingleCodeProductTypeDropDown = (searchTerm?: any) => {



    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SINGLE_CODE_PRODUCT_TYPE_API}/fetch_all/`)

}

export const useFetchSingleCodeProductTypeDropDown = (searchTerm?: any) => {
    return useQuery(['Singlecode Product Type', searchTerm], () => FetchSingleCodeProductTypeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchHSNProductTypeDropDown = (searchTerm?: any) => {



    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PARTICULAR_SECTOR_NAME_API}/fetch_all/`)

}

export const useFetchHSNProductTypeDropDown = (searchTerm?: any) => {
    return useQuery(['HSN Product Type', searchTerm], () => FetchHSNProductTypeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchGstTypeDropDown = (searchTerm?: any) => {



    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GST_TYPE_API}/fetch_all/`)

}

export const useFetchGstTypeDropDown = (searchTerm?: any) => {
    return useQuery(['Gst Type', searchTerm], () => FetchGstTypeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}


const FetchMachineDropDown = (searchTerm?: any) => {



    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}machine/fetch_all/`)

}

export const useFetchMachineDropDown = (searchTerm?: any) => {
    return useQuery(['Machine', searchTerm], () => FetchMachineDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: `${option?.plant_machine_number}::${option?.machine_model_no}`,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}


const FetchMouldMachineDropDown = (searchTerm?: any) => {



    return Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MACHINE_API}/search_filter/`, {
        machine_name: "T. F. M."
    })

}

export const useFetchMouldMachineDropDown = (searchTerm?: any) => {
    return useQuery(['Mould Machine', searchTerm], () => FetchMouldMachineDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option?.plant_machine_number,
                // label: `${option?.hsn_code?.particular_sector_name?.name} - ${option?.plant_machine_number}`,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}


const FetchUserAdminDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LEOUSER_GROUP_API}/fetch_all/`)

}

export const useFetchUserAdminDropDown = (searchTerm?: any) => {
    return useQuery(['LeoUser Group', searchTerm], () => FetchUserAdminDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({


                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted
            }))
            return options
        }
    })
}





const FetchResponsiblePerson = (departmentLabel?: any) => {

    if (departmentLabel) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LEOUSER_API}/fetch_all?department=${departmentLabel}`)

    } else {

        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LEOUSER_API}/fetch_all/`)
    }

}

export const useFetchResponsiblePerson = (departmentLabel?: any) => {
    return useQuery(['responsible person', departmentLabel], () => FetchResponsiblePerson(departmentLabel), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({


                value: option.id,
                label: option.username,
                //    status:option.status,
                //    deleted:option.is_deleted
            }))
            return options
        }
    })
}





// const FetchBulkCartonPly=(searchTerm?:any)=>{


//     return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}cartonPly/fetch_all/`)

// }

// export const useFetchBulkCartonPly = (searchTerm?:any) => {
// return useQuery(['bulk_carton_ply',searchTerm],()=>FetchBulkCartonPly(searchTerm),{
//     select:(data:any)=>{
//         const options = data.data.results.map((option: any) =>{
//             return option
//         })
//         return options
//     }
// })
// }





const FetchSuperAccountDropDown = (searchTerm?: any) => {



    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUPER_ACCOUNT_API}/fetch_all/`)

}

export const useFetchSuperAccountDropDown = (searchTerm?: any) => {
    return useQuery(['Super Account', searchTerm], () => FetchSuperAccountDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}



const FetchSubGroupDropDown = (superAccountName?: any) => {



    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUB_GROUP_API}/get_subAccountGroup/?name=${superAccountName}`)

}

export const useFetchSubGroupDropDown = (superAccountName?: any) => {
    return useQuery(['Sub Group', superAccountName], () => FetchSubGroupDropDown(superAccountName), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}



const FetchAccountTypeDropDown = (searchTerm?: any) => {



    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACCOUNT_STATEMENT_TYPE_API}/fetch_all/`)

}

export const useFetchAccountTypeDropDown = (searchTerm?: any) => {
    return useQuery(['Account Statement Type', searchTerm], () => FetchAccountTypeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchSeqDropDown = (searchTerm?: any) => {



    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACCOUNT_SEQ_API}/fetch_all/`)

}

export const useFetchSeqDropDown = (searchTerm?: any) => {
    return useQuery(['Seq', searchTerm], () => FetchSeqDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchAccountCategoryDropDown = (searchTerm?: any) => {



    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACCOUNT_CATEGORY_API}/fetch_all/`)

}

export const useFetchAccountCategoryDropDown = (searchTerm?: any) => {
    return useQuery(['Account Category', searchTerm], () => FetchAccountCategoryDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}



const FetchCheckListMapperDropDown = (searchTerm?: any) => {



    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CHECKLIST_MAPPER_API}/fetch_all/`)

}

export const useFetchCheckListMapperDropDown = (searchTerm?: any) => {
    return useQuery(['Checklist Mapper', searchTerm], () => FetchCheckListMapperDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.checkList.id,
                choice_yes_no: option.choice_yes_no

                //    label:option.name,
                //    status:option.status,
                //    deleted:option.is_deleted

            }))

            return options
        }
    })
}
const FetchAccountDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACCOUNT_TYPE_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACCOUNT_TYPE_API}/fetch_all/`)
    }
}

export const useFetchAccountDropDown = (searchTerm?: any) => {
    return useQuery(['Account', searchTerm], () => FetchAccountDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.category?.name,
                //    label:option.category,    
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}

const FetchDistrictDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DISTRICT_API}/search_district?id=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DISTRICT_API}/fetch_all/`)
    }
}

export const useFetchDistrictDropDown = (searchTerm?: any) => {
    return useQuery(['District', searchTerm], () => FetchDistrictDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchEmployeeTypeDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DISTRICT_API}/search_district?id=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DISTRICT_API}/fetch_all/`)
    }
}

export const useFetchEmployeeTypeDropDown = (searchTerm?: any) => {
    return useQuery(['Employee Type', searchTerm], () => FetchEmployeeTypeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchSalaryTypeDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_EMPLOYEE_SALARY_TYPE_API}/search_district?id=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_EMPLOYEE_SALARY_TYPE_API}/fetch_all/`)
    }
}

export const useFetchSalaryTypeDropDown = (searchTerm?: any) => {
    return useQuery(['Salary Type', searchTerm], () => FetchSalaryTypeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchStateDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_STATE_API}/search_state?id=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_STATE_API}/fetch_all/`)
    }
}

export const useFetchStateDropDown = (searchTerm?: any) => {
    return useQuery(['State', searchTerm], () => FetchStateDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}
const FetchCountryDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_COUNTRY_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_COUNTRY_API}/fetch_all/`)
    }
}

export const useFetchCountryDropDown = (searchTerm?: any) => {
    return useQuery(['Country', searchTerm], () => FetchCountryDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                countryCode: option.country_code_mobile,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}
const FetchTypeDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PERSON_TYPE_API}/fetch_all/`)

}

export const useFetchPersonTypeDropDown = (searchTerm?: any) => {
    return useQuery(['Person Type', searchTerm], () => FetchTypeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}

const FetchNationalityDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_NATIONALITY_API}/fetch_all/`)

}

export const useFetchNationalityDropDown = (searchTerm?: any) => {
    return useQuery(['Nationality', searchTerm], () => FetchNationalityDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}

const FetchReligionDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_RELIGION_API}/fetch_all/`)

}

export const useFetchReligionDropDown = (searchTerm?: any) => {
    return useQuery(['Religion', searchTerm], () => FetchReligionDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}


const FetchBloodGroupDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_EMPLOYEE_BLOODGROUP_API}/fetch_all/`)

}

export const useFetchBloodGroupDropDown = (searchTerm?: any) => {
    return useQuery(['Blood Group', searchTerm], () => FetchBloodGroupDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}



const FetchOrganizationTypeDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CUSTOMER_ORGANIZATION_TYPE_API}/fetch_all/`)

}

export const useFetchOrganizationTypeDropDown = (searchTerm?: any) => {
    return useQuery(['Organization Type', searchTerm], () => FetchOrganizationTypeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}
const FetchPersonOccupationDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CUSTOMER_OCCUPATION_API}/fetch_all/`)

}

export const useFetchPersonOccupationDropDown = (searchTerm?: any) => {
    return useQuery(['Person Occupation', searchTerm], () => FetchPersonOccupationDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}
const FetchPersonSalesStatusDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CUSTOMER_SALES_STATUS_API}/fetch_all/`)

}

export const useFetchPersonSalesStatusDropDown = (searchTerm?: any) => {
    return useQuery(['Person Sales Status', searchTerm], () => FetchPersonSalesStatusDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}
const FetchPersonPurchaseTypeDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CUSTOMER_BUYING_TYPE_API}/fetch_all/`)

}

export const useFetchPersonPurchaseTypeDropDown = (searchTerm?: any) => {
    return useQuery(['Person Purchase Type', searchTerm], () => FetchPersonPurchaseTypeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}
const FetchTypeOfBusinessDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CUSTOMER_BUSINESS_TYPE_API}/fetch_all/`)

}

export const useFetchTypeOfBusinessDropDown = (searchTerm?: any) => {
    return useQuery(['Type Of Business', searchTerm], () => FetchTypeOfBusinessDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}
const FetchTitleDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_EMPLOYEE_TITLE_API}/fetch_all/`)

}

export const useFetchTitleDropDown = (searchTerm?: any) => {
    return useQuery(['Title', searchTerm], () => FetchTitleDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}
const FetchCasteDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_EMPLOYEE_CASTE_API}/fetch_all/`)

}

export const useFetchCasteDropDown = (searchTerm?: any) => {
    return useQuery(['Caste', searchTerm], () => FetchCasteDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}

const FetchWeeklyOffDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_EMPLOYEE_WEEKLY_OFF_API}/fetch_all/`)

}

export const useFetchWeeklyOffDropDown = (searchTerm?: any) => {
    return useQuery(['Weekly Off', searchTerm], () => FetchWeeklyOffDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}
const FetchAdministrationTypeDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_EMPLOYEE_ADMINISTRATION_API}/fetch_all/`)

}

export const useFetchAdministrationTypeDropDown = (searchTerm?: any) => {
    return useQuery(['Administration Type', searchTerm], () => FetchAdministrationTypeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}




const FetchCityDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CITY_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CITY_API}/fetch_all/`)
    }
}

export const useFetchCityDropDown = (searchTerm?: any) => {
    return useQuery(['City', searchTerm], () => FetchCityDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: `${option.code}::${option.city_name ? option.city_name : option.village_name}`,
                code: option.code,
                city: option.city_name,
                village: option.village_name,
                state: option.state.name,
                stateID: option.state.id,
                country: option.country.name,
                countryCode: option.country?.country_code_mobile,
                countryID: option.country.id,
                district: option.district.name,
                districtID: option.district.id,
                pincode: option.pincode,
                pincodeID: option.pincode.id,
                taluka: option.taluka?.name,
                talukaID: option.taluka?.id,
                status: option.status,
                deleted: option.is_deleted

            }))
            const filteredOptions = options.filter((option: any) => option.label.trim() !== '');

            return filteredOptions


        }
    })
}
const FetchCityPincodeDropDown = (searchTerm?: any) => {
    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CITY_API}/search_pincode?id=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CITY_API}/fetch_all/`)
    }

}

export const useFetchCityPincodeDropDown = (searchTerm?: any) => {
    return useQuery(['pincode', searchTerm], () => FetchCityPincodeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.pincode,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}



const FetchUOMDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CAPACITY_API}/fetch_all/`)

}

export const useFetchUOMDropDown = (searchTerm?: any) => {
    return useQuery(['UOM', searchTerm], () => FetchUOMDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name?.name,
                baseUnit: option?.baseUnit?.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}



// const FetchPersonTypeDropDown=(searchTerm?:any)=>{


//     return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PERSON_TYPE_API}/fetch_all/`)

// }

// export const useFetchPersonTypeDropDown = (searchTerm?:any) => {
// return useQuery(['Person Type',searchTerm],()=>FetchPersonTypeDropDown(searchTerm),{
//     select:(data:any)=>{
//         const options = data.data.results.map((option: any) =>({
//            value:option.id,
//            label:option.name?.name,
//            status:option.status,
//            deleted:option.is_deleted

//         }))

//         return options
//     }
// })
// }


const FetchTalukaDropDown = (searchTerm?: any) => {

    // if(searchTerm){
    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_TALUKA_API}/search_taluka?id=${searchTerm}`)
    // }else{
    //     return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_TALUKA_API}/fetch_all/`)
    // }
}

export const useFetchTalukaDropDown = (searchTerm?: any) => {
    return useQuery(['Taluka', searchTerm], () => FetchTalukaDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                //    state:option.state.name,
                //    stateID:option.state.id,
                //    country:option.country.name,
                //    countryID:option.country.id,
                //    district:option.district.name,
                //    districtID:option.district.id,

                status: option.status,
                deleted: option.is_deleted

            }))


            return options
        }
    })
}



const FetchPincodeDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PINCODE_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PINCODE_API}/fetch_all/`)
    }
}

export const useFetchPincodeDropDown = (searchTerm?: any) => {
    return useQuery(['pincode', searchTerm], () => FetchPincodeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.pincode,
                status: option.status,
                deleted: option.is_deleted

            }))


            return options
        }
    })
}




const FetchZoneDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ZONE_API}/search_district?id=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ZONE_API}/fetch_all/`)
    }
}

export const useFetchZoneDropDown = (searchTerm?: any) => {
    return useQuery(['Zone', searchTerm], () => FetchZoneDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.route_name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}



const FetchGradeDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CUSTOMER_GRADE_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CUSTOMER_GRADE_API}/fetch_all/`)
    }
}

export const useFetchGradeDropDown = (searchTerm?: any) => {
    return useQuery(['Customer Grade', searchTerm], () => FetchGradeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.grade,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}






const FetchIFSCDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_IFSC_API}/bank_details?ifsc_code=${searchTerm}`)
    }
    // else{
    //     return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_IFSC_API}/bank_details/fetch_all/`)
    // }
}

export const useFetchIFSCDropDown = (searchTerm?: any) => {
    return useQuery(['IFSC Verify', searchTerm], () => FetchIFSCDropDown(searchTerm), {
        select: (data: any) => {

            return data?.data
        }

    })
}

const FetchDisposableDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DISPOSABLE_PRODUCT_API}/fetch_all?searchTerm=${searchTerm}`)
    }
    else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DISPOSABLE_PRODUCT_API}/fetch_all/`)
    }
}

export const useFetchDisposableDropDown = (searchTerm?: any, product_type?: any) => {
    return useQuery(['Disposable', searchTerm], () => FetchDisposableDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: `${option.product_code} ${option.extra_response?.product_type?.type} ${option.mouldshape?.name ? option.mouldshape?.name : ""}`,
                code: option.product_code,
                name: option.extra_response?.material_type_name,
                type: option.extra_response?.product_type?.type,
                color: option.extra_response?.company_color_name[0],
                special_running: option.product_special_running,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}




const FetchSourceOfInquiryDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_SOURCE_INQUIRY_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_SOURCE_INQUIRY_API}/fetch_all/`)
    }
}

export const useFetchSourceOfInquiryDropDown = (searchTerm?: any) => {
    return useQuery(['source of inquiry', searchTerm], () => FetchSourceOfInquiryDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}


const FetchPriorityDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BANK_PRIORITY_TYPE_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BANK_PRIORITY_TYPE_API}/fetch_all/`)
    }
}

export const useFetchPriorityDropDown = (searchTerm?: any) => {
    return useQuery(['Priority', searchTerm], () => FetchPriorityDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}

const FetchBankAccountTypeDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BANK_ACCOUNT_TYPE_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BANK_ACCOUNT_TYPE_API}/fetch_all/`)
    }
}

export const useFetchBankAccountTypeDropDown = (searchTerm?: any) => {
    return useQuery(['Bank Account Type', searchTerm], () => FetchBankAccountTypeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}


const FetchSalesInquiryDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_INQUIRY_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_INQUIRY_API}/fetch_all/`)
    }
}

export const useFetchSalesInquiryDropDown = (searchTerm?: any) => {
    return useQuery(['sales inquiry ', searchTerm], () => FetchSalesInquiryDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option?.id,
                label: option?.code,
                company: `${option?.extra_response?.company_name_and_city?.customer_name}-${option.extra_response?.company_name_and_city?.city[0]?.city_name ? option.extra_response?.company_name_and_city?.city[0]?.city_name : option.extra_response?.company_name_and_city?.city[0]?.village_name}`,
                companyDate: option?.company_name_and_city?.company_date_of_birth,
                //    employee:`${option?.company_name_and_city?.code} ${option.lead_generated_by_sales_executive_code_and_name?.name} ${option.lead_generated_by_sales_executive_code_and_name?.surname}`  ,
                product: option?.product,
                gst: option?.product?.pricelist_product?.product_code?.product_name_and_grade?.gst?.india_GST,
                companyNewOld: option?.company_new_old,
                leadInquiry: option?.lead_source_of_inquiry?.name,
                company_name_and_city: option.extra_response?.company_name_and_city?.name,
                requirmentStatus: option?.requirement_status,
                reference: `${option?.extra_response?.company_name_and_city?.reference_name ? option?.extra_response?.company_name_and_city?.reference_name : option?.extra_response?.company_name_and_city?.other_reference}`,
                lead_contacted: option?.lead_contacted,
                pincode: option?.extra_response?.company_name_and_city.city[0]?.pincode[0],
                city_and_village: `${option?.extra_response?.company_name_and_city.city[0]?.city_code} ${option?.extra_response?.company_name_and_city.city[0]?.city_name ? option?.extra_response?.company_name_and_city.city[0]?.city_name : option?.extra_response?.company_name_and_city.city[0]?.village_name}`,
                taluka: option?.extra_response?.company_name_and_city.city[0]?.taluka,
                district: option?.extra_response?.company_name_and_city.city[0]?.district,
                state: option?.extra_response?.company_name_and_city.city[0]?.state,
                country: option?.extra_response?.company_name_and_city.city[0]?.country,
                mobile_number: option.extra_response?.company_name_and_city?.contact_detail_number,
                email: option.extra_response?.company_name_and_city?.email_address,
                address: option?.company_name_and_city?.address,
                customerId: option?.company_name_and_city,
                //    mobile_number:option?.mobile_number,
                is_discount_approved: option?.is_discount_approved,
                visiting_card_image: option?.visiting_card_image?.split("/").pop(),
                status: option?.status,
                deleted: option?.is_deleted

            }))

            return options

        }
    })
}







const FetchSalesOrderDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_ORDER_API}/?id=${searchTerm}`)


}

export const useFetchSalesOrderDropDown = (searchTerm?: any) => {
    return useQuery(['Sales Order', searchTerm], () => FetchSalesOrderDropDown(searchTerm), {
        select: (data: any) => {

            return data?.data?.data
        }
    })
}


const FetchDeliveryAddressDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CUSTOMER_DETAILS_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CUSTOMER_DETAILS_API}/fetch_all/`)
    }
}

export const useFetchDeliveryAddressDropDown = (searchTerm?: any) => {
    return useQuery(['Delivery Address', searchTerm], () => FetchDeliveryAddressDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.address.map((e: any) => e.address),
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchTransportationDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_TRANSPORT_FORMAT_API}/fetch_all?searchTerm=${searchTerm}`)
    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_TRANSPORT_FORMAT_API}/fetch_all/`)
    }
}

export const useFetchTransportationDropDown = (searchTerm?: any) => {
    return useQuery(['Transportation', searchTerm], () => FetchTransportationDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchPriceListProductDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRICE_LIST_PRODUCTS_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRICE_LIST_PRODUCTS_API}/fetch_all/`)
    }
}
export const useFetchPriceListProductDropDown = (searchTerm?: any) => {
    return useQuery(['Price List Products', searchTerm], () => FetchPriceListProductDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.product_code?.product_single_piece_code?.HSN_code?.particular_sector_name?.name,
                productCode: option.product_code?.product_code,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}

const FetchMachineBrandDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MACHINE_BRAND_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MACHINE_BRAND_API}/fetch_all/`)
    }
}
export const useFetchMachineBrandDropDown = (searchTerm?: any) => {
    return useQuery(['Machine Brand', searchTerm], () => FetchMachineBrandDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchMaterialBrandDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_RAWMATERIAL_BRAND_NAME_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_RAWMATERIAL_BRAND_NAME_API}/fetch_all/`)
    }
}
export const useFetchMaterialBrandDropDown = (searchTerm?: any) => {
    return useQuery(['Material Brand', searchTerm], () => FetchMaterialBrandDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}

const FetchMachineLocationDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MACHINE_LOCATION_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MACHINE_LOCATION_API}/fetch_all/`)
    }
}
export const useFetchMachineLocationDropDown = (searchTerm?: any) => {
    return useQuery(['Machine Location', searchTerm], () => FetchMachineLocationDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchCartonMoistureDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CARTON_MOISTURE_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CARTON_MOISTURE_API}/fetch_all/`)
    }
}
export const useFetchCartonMoistureDropDown = (searchTerm?: any) => {
    return useQuery(['Carton Moisture', searchTerm], () => FetchCartonMoistureDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchMouldBrandDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MOULD_BRAND_NAME_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MOULD_BRAND_NAME_API}/fetch_all/`)
    }
}
export const useFetchMouldBrandDropDown = (searchTerm?: any) => {
    return useQuery(['Mould Brand', searchTerm], () => FetchMouldBrandDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchMouldCompanyNameDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MOULD_COMPANY_NAME_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MOULD_COMPANY_NAME_API}/fetch_all/`)
    }
}
export const useFetchMouldCompanyNameDropDown = (searchTerm?: any) => {
    return useQuery(['Mould Company', searchTerm], () => FetchMouldCompanyNameDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchMaterialNameDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MATERIAL_NAME_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MATERIAL_NAME_API}/fetch_all/`)
    }
}
export const useFetchMaterialNameDropDown = (searchTerm?: any) => {
    return useQuery(['Material Name', searchTerm], () => FetchMaterialNameDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchUseCategoryNameDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_USE_CATEGORY_NAME_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_USE_CATEGORY_NAME_API}/fetch_all/`)
    }
}
export const useFetchUseCategoryNameDropDown = (searchTerm?: any) => {
    return useQuery(['Use Category', searchTerm], () => FetchUseCategoryNameDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchMaterialSourceDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MATERIAL_SOURCE_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MATERIAL_SOURCE_API}/fetch_all/`)
    }
}
export const useFetchMaterialSourceDropDown = (searchTerm?: any) => {
    return useQuery(['Material Source', searchTerm], () => FetchMaterialSourceDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchMaterialUseDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MATERIAL_USE_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MATERIAL_USE_API}/fetch_all/`)
    }
}
export const useFetchMaterialUseDropDown = (searchTerm?: any) => {
    return useQuery(['Material Use', searchTerm], () => FetchMaterialUseDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchBoxPattiDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BOX_PATTI_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BOX_PATTI_API}/fetch_all/`)
    }
}
export const useFetchBoxPattiDropDown = (searchTerm?: any) => {
    return useQuery(['Box Patti', searchTerm], () => FetchBoxPattiDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: `${option.box_patti_code} ${option.material_name_and_type_category_and_hsn_code?.grade_type?.material_type_name} ${option.material_name_and_type_category_and_hsn_code?.particular_sector_name?.name}`,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchTapeRollDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_TAPEROLL_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_TAPEROLL_API}/fetch_all/`)
    }
}
export const useFetchTapeRollDropDown = (searchTerm?: any) => {
    return useQuery(['Tape Roll', searchTerm], () => FetchTapeRollDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: `${option.tape_roll_code} ${option.material_name_and_type_and_hsn_code?.grade_type?.material_type_name} ${option.material_name_and_type_and_hsn_code?.particular_sector_name?.name}`,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchCompanyNameDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_RAWMATERIAL_COMPANY_NAME_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_RAWMATERIAL_COMPANY_NAME_API}/fetch_all/`)
    }
}
export const useFetchCompanyNameDropDown = (searchTerm?: any) => {
    return useQuery(['Company Name', searchTerm], () => FetchCompanyNameDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}


const FetchMaterialSectorDropDown = (searchTerm?: any) => {

    // if(searchTerm){
    //     return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MATERIAL_U_API}/fetch_all?searchTerm=${searchTerm}`)

    // }else{
    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MATERIAL_SECTOR_API}/fetch_all/`)
    // }
}
export const useFetchMaterialSectorDropDown = (searchTerm?: any) => {
    return useQuery(['Material Sector', searchTerm], () => FetchMaterialSectorDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}



const FetchDiscountProductDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_INQUIRY_API}/${searchTerm}/`)

    }
}
export const useFetchDiscountProductDropDown = (searchTerm?: any) => {
    return useQuery(['Discount Products', searchTerm], () => FetchDiscountProductDropDown(searchTerm), {
        select: (data: any) => {
            // const options = data.data.results.map((option: any) =>({
            //    value:option.id,
            //    label:option.product_code?.product_name_and_grade?.material_type_name,
            //    productCode:option.product_code.product_code,
            //    status:option.status,
            //    deleted:option.is_deleted

            // }))
            return data?.data?.results
        }
    })
}
// const FetchDiscountDataDropDown=(searchTerm?:any)=>{

//     if(searchTerm){
//         return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_INQUIRY_API}/${searchTerm}/`)

//     }
// }
// export const useFetchDiscountDataDropDown = (searchTerm?:any) => {
//     return useQuery(['Discount Products',searchTerm],()=>FetchDiscountDataDropDown(searchTerm),{
//         select:(data:any)=>{
//             // const options = data.data.results.map((option: any) =>({
//             //    value:option.id,
//             //    label:option.product_code?.product_name_and_grade?.material_type_name,
//             //    productCode:option.product_code.product_code,
//             //    status:option.status,
//             //    deleted:option.is_deleted

//             // }))
//             return data?.data?.results
//         }
//     })
// }
const FetchDispatchOrderDropDown = (searchTerm?: any) => {

    // if(searchTerm){
    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_SALES_ORDER_API}/?id=${searchTerm}`)

    // }
}
export const useFetchDispatchOrderDropDown = (searchTerm?: any) => {
    return useQuery(['Dispatch Order', searchTerm], () => FetchDispatchOrderDropDown(searchTerm), {
        select: (data: any) => {
            // const options = data.data.results.map((option: any) =>({
            //    value:option.id,
            //    label:option.product_code?.product_name_and_grade?.material_type_name,
            //    productCode:option.product_code.product_code,
            //    status:option.status,
            //    deleted:option.is_deleted

            // }))
            return data?.data?.results
        }
    })
}
const FetchDispatchDropDown = (searchTerm?: any) => {

    // if(searchTerm){
    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CUSTOMER_DETAILS_API}/?id=${searchTerm}`)

    // }
}
export const useFetchDispatchDropDown = (searchTerm?: any) => {
    return useQuery(['Dispatch Data', searchTerm], () => FetchDispatchDropDown(searchTerm), {
        select: (data: any) => {
            // const options = data.data.results.map((option: any) =>({
            //    value:option.id,
            //    label:option.product_code?.product_name_and_grade?.material_type_name,
            //    productCode:option.product_code.product_code,
            //    status:option.status,
            //    deleted:option.is_deleted

            // }))
            return data?.data?.results
        }
    })
}
const FetchMaterialandHSNDropDown = (searchTerm?: any) => {

    // if(searchTerm){
    // return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HSN_API}/fetch_all/`)
    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HSN_FILTER_BY_MACHINE_API}`)

    // }
}
export const useFetchMaterialandHSNDropDown = (searchTerm?: any) => {
    return useQuery(['HSN Data', searchTerm], () => FetchMaterialandHSNDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: `${option.grade_type?.material_type_name}::${option?.particular_sector_name?.name}::${option?.product_categories?.name}::${option?.hsn_code}::${option?.gst?.india_GST}`,
                //    label:`${option.grade_type?.material_type_name}::${option?.particular_sector_name?.name=== null?<span style={{ color: 'red'}}>****</span>:option?.particular_sector_name?.name}::${option?.product_categories?.name?option?.product_categories?.name:<span style={{ color: 'red' }}>****</span>}::${option?.hsn_code}::${option?.gst?.india_GST}`,
                hsn: option?.hsn_code,
                polymer: option?.grade_type?.grade_polymer,
                material: `${option?.grade_type?.material_type_name}::${option.grade_type?.material_density}::${option.grade_type?.grade_sutra}::${option.grade_type?.gst?.india_GST}`,
                material_id: option?.grade_type?.id,
                sutra: option?.grade_type?.grade_sutra,
                status: option.status,
                deleted: option.is_deleted

            }))     
            return options
        }
    })
}
const FetchMouldHSNDropDown = (searchTerm?: any) => {

    // if(searchTerm){
    // return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HSN_API}/fetch_all/`)
    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HSN_FILTER_BY_MOULD_API}`)

    // }
}
export const useFetchMouldHSNDropDown = (searchTerm?: any) => {
    return useQuery(['Mould HSN Data', searchTerm], () => FetchMouldHSNDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: `${option.grade_type?.material_type_name}::${option?.particular_sector_name?.name === null ? <span style={{ color: 'red' }}>****</span> : option?.particular_sector_name?.name}::${option?.product_categories?.name ? option?.product_categories?.name : <span style={{ color: 'red' }}>****</span>}::${option?.hsn_code}::${option?.gst?.india_GST}`,
                hsn: option?.hsn_code,
                polymer: option?.grade_type?.grade_polymer,
                material: `${option?.grade_type?.material_type_name}::${option.grade_type?.material_density}::${option.grade_type?.grade_sutra}::${option.grade_type?.gst?.india_GST}`,
                material_id: option?.grade_type?.id,
                sutra: option?.grade_type?.grade_sutra,
                status: option.status,
                deleted: option.is_deleted

            }))
            return options
        }
    })
}


const FetchPacketPakingDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PANNI_PACKET_PAKING_TYPE_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PANNI_PACKET_PAKING_TYPE_API}/fetch_all/`)
    }
}
export const useFetchPacketPakingDropDown = (searchTerm?: any) => {
    return useQuery(['Packet Paking', searchTerm], () => FetchPacketPakingDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchPackingCoverDoriDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DISPOSABLE_PRODUCT_PACKING_COVER_DORI_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DISPOSABLE_PRODUCT_PACKING_COVER_DORI_API}/fetch_all/`)
    }
}
export const useFetchPackingCoverDoriDropDown = (searchTerm?: any) => {
    return useQuery(['Packing Cover Dori', searchTerm], () => FetchPackingCoverDoriDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchPanniMaterialUseDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PANNI_MATERIAL_USE_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PANNI_MATERIAL_USE_API}/fetch_all/`)
    }
}
export const useFetchPanniMaterialUseDropDown = (searchTerm?: any) => {
    return useQuery(['Panni Material Use', searchTerm], () => FetchPanniMaterialUseDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}

const FetchUomNameDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UOM_NAME_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UOM_NAME_API}/fetch_all/`)
    }
}
export const useFetchUomNameDropDown = (searchTerm?: any) => {
    return useQuery(['U.O.M Name', searchTerm], () => FetchUomNameDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}

const FetchCartonPaperNameDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CARTON_PLY_PAPER_NAME_API}/fetch_all/`)

}
export const useFetchCartonPaperNameDropDown = (searchTerm?: any) => {
    return useQuery(['Carton Paper Name', searchTerm], () => FetchCartonPaperNameDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}

const FetchCartonPaperDesignDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CARTON_PLY_PAPER_DESIGN_API}/fetch_all/`)

}
export const useFetchCartonPaperDesignDropDown = (searchTerm?: any) => {
    return useQuery(['Carton Paper Design', searchTerm], () => FetchCartonPaperDesignDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}


const FetchCartonPaperBFDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CARTON_PLY_PAPER_BF_API}/fetch_all/`)

}
export const useFetchCartonPaperBFDropDown = (searchTerm?: any) => {
    return useQuery(['Carton Paper BF', searchTerm], () => FetchCartonPaperBFDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchCartonPaperGSMDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CARTON_PLY_PAPER_GSM_API}/fetch_all/`)

}
export const useFetchCartonPaperGSMDropDown = (searchTerm?: any) => {
    return useQuery(['Carton Paper GSM', searchTerm], () => FetchCartonPaperGSMDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}

const FetchPanniSealingTypeDropDown = (searchTerm?: any) => {

    // if(searchTerm){
    //     return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PANNI_SEALING_TYPE_API}/fetch_all?searchTerm=${searchTerm}`)

    // }else{
    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PANNI_SEALING_TYPE_API}/fetch_all/`)
    // }
}
export const useFetchPanniSealingTypeDropDown = (searchTerm?: any) => {
    return useQuery(['Panni Sealing Type', searchTerm], () => FetchPanniSealingTypeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchPanniProductPrintingTypeDropDown = (searchTerm?: any) => {

    // if(searchTerm){
    //     return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PANNI_PRINTING_TYPE_API}/fetch_all?searchTerm=${searchTerm}`)

    // }else{
    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PANNI_PRINTING_TYPE_API}/fetch_all/`)
    // }
}
export const useFetchPanniProductPrintingTypeDropDown = (searchTerm?: any) => {
    return useQuery(['Panni Printing Type', searchTerm], () => FetchPanniProductPrintingTypeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchPanniProductPakingTypeDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PANNI_PACKING_TYPE_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PANNI_PACKING_TYPE_API}/fetch_all/`)
    }
}
export const useFetchPanniProductPakingTypeDropDown = (searchTerm?: any) => {
    return useQuery(['Panni Packing Type', searchTerm], () => FetchPanniProductPakingTypeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchPanniProductByDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PANNI_PRODUCT_BY_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PANNI_PRODUCT_BY_API}/fetch_all/`)
    }
}
export const useFetchPanniProductByDropDown = (searchTerm?: any) => {
    return useQuery(['Product By', searchTerm], () => FetchPanniProductByDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchUomDropDown = (searchTerm?: any) => {

    if (searchTerm) {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CAPACITY_API}/fetch_all?searchTerm=${searchTerm}`)

    } else {
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CAPACITY_API}/fetch_all/`)
    }
}
export const useFetchUomDropDown = (searchTerm?: any) => {
    return useQuery(['Uom', searchTerm], () => FetchUomDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name?.abbreviation,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}







const FetchBundleQtyDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BUNDLE_QTY_API}/fetch_all/`)

}
export const useFetchBundleQtyDropDown = (searchTerm?: any) => {
    return useQuery(['Bundle Qty', searchTerm], () => FetchBundleQtyDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}


const FetchBundleInCartonDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BUNDLE_IN_CARTON_API}/fetch_all/`)

}
export const useFetchBundleInCartonDropDown = (searchTerm?: any) => {
    return useQuery(['Bundle In Carton', searchTerm], () => FetchBundleInCartonDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}



const FetchProductOurOtherDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRODUCT_OUR_OTHER_API}/fetch_all/`)

}
export const useFetchProductOurOtherDropDown = (searchTerm?: any) => {
    return useQuery(['Product Our Other', searchTerm], () => FetchProductOurOtherDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}



const FetchProductStarDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRODUCT_STAR_API}/fetch_all/`)

}
export const useFetchProductStarDropDown = (searchTerm?: any) => {
    return useQuery(['Product Star', searchTerm], () => FetchProductStarDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}

const FetchProductLocationDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRODUCT_LOCATION_API}/fetch_all/`)

}
export const useFetchProductLocationDropDown = (searchTerm?: any) => {
    return useQuery(['Product Location', searchTerm], () => FetchProductLocationDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}



const FetchGumNameDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GUM_NAME_API}/fetch_all/`)

}
export const useFetchGumNameDropDown = (searchTerm?: any) => {
    return useQuery(['Gum Name', searchTerm], () => FetchGumNameDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}


const FetchAddressTypeDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_ADDRESS_TYPE_API}/fetch_all/`)

}
export const useFetchAddressTypeDropDown = (searchTerm?: any) => {
    return useQuery(['Address Type', searchTerm], () => FetchAddressTypeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}



const FetchMaintenanceIssueDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MAINTENANCE_ISSUE_API}/fetch_all/`)

}
export const useFetchMaintenanceIssueDropDown = (searchTerm?: any) => {
    return useQuery(['Maintenance Issue', searchTerm], () => FetchMaintenanceIssueDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}




// PRODUCTION


const FetchParticularNameDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRODUCTION_PARTICULAR_NAME_API}/fetch_all/`)

}
export const useFetchParticularNameDropDown = (searchTerm?: any) => {
    return useQuery(['Particular Name', searchTerm], () => FetchParticularNameDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}

const FetchSheetCoreDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRODUCTION_SHEET_ROLL_CORE_ID_API}/fetch_all/`)

}
export const useFetchSheetCoreDropDown = (searchTerm?: any) => {
    return useQuery(['Sheet Core', searchTerm], () => FetchSheetCoreDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchSheetCodeNameDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRODUCTION_SHEET_CODE_API}/fetch_all/`)

}
export const useFetchSheetCodeNameDropDown = (searchTerm?: any) => {
    return useQuery(['Sheet Core', searchTerm], () => FetchSheetCodeNameDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.code,
                color: option?.sheet_color_name?.company_color_name,
                sheet_roll_weight: option?.sheet_roll_weight,
                roll_meter: option?.roll_meter,
                grade: option?.extra_response?.mould_name_and_type?.material_type_name,
                allproduct: `${option?.extra_response?.use_product_code[0]?.code}::${option?.extra_response?.use_product_code[0]?.name}::${option?.extra_response?.use_product_code[0]?.type}`,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options
        }
    })
}
const FetchRawMaterialGardeDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_RAWMATERIALGRADE_API}/fetch_all/`)

}
export const useFetchRawMaterialGardeDropDown = (searchTerm?: any) => {
    return useQuery(['Raw Material Garde', searchTerm], () => FetchRawMaterialGardeDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: `${option.material_grade_code}::${option.type.material_type_name}::${option.grade}`,
                gradeType: option.type.material_type_name,
                materialName: option.hsn_code.particular_sector_name?.name,
                grade: option.grade,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options

        }
    })
}
const FetchBatchNoDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PRODUCTION_PRODUCTION_LINE_API}/fetch_all/`)

}
export const useFetchBatchNoDropDown = (searchTerm?: any) => {
    return useQuery(['Production Line', searchTerm], () => FetchBatchNoDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.code,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options


        }
    })
}





// HRMS

const FetchHrmsAccountTypeDropdown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HRMS_ACCOUNT_TYPE_API}/fetch_all/`)

}
export const useFetchHrmsAccountTypeDropdown = (searchTerm?: any) => {
    return useQuery(['HRMS Account Type', searchTerm], () => FetchHrmsAccountTypeDropdown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options


        }
    })
}
const FetchHrmsAccountDropdown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HRMS_SUB_ACCOUNT_API}/fetch_all/`)

}
export const useFetchHrmsAccountDropdown = (searchTerm?: any) => {
    return useQuery(['HRMS Sub Account ', searchTerm], () => FetchHrmsAccountDropdown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options


        }
    })
}
const FetchMilestoneDropdown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HRMS_MILESTONE_API}/fetch_all/`)

}
export const useFetchMilestoneDropdown = (searchTerm?: any) => {
    return useQuery(['Milestone ', searchTerm], () => FetchMilestoneDropdown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options


        }
    })
}
const FetchProject = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HRMS_PROJECT_API}/fetch_all/`)

}
export const useFetchProject = (searchTerm?: any) => {
    return useQuery(['HRMS Project', searchTerm], () => FetchProject(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options


        }
    })
}
const FetchTaskStatusDropDown = (projectId?: any) => {
    
        
    return Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HRMS_TASK_STATUS_API}/search_filter/`,{
        project:projectId
        })

}
export const useFetchTaskStatusDropDown = (projectId?:any) => {
    return useQuery(['Task Status', projectId], () => FetchTaskStatusDropDown(projectId), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                project: option.project,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options


        }
    })
}
const FetchTaskPriorityDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HRMS_PRIORITY_API}/fetch_all/`)

}
export const useFetchTaskPriorityDropDown = (searchTerm?: any) => {
    return useQuery(['Task Priority', searchTerm], () => FetchTaskPriorityDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options


        }
    })
}
const FetchTeamDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HRMS_PROJECT_TEAM_API}/fetch_all/`)

}
export const useFetchTeamDropDown = (searchTerm?: any) => {
    return useQuery(['Team', searchTerm], () => FetchTeamDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                Member: option.extra_response?.user,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options


        }
    })
}
const FetchTaskDropDown = (Task_Id?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HRMS_TASK_API}`)

}
export const useFetchTaskDropDown = (Task_Id?: any) => {
    return useQuery(['Task', Task_Id], () => FetchTaskDropDown(Task_Id), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                task_status: option.task_status,
                project: option.project,
                description: option.description,
                startDate: option.start_date,
                endDate: option.end_date,
                completion_time: option.completion_time,
                priority: option.extra_response?.priority,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options


        }
    })
}


const FetchBankAccountDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACCOUNTING_BANK_ACCOUNT_ENTRY_API}/fetch_all/`)

}
export const useFetchBankAccountDropDown = (searchTerm?: any) => {
    return useQuery(['Bank Account', searchTerm], () => FetchBankAccountDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: `${option.bank_name}::${option.name}::${option.branch}`,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options


        }
    })
}
const FetchTransactionAccountDropDown = (searchTerm?: any) => {


    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACCOUNTING_TRANSACTION_ACCOUNT_ENTRY_API}/fetch_all/`)

}
export const useFetchTransactionAccountDropDown = (searchTerm?: any) => {
    return useQuery(['Transaction Account', searchTerm], () => FetchTransactionAccountDropDown(searchTerm), {
        select: (data: any) => {
            const options = data.data.results.map((option: any) => ({
                value: option.id,
                label: option.name,
                status: option.status,
                deleted: option.is_deleted

            }))

            return options


        }
    })
}

