import React, { useEffect, useRef } from "react";
import Header from "../../components/Header/header";
import Sidebar from "../../components/Sidebar/sidebar";
import SearchLogo from "../../images/SearchLogo.png";
import ColorPickerLogo from "../../images/ColorPickerLogo.png";
import "../../components/Table/table.css";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useBrandPost } from "../../hooks/brand";
import { useState } from "react";
import { URL } from "node:url";
import Mui_Toaster from "../../components/Mui_Toaster/Mui_Toaster";
import { useCreate } from "../../hooks/Api";
import { Alert } from "../../components/Model/Model";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../components/focus";

const Add_Brand = ({checkToast}:any) => {
    const focus:any = useRef();
    const {onEnterKey} = useFocusOnEnter(focus);
    const navigate = useNavigate()
    const [logo, setlogo] = useState(null)
    const [logoPdf, setlogoPdf] = useState(null)
    const apiname = process.env.REACT_APP_BRAND_API?.toString()
    const { mutate: brandPost, data, isError, isLoading, isSuccess, error }:any = useCreate(apiname)

    const { register, handleSubmit, setError, formState, reset,setValue, getValues  }:any = useForm({
        mode:"onChange"
    });
    const { errors } = formState;
    useEffect(()=>{
        if(data){
            reset()
        }
    },[data])

    const onLogoChange = (e:any) => {
        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e:any) => {
        setlogoPdf(e.target.files[0].name)
    }

    const onSubmit = (branddetail: any) => {
        const formData = new FormData();

        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (key === "logo" || key === "logo_pdf"){
                if (typeof value === 'object' && value !== null && formvalue.length > 0) {
                    formData.append(key, formvalue[0])
                }
                else {
                    formData.append(key, "")
                }
            }

            

           
        }
        brandPost(formData);
       
    }
    

    


    const handleReset = () => {
        reset()
    }


    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listBrand', { state: { showToast: true }})
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])



    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    const handleUpper=(e:any)=>{
        e.target.value = e.target.value.toLowerCase()
    .split(' ')
    .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
    }


       

    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add Brand</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Product Details</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listbrand" className="text-decoration-none text-black">Brand</Link></li>
                                    <li className="breadcrumb-item active">Add Brand</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2 mt-2"><b>Add Brand</b></span></h5>
                            </div> */}
                            <form 
                             ref={focus}
                             onKeyUp={event => onEnterKey(event)}
                            onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>
                                {/* {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""} */}
                                {checkToast&&show && showmessage === "success" && 
                                
                                    <Mui_Toaster message={"Brand Added Successfully"} severity={"success"} Isopen={true}/>
                                }
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start"> 
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Brand Name <span style={{color:'red'}}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Add Brand Name"  {...register("name",{ onChange:handleUpper, required: { value: true, message: "Name is required", }, maxLength: 30, 
                                                        // pattern:/^[a-zA-Z]*$/
                                                    })} 
                                                        // onChange={handleUpper}
                                                        />
                                                         <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                        <span className='text-red w-100'>{errors?.name?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Our/Other Brand <span style={{color:'red'}}>*</span></label>
                                                    <select className="form-select" {...register("type"
                                                    , {
                                                        required: { value: true, message: "Type is required" }, maxLength: 30,

                                                    }
                                                    )}>
                                                    <option value=""  selected>Select Any </option>
                                                        <option value={"our"}>Our</option>
                                                        <option value={"other"}>Other</option>
                                                        <option value={"No Brand"}>No Brand</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.type?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{color:'red'}}>*</span></label>
                                                    <select className="form-select" {...register("status",
                                                     {
                                                        required: { value: true, message: "Status is required" }, maxLength: 30,

                                                    })}>
                                                    <option value=""  selected>Select Any </option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Upload Logo</label>
                                                    <label style={{cursor:"pointer"}}>
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file"  id="file_upload" accept="image/*" className="image-input" data-traget-resolution="image_resolution"  {...register("logo",{onChange:onLogoChange, required:false})} hidden />
                                                    </label>
                                                    <p>{logo}</p>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Logo PDF</label>
                                                    <label style={{cursor:"pointer"}}>
                                                    <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    <input data-required="image" type="file"  id="file_upload" accept="application/pdf" className="image-input" data-traget-resolution="image_resolution" {...register("logo_pdf",{onChange:onLogoPDFChange, required:false})} hidden />
                                                    </label>
                                                    <p>{logoPdf}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                  <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                  <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>
                                                <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listBrand")}>Cancel</button>

                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Add_Brand










// import React, { useEffect, useRef } from "react";
// import Header from "../../components/Header/header";
// import Sidebar from "../../components/Sidebar/sidebar";
// import SearchLogo from "../../images/SearchLogo.png";
// import ColorPickerLogo from "../../images/ColorPickerLogo.png";
// import "../../components/Table/table.css";
// import cameraLogo from "../../images/Export/Camera.png";
// import pdfLogo from "../../images/Export/pdf upload.png";
// import { Link, useNavigate } from "react-router-dom";
// import { useForm } from "react-hook-form";
// import { useBrandPost } from "../../hooks/brand";
// import { useState } from "react";
// import { URL } from "node:url";
// import Mui_Toaster from "../../components/Mui_Toaster/Mui_Toaster";
// import { useCreate } from "../../hooks/Api";
// import { Alert } from "../../components/Model/Model";
// import { RiPencilLine } from "react-icons/ri";
// import useFocusOnEnter from "../../components/focus";

// const Add_Brand = ({checkToast}:any) => {
//     const focus:any = useRef();
//     const {onEnterKey} = useFocusOnEnter(focus);
//     const navigate = useNavigate()
//     const [logo, setlogo]:any = useState([])
//     const [logoPdf, setlogoPdf]:any = useState([])
//     const apiname = process.env.REACT_APP_BRAND_API?.toString()
//     // const { mutate: brandPost, data, isError, isLoading, isSuccess, error } = useCreate(apiname)
//     const { mutate: brandPost, data, isError, isLoading, isSuccess, error }:any = useCreate(apiname)

//     const { register, handleSubmit, setError, formState, reset,setValue, getValues  }:any = useForm({
//         mode:"onChange"
//     });
//     const { errors } = formState;
//     useEffect(()=>{
//         if(data){
//             reset()
//         }
//     },[data])

//     const onLogoChange = (e:any) => {
//         setlogo(e.target.files)
    
//     }

//     const onLogoPDFChange = (e:any) => {
//         setlogoPdf(e.target.files.name)
//     }

 

//     const onSubmit = (branddetail: any) => {
//         const formData = new FormData();

//         for (const [key, value] of Object.entries(branddetail)) {
//             const formvalue: any = value
//             // if(key === "logo"){
          
//             // }
//             if (key === "logo"){
//             for (let i = 0; i < logo.length; i++) {
    
//                 formData.append('logo', logo[i])
//              }
//             }
//             if (typeof value !== "object") {
//                 formData.append(key, formvalue)
//             }
         

//         }
//         brandPost(formData);
       
//     }
    

  


//     const handleReset = () => {
//         reset()
//     }


//     const [show, setShow] = useState(false)
//     const [showmessage, setshowmessage] = useState("")

//     useEffect(() => {
//         if (isSuccess) {
//             setShow(true)
//             setshowmessage("success")
//             navigate('/listBrand', { state: { showToast: true }})
//         }
//         else if (isError) {
//             setShow(true)
//             setshowmessage("danger")
//         }
//     }, [data, error])



//     useEffect(() => {
//         setTimeout(() => {
//             setShow(false)
//         }, 3000)

//     }, [show]);


//     const handleUpper=(e:any)=>{
//         e.target.value = e.target.value.toLowerCase()
//         .split(' ')
//         .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
//         .join(' ');
//     }


       

//     return (
//         <div>
//             <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
//                 <section className="content-header py-1 px-3">
//                     <div className="container-fluid">
//                         <div className="row">
//                             <div className="col-sm-6">
//                                 <h4>Add Brand</h4>
//                             </div>
//                             <div className="col-sm-6">
//                                 <ol className="breadcrumb float-sm-right">
//                                     <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
//                                     <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Product Details</Link></li>
//                                     <li className="breadcrumb-item"><Link to="/listbrand" className="text-decoration-none text-black">Brand</Link></li>
//                                     <li className="breadcrumb-item active">Add Brand</li>
//                                 </ol>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//                 <section className="content">
//                     <div className="container-fluid">
//                         <div className="card card-default">
//                             {/* <div className="card-header">
//                                 <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2 mt-2"><b>Add Brand</b></span></h5>
//                             </div> */}
//                             <form 
//                              ref={focus}
//                              onKeyUp={event => onEnterKey(event)}
//                             onSubmit={handleSubmit(onSubmit)}>
//                                 <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>
//                                 {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
//                                 {checkToast&&show && showmessage === "success" && 
                                
//                                     <Mui_Toaster message={"Brand Added Successfully"} severity={"success"} Isopen={true}/>
//                                 }
//                                     <div className="row d-flex justify-content-md-center justify-content-lg-start">
//                                         <div className="col-md-12">
//                                             <div className="row">
//                                                 <div className="col-sm-3 mt-2">
//                                                     <label htmlFor="exampleInputEmail1">Brand Name <span style={{color:'red'}}>*</span></label>
//                                                     <div className="input-group" data-colorpicker-id={2}>
//                                                         <input type="text" className="form-control" id="exampleInputBrand" placeholder="Add Brand Name"  {...register("name",{ required: { value: true, message: "Name is required" }, maxLength: 30,})} 
//                                                         onChange={handleUpper}
//                                                         />
//                                                          <div className="input-group-append" >
//                                                             <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
//                                                         </div>
//                                                     </div>
//                                                         <span className='text-red w-100'>{errors?.name?.message}</span>
//                                                 </div>
//                                                 <div className="col-sm-3 mt-2">
//                                                     <label htmlFor="exampleInputEmail1">Our/Other Brand <span style={{color:'red'}}>*</span></label>
//                                                     <select className="form-select" {...register("type"
//                                                     , {
//                                                         required: { value: true, message: "Type is required" }, maxLength: 30,

//                                                     }
//                                                     )}>
//                                                     <option value=""  selected>Select Any </option>
//                                                         <option value={"our"}>Our</option>
//                                                         <option value={"other"}>Other</option>
//                                                     </select>
//                                                     <span className='text-red w-100'>{errors?.type?.message}</span>
//                                                 </div>
//                                                 <div className="col-sm-3 mt-2">
//                                                     <label htmlFor="exampleInputEmail1">Status <span style={{color:'red'}}>*</span></label>
//                                                     <select className="form-select" {...register("status",
//                                                      {
//                                                         required: { value: true, message: "Status is required" }, maxLength: 30,

//                                                     })}>
//                                                     <option value=""  selected>Select Any </option>
//                                                         <option value={"active"}>Active</option>
//                                                         <option value={"inactive"}>Inactive</option>
//                                                     </select>
//                                                     <span className='text-red w-100'>{errors?.status?.message}</span>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="row d-flex">
//                                         <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
//                                             <div className="row d-flex ">
//                                                 <div className="col-sm-3 mt-2 image-upload">
//                                                     <label htmlFor="exampleInputEmail1" className="col-12">Upload Logo</label>
//                                                     <label style={{cursor:"pointer"}}>
//                                                         <img src={cameraLogo} alt='' className="uploaded-image" />
//                                                         <input data-required="image" type="file"  id="file_upload" multiple accept="image/*" className="image-input" data-traget-resolution="image_resolution"  {...register("logo",{onChange:onLogoChange, required:false})} hidden />
//                                                     </label>
//                                                     {/* <p>{...logo}</p> */}
//                                                 </div>
//                                                 <div className="col-sm-3 mt-2 image-upload">
//                                                     <label htmlFor="exampleInputEmail1" className="col-12">Logo PDF</label>
//                                                     <label style={{cursor:"pointer"}}>
//                                                     <img src={pdfLogo} alt='' className="uploaded-image" />
//                                                     <input data-required="image" type="file"  id="file_upload" accept="application/pdf" className="image-input" data-traget-resolution="image_resolution" {...register("logo_pdf",{onChange:onLogoPDFChange, required:false})} hidden />
//                                                     </label>
//                                                     <p>{logoPdf}</p>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="row d-flex  ">
//                                         <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
//                                             <div className="row d-flex ">
//                                                   <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
//                                                   <button 
//                                                 type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
//                                                 style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
//                                                 onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>
//                                                 <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

//                                                 <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listBrand")}>Cancel</button>

//                                             </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </section>
//             </div>
//         </div>
//     )
// }

// export default Add_Brand