import React, { useEffect, useState, useRef } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import "../../components/Table/table.css"
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Select from 'react-select'
import axios from 'axios'
import { isConstructorDeclaration } from "typescript";
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../hooks/Api";
import { excel } from "react-export-table-to-excel/lib/lib";
import { RiPencilLine } from "react-icons/ri";
import { Alert } from "../../components/Model/Model";
import useFocusOnEnter from "../../components/focus";
import { Upload, UploadFile } from "antd";
import { Material_Density_Model } from "../../components/Dynamic_Form_Models/Material_Density_Model";
import { Color_Model } from "../../components/Dynamic_Form_Models/Color_Model";
import { useFetchBrandDropDown, useFetchBundleInCartonDropDown, useFetchBundleQtyDropDown, useFetchColorDropDown, useFetchGumNameDropDown, useFetchHSNDropDown, useFetchMaterialDensityDropDown, useFetchMaterialUseDropDown, useFetchMaterialandHSNDropDown, useFetchPanniMaterialUseDropDown, useFetchPanniProductByDropDown, useFetchPanniProductPrintingTypeDropDown, useFetchProductLocationDropDown, useFetchProductOurOtherDropDown, useFetchProductStarDropDown } from "../../hooks/DropDown_Api";
import { AiOutlinePlus } from "react-icons/ai";
import CreatableSelect from "react-select/creatable";
import Button from "react-bootstrap/esm/Button";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
import { Brand_Model } from "../../components/Dynamic_Form_Models/Brand_Model";
import { Deletesinglewithdependancy } from "../../components/modal";
import { HSN_Model } from "../../components/Dynamic_Form_Models/HSN_Model";
import Modal from "react-bootstrap/Modal";
import Actions, { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";

interface Option {
  readonly label: string;
  readonly name: string;
}
const createOption = (label: string) => ({
  label,
  name: label,
  status: "active",
});

const customIndicator = {
  IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
  IndicatorsContainer: IndicatorsContainerCreate
}

const Edit_TapeRoll = () => {


  // const {mutate:getrelated,data:relatedData} = useCreate(getRelatedHsn)






































  const [taperollNetKG, setTaperollNetKG]: any = useState()
  const [FilmnetKg, setFilmnetKg]: any = useState()


  const { id } = useParams();
  const apiname = process.env.REACT_APP_TAPEROLL_API?.toString();
  const { data } = useFetchSingle(id, apiname);
  const {
    mutate,
    isSuccess,
    isError,
    error,
    data: panniUpdated_response,
    isLoading,
  }: any = useUpdateSingle();


  const [confirmmodal, setConfirmmodal] = useState(false)
  const [valuesofform, setValuesofform] = useState<any>()

  const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
  const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()

  const [openEditModel, setOpenEditModel] = useState(false)
  const [expirationDate, setExpirationDate] = useState()


  const yesupdatemodal = () => {
    setConfirmmodal(false);
    mutateyes(valuesofform)
  }
  const cancelupdatemodal = () => {
    setConfirmmodal(false);
  }
  const noupdatemodal = () => {
    setOpenEditModel(true)
  }


  const handleExpirationDate = (e: any) => {
    setExpirationDate(e.target.value)
    setValue("expiration_date", e.target.value)
  }

  const handleConfirm = (data: any) => {

    delete data.created_at
    delete data.created_by
    let ok = {
      id: id,
      apiname: apiname,
      data: data,
    }

    mutateno(ok)
    setConfirmmodal(false);
    setOpenEditModel(false)
  }




  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    setValue,
    getValues,
  }: any = useForm();


  const navigate = useNavigate();
  const focus: any = useRef()
  const { onEnterKey } = useFocusOnEnter(focus)
  const [panniPrinting, setpanniPrinting] = useState("no");
  const getRelatedHsn = process.env.REACT_APP_GET_RELATED_HSN_API?.toString();
  const [open, setOpen] = useState(false)
  const [colorOpen, setColorOpen] = useState(false)
  const [printingColorOpen, setPrintingColorOpen] = useState(false)
  const [brandOpen, setBrandOpen] = useState(false)
  const [options, setOptions]: any = useState([]);
  const [optionsPrinting, setOptionsPrinting]: any = useState([]);
  const [optionsbrand, setOptionsBrand]: any = useState([]);
  const [optionsHSN, setOptionsHSN] = useState([]);
  const [ColorTotal, setColorTotal] = useState([]);
  const [optionsPanniName, setOptionsPanniName]:any = useState([]);
  const [TapeRollType, setTapeRollType]: any = useState({});
  const { data: colorData, refetch: colorRefetch } =
    useFetchColorDropDown();

  useEffect(() => {
    setOptions(colorData);
    setOptionsPrinting(colorData)
  }, [colorData]);





  const [colorNamePanni, setColorNamePanni]: any = useState({})

  const { data: brandData, refetch: brandRefetch } =
    useFetchBrandDropDown();

  useEffect(() => {
    setOptionsBrand(brandData);
  }, [brandData]);


  const { data: materialdensityData, refetch: materialdensityRefetch } =
    useFetchMaterialDensityDropDown();

  useEffect(() => {
    setOptionsPanniName(materialdensityData);
  }, [materialdensityData]);

  const [selPrintingColor, setSelPrintingColor] = useState([])
  const [selectedPrintingColor, setSelectedPrintingColor]: any = useState([])
  const handleChangeColorPrinting = (e: any) => {
    const printing_color_name = e.map((value: any) => {
      return value.value;
    });
    setValue("printing_color", printing_color_name);
    setColorTotal(e.length);
    setValue("printing_total_color", e.length);
    setSelPrintingColor(e)


  };
  const [selPrintingBrand, setSelPrintingBrand] = useState('')
  const [selectedPrintingBrand, setSelectedPrintingBrand] = useState('')
  const handleChangeBrand = (e: any) => {
    setSelPrintingBrand(e)
    setColorNamePanni(e)
    setTapeRollType(e.type);
    setValue("type", e.type);
    setValue("brand", `${e.value}`);
    setValue("brand_our_other", `${e.type}`);
    setForBrandValue({ label: e?.label, value: e?.value })

  };
  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  // useEffect(() => {
  //   let temp_results = relatedData?.results.map((e: any) => {
  //     return {
  //       value: e.id,
  //       label: e.hsn_code,
  //       gst_id: e.gst.id,
  //       gst: e.gst.india_GST,
  //     };
  //   });

  //   setOptionsHSN(temp_results);
  // }, [relatedData]);
  const handleReset = () => {
    reset();
    setForColorValue(null)
    setForColorsValue(null)
    setForValue(null)
    setForBrandValue(null)
    setForHSNValue(null)
    // setGSTNumber(null)
    // setlogoImages(null)
    // setTapeRollImg(null)
    // setPacketImg(null)
    // setBoxImg(null)
    setpanniPrinting("no")

  };
  const [show, setShow] = useState(false)
  const [showmessage, setshowmessage] = useState("")

  useEffect(() => {
    if (isSuccess || yessuccess || nosuccess) {
      setValuesofform(null)
      setShow(true)
      navigate('/listTaperoll', { state: { showEditToast: true } })
    }

  }, [data, isSuccess, yessuccess,
    nosuccess, panniUpdated_response])

  useEffect(() => {
    if (isError) {
      if (error.response.status === 405) {
        setConfirmmodal(true)
      }
    }
    else if (isError || yeserror || noerror) {
      setShow(true)
      setshowmessage("danger")
    }
  }, [isError, yeserror, noerror])
  useEffect(() => {
    setTimeout(() => {
      setShow(false)
    }, 3000)

  }, [show]);

  const onPrintingChange = (e: any) => {
    setpanniPrinting(e.target.value);
  };

  const ok = getValues("type");

  const handleValue = (e: any) => {
    e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  };

  const [forValue, setForValue]: any = useState([])
  const [submitted, setSubmitted] = useState(false)
  const [submittedColor, setSubmittedColor] = useState(false)
  const [submittedPrintingColor, setSubmittedPrintingColor] = useState(false)
  const [submittedBrand, setSubmittedBrand] = useState(false)

  const [forColorValue, setForColorValue]: any = useState([])
  const [forColorsValue, setForColorsValue]: any = useState([])
  const [forBrandValue, setForBrandValue]: any = useState([])
  const [forHSNValue, setForHSNValue]: any = useState([])


  useEffect(() => {
    if (submitted) {



      setSelHSN(optionsPanniName[0])
      setValue("material_name_and_type_and_hsn_code",optionsPanniName[0]?.name)



    }
  }, [submitted, optionsPanniName])

  useEffect(() => {


    if (submittedColor) {

      setSelColorName(options[0])
      setValue("product_color", options[0]?.value)


    }


  }, [submittedColor, options])
  useEffect(() => {


    if (submittedPrintingColor) {


      setSelPrintingColor(optionsPrinting[0])
      setValue("printing_color", optionsPrinting[0]?.value)

    }


  }, [submittedPrintingColor, optionsPrinting])

  useEffect(() => {

    if (submittedBrand) {

      setValue("brand", optionsbrand[0]?.value)
      setSelPrintingBrand(optionsbrand[0])
    }

  }, [submittedBrand, optionsbrand])


  // const [forHSNValue, setForHSNValue]: any = useState([])
  const [DensityValue, setDensityValue]: any = useState()
  const { data: HSNData, refetch: HSNRefetch } = useFetchHSNDropDown()

  useEffect(() => {
    setOptionsHSN(HSNData)
  }, [HSNData])

  const [openHSN, setOpenHSN] = useState(false)


  const [selHSN, setSelHSN]:any = useState('')
  const [selectedHSN, setSelectedHSN] = useState('')

  const handleChangeHSN = (e: any) => {
    setValue("material_name_and_type_and_hsn_code", `${e.value}`)
    setDensityValue(e.material)
    setValue("polymer_grade", e.polymer)
    setForHSNValue({ label: e?.label, value: e?.value })
    setSelHSN(e)
  }

  const apinameForMaterialType = process.env.REACT_APP_PANNI_MATERIAL_USE_API?.toString()
  const { mutate: mutateMaterialType, data: dataMaterialType, isError: MaterialTypeIsError, isLoading: MaterialTypeLoading, isSuccess: MaterialTypeSuccess, error: MaterialTypeError }: any = useCreate(apinameForMaterialType)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valueMaterialType, setValueMaterialType]: any = useState();
  const [optionsMaterialType, setOptionsMaterialType]: any = useState([]);
  const [valueMaterialTypeCreatable, setValueMaterialTypeCreatable] = useState<Option | null>();

  const { data: MaterialTypeData, refetch: MaterialTypeRefetch } = useFetchPanniMaterialUseDropDown()

  useEffect(() => {
    setOptionsMaterialType(MaterialTypeData)
  }, [MaterialTypeData])




  const [selectedMaterialType, setSelectedMaterialType] = useState()
  const [selectedBundleQty, setSelectedBundleQty] = useState()
  const [selectedBundleInCarton, setSelectedBundleInCarton] = useState()
  const [selectedPrintingType, setSelectedPrintingType] = useState()
  const [selectedGumName, setSelectedGumName] = useState()
  const [selectedProductBy, setSelectedProductBy] = useState()
  const [selectedOurOther, setSelectedOurOther] = useState()
  const [selectedTapeRollType, setSelectedTapeRollType] = useState()
  const [selectedProductStar, setSelectedProductStar] = useState()
  const [selectedProductLocation, setSelectedProductLocation] = useState()
  const handleChangeMaterialType = (e: any) => {

    MaterialTypeRefetch()

    setApiName(apinameForMaterialType)
    setDeleteApiName(apinameForMaterialType)
    setEditId(e?.value)
    setEditData(MaterialTypeData)
    setRenderKey(prevKey => prevKey + 1);

    setValueMaterialType(e)
    setValue("material_type", `${e?.value}`)
    setValueMaterialTypeCreatable(null)
  }





  const handleCreateMaterialType = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    // setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      // setIsLoadingValue(false);


      mutateMaterialType({ name: newOption.name, status: newOption.status })
      setOptionsMaterialType((prev: any) => [...prev, newOption]);
      setValueMaterialTypeCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (MaterialTypeSuccess && optionsMaterialType) {
        await MaterialTypeRefetch();
        setValue("material_type", MaterialTypeData[0]?.value)
      }
      
    })();

    return () => {
    };
  }, [MaterialTypeSuccess, MaterialTypeData]);





  const [selColorName, setSelColorName] = useState('')
  const [selectedColorName, setSelectedColorName] = useState('')

  const handleChangeColor = (e: any) => {
    setForColorValue(e);
    setValue("product_color", e.value);
    setSelColorName(e)
  };







  const apinameForBundleQty = process.env.REACT_APP_BUNDLE_QTY_API?.toString()
  const { mutate: mutateBundleQty, data: dataBundleQty, isError: BundleQtyIsError, isLoading: BundleQtyLoading, isSuccess: BundleQtySuccess, error: BundleQtyError }: any = useCreate(apinameForBundleQty)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valueBundleQty, setValueBundleQty]: any = useState();
  const [optionsBundleQty, setOptionsBundleQty]: any = useState([]);
  const [valueBundleQtyCreatable, setValueBundleQtyCreatable] = useState<Option | null>();

  const { data: BundleQtyData, refetch: BundleQtyRefetch } = useFetchBundleQtyDropDown()

  useEffect(() => {
    setOptionsBundleQty(BundleQtyData)
  }, [BundleQtyData])





  const handleChangeBundleQty = (e: any) => {

    BundleQtyRefetch()

    setApiName(apinameForBundleQty)
    setDeleteApiName(apinameForBundleQty)
    setEditId(e?.value)
    setEditData(BundleQtyData)
    setRenderKey(prevKey => prevKey + 1);


    setValueBundleQty(e)
    setValue("bundle_qty", `${e?.value}`)
    setValueBundleQtyCreatable(null)
  }





  const handleCreateBundleQty = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    // setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      // setIsLoadingValue(false);


      mutateBundleQty({ name: newOption.name, status: newOption.status })
      setOptionsBundleQty((prev: any) => [...prev, newOption]);
      setValueBundleQtyCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (BundleQtySuccess && optionsBundleQty) {
        await BundleQtyRefetch();
        setValue("bundle_qty", BundleQtyData[0]?.value)
      }
     
    })();

    return () => {
    };
  }, [BundleQtySuccess, BundleQtyData]);






  const apinameForBundleInCarton = process.env.REACT_APP_BUNDLE_IN_CARTON_API?.toString()
  const { mutate: mutateBundleInCarton, data: dataBundleInCarton, isError: BundleInCartonIsError, isLoading: BundleInCartonLoading, isSuccess: BundleInCartonSuccess, error: BundleInCartonError }: any = useCreate(apinameForBundleInCarton)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valueBundleInCarton, setValueBundleInCarton]: any = useState();
  const [optionsBundleInCarton, setOptionsBundleInCarton]: any = useState([]);
  const [valueBundleInCartonCreatable, setValueBundleInCartonCreatable] = useState<Option | null>();

  const { data: BundleInCartonData, refetch: BundleInCartonRefetch } = useFetchBundleInCartonDropDown()

  useEffect(() => {
    setOptionsBundleInCarton(BundleInCartonData)
  }, [BundleInCartonData])





  const handleChangeBundleInCarton = (e: any) => {

    BundleInCartonRefetch()

    setApiName(apinameForBundleInCarton)
    setDeleteApiName(apinameForBundleInCarton)
    setEditId(e?.value)
    setEditData(BundleInCartonData)
    setValueBundleInCarton(e)
    setRenderKey(prevKey => prevKey + 1);
    
    setValue("bundle_in_carton", `${e?.value}`)
    setValueBundleInCartonCreatable(null)
  }





  const handleCreateBundleInCarton = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    // setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      // setIsLoadingValue(false);


      mutateBundleInCarton({ name: newOption.name, status: newOption.status })
      setOptionsBundleInCarton((prev: any) => [...prev, newOption]);
      setValueBundleInCartonCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (BundleInCartonSuccess && optionsBundleInCarton) {
        await BundleInCartonRefetch();
        setValue("bundle_in_carton", BundleInCartonData[0]?.value)
      }
     
    })();

    return () => {
    };
  }, [BundleInCartonSuccess, BundleInCartonData]);





  const apinameForPrintingType = process.env.REACT_APP_PANNI_PRINTING_TYPE_API?.toString()
  const { mutate: mutatePrintingType, data: dataPrintingType, isError: PrintingTypeIsError, isLoading: PrintingTypeLoading, isSuccess: PrintingTypeSuccess, error: PrintingTypeError }: any = useCreate(apinameForPrintingType)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valuePrintingType, setValuePrintingType]: any = useState();
  const [optionsPrintingType, setOptionsPrintingType]: any = useState([]);
  const [valuePrintingTypeCreatable, setValuePrintingTypeCreatable] = useState<Option | null>();

  const { data: PrintingTypeData, refetch: PrintingTypeRefetch } = useFetchPanniProductPrintingTypeDropDown()

  useEffect(() => {
    setOptionsPrintingType(PrintingTypeData)
  }, [PrintingTypeData])





  const handleChangePrintingType = (e: any) => {

    PrintingTypeRefetch()

    setApiName(apinameForPrintingType)
    setDeleteApiName(apinameForPrintingType)
    setEditId(e?.value)
    setEditData(PrintingTypeData)
    setValuePrintingType(e)
    setRenderKey(prevKey => prevKey + 1);


    setValue("printing_type", `${e?.value}`)
    setValuePrintingTypeCreatable(null)
  }





  const handleCreatePrintingType = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    // setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      // setIsLoadingValue(false);


      mutatePrintingType({ name: newOption.name, status: newOption.status })
      setOptionsPrintingType((prev: any) => [...prev, newOption]);
      setValuePrintingTypeCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (PrintingTypeSuccess && optionsPrintingType) {
        await PrintingTypeRefetch();
        setValue("printing_type", PrintingTypeData[0]?.value)
      }
      
    })();

    return () => {
    };
  }, [PrintingTypeSuccess, PrintingTypeData]);



  const apinameForProductBy = process.env.REACT_APP_PANNI_PRODUCT_BY_API?.toString()
  const { mutate: mutateProductBy, data: dataProductBy, isError: ProductByIsError, isLoading: ProductByLoading, isSuccess: ProductBySuccess, error: ProductByError }: any = useCreate(apinameForProductBy)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valueProductBy, setValueProductBy]: any = useState();
  const [optionsProductBy, setOptionsProductBy]: any = useState([]);
  const [valueProductByCreatable, setValueProductByCreatable] = useState<Option | null>();

  const { data: ProductByData, refetch: ProductByRefetch } = useFetchPanniProductByDropDown()

  useEffect(() => {
    setOptionsProductBy(ProductByData)
  }, [ProductByData])





  const handleChangeProductBy = (e: any) => {

    ProductByRefetch()

    setApiName(apinameForProductBy)
    setDeleteApiName(apinameForProductBy)
    setEditId(e?.value)
    setEditData(ProductByData)
    setRenderKey(prevKey => prevKey + 1);

    setValueProductBy(e)
    setValue("product_by", `${e?.value}`)
    setValueProductByCreatable(null)
  }





  const handleCreateProductBy = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    // setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      // setIsLoadingValue(false);


      mutateProductBy({ name: newOption.name, status: newOption.status })
      setOptionsProductBy((prev: any) => [...prev, newOption]);
      setValueProductByCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (ProductBySuccess && optionsProductBy) {
        await ProductByRefetch();
        setValue("product_by", ProductByData[0]?.value)
      }
      
    })();

    return () => {
    };
  }, [ProductBySuccess, ProductByData]);


  const apinameForProductOurOther = process.env.REACT_APP_PRODUCT_OUR_OTHER_API?.toString()
  const { mutate: mutateProductOurOther, data: dataProductOurOther, isError: ProductOurOtherIsError, isLoading: ProductOurOtherLoading, isSuccess: ProductOurOtherSuccess, error: ProductOurOtherError }: any = useCreate(apinameForProductOurOther)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valueProductOurOther, setValueProductOurOther]: any = useState();
  const [optionsProductOurOther, setOptionsProductOurOther]: any = useState([]);
  const [valueProductOurOtherCreatable, setValueProductOurOtherCreatable] = useState<Option | null>();

  const { data: ProductOurOtherData, refetch: ProductOurOtherRefetch } = useFetchProductOurOtherDropDown()

  useEffect(() => {
    setOptionsProductOurOther(ProductOurOtherData)
  }, [ProductOurOtherData])





  const handleChangeProductOurOther = (e: any) => {

    ProductOurOtherRefetch()

    setApiName(apinameForProductOurOther)
    setDeleteApiName(apinameForProductOurOther)
    setEditId(e?.value)
    setEditData(ProductOurOtherData)
    setValueProductOurOther(e)
    setRenderKey(prevKey => prevKey + 1);

    setValueProductOurOther(e)
    setValue("product_our_other", `${e?.value}`)
    setValueProductOurOtherCreatable(null)
  }





  const handleCreateProductOurOther = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    // setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      // setIsLoadingValue(false);


      mutateProductOurOther({ name: newOption.name, status: newOption.status })
      setOptionsProductOurOther((prev: any) => [...prev, newOption]);
      setValueProductOurOtherCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (ProductOurOtherSuccess && optionsProductOurOther) {
        await ProductOurOtherRefetch();
        setValue("product_our_other", ProductOurOtherData[0]?.value)
      }
     
    })();

    return () => {
    };
  }, [ProductOurOtherSuccess, ProductOurOtherData]);


  const apinameForTapeRollType = process.env.REACT_APP_MATERIAL_USE_API?.toString()
  const { mutate: mutateTapeRollType, data: dataTapeRollType, isError: TapeRollTypeIsError, isLoading: TapeRollTypeLoading, isSuccess: TapeRollTypeSuccess, error: TapeRollTypeError }: any = useCreate(apinameForTapeRollType)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valueTapeRollType, setValueTapeRollType]: any = useState();
  const [optionsTapeRollType, setOptionsTapeRollType]: any = useState([]);
  const [valueTapeRollTypeCreatable, setValueTapeRollTypeCreatable] = useState<Option | null>();

  const { data: TapeRollTypeData, refetch: TapeRollTypeRefetch } = useFetchMaterialUseDropDown()

  useEffect(() => {
    setOptionsTapeRollType(TapeRollTypeData)
  }, [TapeRollTypeData])





  const handleChangeTapeRollType = (e: any) => {

    
    TapeRollTypeRefetch()

    setApiName(apinameForTapeRollType)
    setDeleteApiName(apinameForTapeRollType)
    setEditId(e?.value)
    setEditData(TapeRollTypeData)
    setValueTapeRollType(e)
    setRenderKey(prevKey => prevKey + 1);

    setValue("taperoll_type", `${e?.value}`)
    setValueTapeRollTypeCreatable(null)
  }





  const handleCreateTapeRollType = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    // setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      // setIsLoadingValue(false);


      mutateTapeRollType({ name: newOption.name, status: newOption.status })
      setOptionsTapeRollType((prev: any) => [...prev, newOption]);
      setValueTapeRollTypeCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (TapeRollTypeSuccess && optionsTapeRollType) {
        await TapeRollTypeRefetch();
        setValue("taperoll_type", TapeRollTypeData[0]?.value)
      }
      
    })();

    return () => {
    };
  }, [TapeRollTypeSuccess, TapeRollTypeData]);


  const apinameForProductStar = process.env.REACT_APP_PRODUCT_STAR_API?.toString()
  const { mutate: mutateProductStar, data: dataProductStar, isError: ProductStarIsError, isLoading: ProductStarLoading, isSuccess: ProductStarSuccess, error: ProductStarError }: any = useCreate(apinameForProductStar)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valueProductStar, setValueProductStar]: any = useState();
  const [optionsProductStar, setOptionsProductStar]: any = useState([]);
  const [valueProductStarCreatable, setValueProductStarCreatable] = useState<Option | null>();

  const { data: ProductStarData, refetch: ProductStarRefetch } = useFetchProductStarDropDown()

  useEffect(() => {
    setOptionsProductStar(ProductStarData)
  }, [ProductStarData])





  const handleChangeProductStar = (e: any) => {

    ProductStarRefetch()

    setApiName(apinameForProductStar)
    setDeleteApiName(apinameForProductStar)
    setEditId(e?.value)
    setEditData(ProductStarData)
    setValueProductStar(e)
    setRenderKey(prevKey => prevKey + 1);

    setValue("product_star", `${e?.value}`)
    setValueProductStarCreatable(null)
  }





  const handleCreateProductStar = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    // setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      // setIsLoadingValue(false);


      mutateProductStar({ name: newOption.name, status: newOption.status })
      setOptionsProductStar((prev: any) => [...prev, newOption]);
      setValueProductStarCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (ProductStarSuccess && optionsProductStar) {
        await ProductStarRefetch();
        setValue("product_star", ProductStarData[0]?.value)
      }
 
    })();

    return () => {
    };
  }, [ProductStarSuccess, ProductStarData]);




  const apinameForProductLocation = process.env.REACT_APP_PRODUCT_LOCATION_API?.toString()
  const { mutate: mutateProductLocation, data: dataProductLocation, isError: ProductLocationIsError, isLoading: ProductLocationLoading, isSuccess: ProductLocationSuccess, error: ProductLocationError }: any = useCreate(apinameForProductLocation)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valueProductLocation, setValueProductLocation]: any = useState();
  const [optionsProductLocation, setOptionsProductLocation]: any = useState([]);
  const [valueProductLocationCreatable, setValueProductLocationCreatable] = useState<Option | null>();

  const { data: ProductLocationData, refetch: ProductLocationRefetch } = useFetchProductLocationDropDown()

  useEffect(() => {
    setOptionsProductLocation(ProductLocationData)
  }, [ProductLocationData])





  const handleChangeProductLocation = (e: any) => {

    ProductLocationRefetch()

    setApiName(apinameForProductLocation)
    setDeleteApiName(apinameForProductLocation)
    setEditId(e?.value)
    setEditData(ProductLocationData)
    setValueProductLocation(e)
    setRenderKey(prevKey => prevKey + 1);


    setValue("product_location", `${e?.value}`)
    setValueProductLocationCreatable(null)
  }





  const handleCreateProductLocation = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    // setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      // setIsLoadingValue(false);


      mutateProductLocation({ name: newOption.name, status: newOption.status })
      setOptionsProductLocation((prev: any) => [...prev, newOption]);
      setValueProductLocationCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (ProductLocationSuccess && optionsProductLocation) {
        await ProductLocationRefetch();
        setValue("product_location", ProductLocationData[0]?.value)
      }
 
    })();

    return () => {
    };
  }, [ProductLocationSuccess, ProductLocationData]);




  const apinameForGumName = process.env.REACT_APP_GUM_NAME_API?.toString()
  const { mutate: mutateGumName, data: dataGumName, isError: GumNameIsError, isLoading: GumNameLoading, isSuccess: GumNameSuccess, error: GumNameError }: any = useCreate(apinameForGumName)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valueGumName, setValueGumName]: any = useState();
  const [optionsGumName, setOptionsGumName]: any = useState([]);
  const [valueGumNameCreatable, setValueGumNameCreatable] = useState<Option | null>();

  const { data: GumNameData, refetch: GumNameRefetch } = useFetchGumNameDropDown()

  useEffect(() => {
    setOptionsGumName(GumNameData)
  }, [GumNameData])





  const handleChangeGumName = (e: any) => {

    GumNameRefetch()

    setApiName(apinameForGumName)
    setDeleteApiName(apinameForGumName)
    setEditId(e?.value)
    setEditData(GumNameData)
    setRenderKey(prevKey => prevKey + 1);

    setValueGumName(e)
    setValue("gumname", `${e?.value}`)
    setValueGumNameCreatable(null)
  }





  const handleCreateGumName = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    // setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      // setIsLoadingValue(false);


      mutateGumName({ name: newOption.name, status: newOption.status })
      setOptionsGumName((prev: any) => [...prev, newOption]);
      setValueGumNameCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (GumNameSuccess && optionsGumName) {
        await GumNameRefetch();
        setValue("gumname", GumNameData[0]?.value)
      }
     
    })();

    return () => {
    };
  }, [GumNameSuccess, GumNameData]);


  const [logoId, setLogoId] = useState([])
  const [logoImagesId, setLogoImagesId] = useState([])
  const [ProductImageId, setProductImageId] = useState([])
  const [ProductDrawingId, setProductDrawingId] = useState([])

  const [cartonPackingImage, setCartonPackingImage] = useState([])
  const [uploadCartonPackingImage, setUploadCartonPackingImage] = useState<UploadFile[]>([])

  const [productPacketPacking, setProductPacketPacking] = useState([])
  const [uploadProductPacketPacking, setUploadProductPacketPacking] = useState<UploadFile[]>([])

  const [productImage, setProductImage]: any = useState([])
  const [uploadProductImage, setUploadProductImage] = useState<UploadFile[]>([])

  const [productPDF, setProductPDF] = useState([])
  const [uploadProductPDF, setUploadProductPDF] = useState<UploadFile[]>([])

  const cartonPackingChange = (e: any) => {
    e.file.status = "done"

    // const tempFiles = e.fileList.map((file: any) => {
    //     return file.originFileObj
    // })
    // setlogo(tempFiles)
    // setUploadFile(e.fileList)





    e.file.status = "done"

    let tempOldFiles: any = []


    e.fileList.map((e: any) => {
      if (!("originFileObj" in e)) {
        let ctx = {
          id: parseInt(e.uid),
          doc: e.url
        }
        tempOldFiles.push(ctx)

      }
    })

    const tempFiles = e.fileList.map((file: any) => {
      return file.originFileObj
    }).filter((e: any) => e != undefined)

    setLogoId(tempOldFiles)
    setCartonPackingImage(tempFiles)
    setUploadCartonPackingImage(e.fileList)

  }


  const ProductPacketPackingOnChange = (e: any) => {
    // e.file.status = "done"

    // const tempFiles = e.fileList.map((file: any) => {
    //     return file.originFileObj
    // })
    // setProductImage(tempFiles)
    // setUploadProductImage(e.fileList)




    e.file.status = "done"

    let tempOldFiles: any = []


    e.fileList.map((e: any) => {
      if (!("originFileObj" in e)) {
        let ctx = {
          id: parseInt(e.uid),
          doc: e.url
        }
        tempOldFiles.push(ctx)

      }
    })

    const tempFiles = e.fileList.map((file: any) => {
      return file.originFileObj
    }).filter((e: any) => e != undefined)

    setProductImageId(tempOldFiles)
    setProductPacketPacking(tempFiles)
    setUploadProductPacketPacking(e.fileList)

  }

  const ProductImageOnChange = (e: any) => {
    // e.file.status = "done"

    // const tempFiles = e.fileList.map((file: any) => {
    //     return file.originFileObj
    // })
    // setProductDrawing(tempFiles)
    // setUploadProductDrawing(e.fileList)




    e.file.status = "done"

    let tempOldFiles: any = []


    e.fileList.map((e: any) => {
      if (!("originFileObj" in e)) {
        let ctx = {
          id: parseInt(e.uid),
          doc: e.url
        }
        tempOldFiles.push(ctx)

      }
    })

    const tempFiles = e.fileList.map((file: any) => {
      return file.originFileObj
    }).filter((e: any) => e != undefined)

    setProductDrawingId(tempOldFiles)
    setProductImage(tempFiles)
    setUploadProductImage(e.fileList)

  }
  const ProductPDFOnChange = (e: any) => {
    // e.file.status = "done"

    // const tempFiles = e.fileList.map((file: any) => {
    //     return file.originFileObj
    // })
    // setlogoImages(tempFiles)
    // setUploadFileDoc(e.fileList)



    e.file.status = "done"

    let tempOldFiles: any = []


    e.fileList.map((e: any) => {
      if (!("originFileObj" in e)) {
        let ctx = {
          id: parseInt(e.uid),
          doc: e.url
        }
        tempOldFiles.push(ctx)

      }
    })

    const tempFiles = e.fileList.map((file: any) => {
      return file.originFileObj
    }).filter((e: any) => e != undefined)

    setLogoImagesId(tempOldFiles)
    setProductPDF(tempFiles)
    setUploadProductPDF(e.fileList)

  }



  const handleThicknessFilmMicron = (e: any) => {
    setValue('thickness_gauge', e.target.value * 4)
  }

  const handleGumLerMicron = (e: any) => {
    // setValue('tape_roll_1_meter_film_in_gum_net_gram', e.target.value)
    // setValue('one_tape_roll_film_net_weight_gram', e.target.value)
    // setValue('one_tape_roll_gum_ler_weight_gram', e.target.value)
    // setValue('one_tape_roll_film_pluse_gum_weight_gram', e.target.value)
  }




  useEffect(() => {
    if (data) {
      reset(data)
      // setSelectedPanni(data?.name_and_grade?.id)
      // setSelectedColor(data?.color?.id)
      // setSelectedBrand(data?.brand?.id)
      // setSelectedHSN(data?.hsn_code?.id)
      // setValue("color", data?.color?.id )
      setValue("printing_total_color", data?.printing_total_color)

      setValue("name_and_grade", data?.name_and_grade?.id)
      setValue("hsn_code", data?.hsn_code?.id)
      setValue("brand", data?.brand?.id)
      setValue("gst", data?.gst?.id)
      // getrelated({"grade": data?.name_and_grade?.id})
      // setGSTNumber(data?.gst)
      // setPanniType(data?.type)
      setpanniPrinting(data?.printing)
      setColorTotal(data?.printing_total_color)
      // setSelectedPrintingColor(data?.printing_color?.map((e:any) => e?.id ))
      setValue("printing_color", data?.printing_color?.map((e: any) => e?.id))



      // setSelectedPrintingBrand()

      setValue('material_name_and_type_and_hsn_code', data?.material_name_and_type_and_hsn_code?.id)
      setSelectedHSN(data?.material_name_and_type_and_hsn_code?.id);
      setValue("product_color", data?.product_color?.id)
      setSelectedColorName(data?.product_color?.id)
      setValue('printing_color', data?.printing_color?.map((e: any) => e?.id))
      setSelectedPrintingColor(data?.printing_color?.map((e: any) => e?.id))
      setValue('brand', data?.brand?.id)
      setSelectedPrintingBrand(data?.brand?.id)




      setValue('material_type', data?.material_type?.id)
      setSelectedMaterialType(data?.material_type?.id)

      setValue('bundle_qty', data?.bundle_qty?.id)
      setSelectedBundleQty(data?.bundle_qty?.id)

      setValue('bundle_in_carton', data?.bundle_in_carton?.id)
      setSelectedBundleInCarton(data?.bundle_in_carton?.id)

      setValue('printing_type', data?.printing_type?.id)
      setSelectedPrintingType(data?.printing_type?.id)

      setValue('gumname', data?.gumname?.id)
      setSelectedGumName(data?.gumname?.id)

      setValue('product_by', data?.product_by?.id)
      setSelectedProductBy(data?.product_by?.id)

      setValue('product_our_other', data?.product_our_other?.id)
      setSelectedOurOther(data?.product_our_other?.id)

      setValue('taperoll_type', data?.taperoll_type?.id)
      setSelectedTapeRollType(data?.taperoll_type?.id)

      setValue('product_star', data?.product_star?.id)
      setSelectedProductStar(data?.product_star?.id)

      setValue('product_location', data?.product_location?.id)
      setSelectedProductLocation(data?.product_location?.id)












      let tempCartonPackingImage = data?.carton_packing_image?.map((img: any, index: number) => {
        let ctx: UploadFile = {
          uid: `${img?.id}`,
          name: `${img.title}`,
          status: 'done',
          url: img?.doc,
          thumbUrl: img?.doc,
        }

        return ctx
      })

      let tempProductPacketPackingImage = data?.product_packet_packing_image?.map((img: any, index: number) => {
        let ctx: UploadFile = {
          uid: `${img?.id}`,
          name: `${img.title}`,
          status: 'done',
          url: img?.doc,
          thumbUrl: img?.doc,
        }

        return ctx
      })

      let tempProductImage = data?.product_image?.map((img: any, index: number) => {
        let ctx: UploadFile = {
          uid: `${img?.id}`,
          name: `${img.title}`,
          status: 'done',
          url: img?.doc,
          thumbUrl: img?.doc,
        }

        return ctx
      })

      let tempProductPdfDrawing = data?.product_pdf_drawing?.map((pdf: any, index: number) => {
        let ctx: UploadFile = {
          uid: `${pdf?.id}`,
          name: `${pdf.title}`,
          status: 'done',
          url: pdf?.doc,
          thumbUrl: pdf?.doc,
        }

        return ctx
      })




      setUploadCartonPackingImage(tempCartonPackingImage)
      setUploadProductPacketPacking(tempProductPacketPackingImage)
      setUploadProductImage(tempProductImage)
      setUploadProductPDF(tempProductPdfDrawing)

    }
  }, [data])


  const onSubmit = (detail: any) => {
    const formData:any = new FormData();
    if (!cartonPackingImage) {
      delete detail.logo
    }
    if (!productPacketPacking) {
      delete detail.logoPdf
    }
    if (!productImage) {
      delete detail.productImage
    }
    if (!productPDF) {
      delete detail.productDrawing
    }

    if (panniPrinting === "no") {
      delete detail.printing_type;
      delete detail.printing_total_color;
      delete detail.printing_color ;
      delete detail.brand;
      delete detail.type ;
      delete detail.brand_our_other;

      
    }

    for (const [key, value] of Object.entries(detail)) {
      const formvalue: any = value;


      if (key === "carton_packing_image") {
        logoId.map((old: any) => {
          formData.append('carton_packing_images', old?.id)
        })
        for (let i = 0; i < cartonPackingImage.length; i++) {

          formData.append('carton_packing_image', cartonPackingImage[i])
        }
      }
      if (key === "product_packet_packing_image") {
        ProductImageId.map((old: any) => {
          formData.append('product_packet_packing_images', old?.id)
        })
        for (let i = 0; i < productPacketPacking.length; i++) {

          formData.append('product_packet_packing_image', productPacketPacking[i])
        }
      }

      if (taperollNetKG && key === 'tape_roll_net_kg_in_carton') {
        let decimalNumber = Number(taperollNetKG);
        const formattedString = decimalNumber.toFixed(3);
        formData.append('tape_roll_net_kg_in_carton', formattedString)
        continue
    }
      if (FilmnetKg && key === 'film_net_1kg_in_meter') {
        let decimalNumber = Number(FilmnetKg);
        const formattedString = decimalNumber.toFixed(3);
        formData.append('film_net_1kg_in_meter', formattedString)
        continue
    }

      if (key === "product_image") {
        ProductDrawingId.map((old: any) => {
          formData.append('product_images', old?.id)
        })
        for (let i = 0; i < productImage.length; i++) {

          formData.append('product_image', productImage[i])
        }
      }
      if (key === "product_pdf_drawing") {
        logoImagesId.map((old: any) => {
          formData.append('product_pdfs_drawing', old?.id)
        })
        for (let i = 0; i < productPDF.length; i++) {

          formData.append('product_pdf_drawing', productPDF[i])
        }
      }















      if (typeof value !== "object") {
        if (value != undefined) {
          formData.append(key, formvalue)
        }
      }
      else if (Array.isArray(value) && key === "printing_color") {
        value.forEach((val, index) => formData.append(`printing_color`, val));
      }
      else if (key === "box_image" || key === "packet_image" || key === "tape_roll_image" || key === "tape_roll_drawing") {
        if (typeof value === 'object' && value !== null && formvalue.length > 0) {
          formData.append(key, formvalue[0])
        }
        else {
          formData.append(key, "")
        }
      }
    }
    let data = {
      apiname: apiname,
      id: id,
      data: formData
    }

    mutate(data);
  }




  const [renderKey, setRenderKey] = useState(0);
  const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
  const [deleteApiName, setDeleteApiName]: any = useState("")
  const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
  var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
  const [editData, setEditData]: any = useState()
  const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
      mutate: singleDelete,
      data: deletedata,
      isError: deletecoloriserror,
      isSuccess: deletesucess,
    } = useDelete();
    const {
      mutate: deleteMultipleMutet,
      data: deleteMultiple,
      isError: deleteMultipleIsError,
      isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
      mutate: deleteMultipleMutetforce,
      isSuccess: forcefullysuccess,
      isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
      mutate: deleteforce,
      isSuccess: forcefullysingledeletesuccess,
      isError: forcefullydeleteerror,
    } = useDeletemforce();
  



  const handleRefetch = () => {
      if (apiName === apinameForMaterialType) {
        MaterialTypeRefetch()
      }

      if (apiName === apinameForGumName) {
        GumNameRefetch()
      }

      if (apiName === apinameForBundleQty) {
        BundleQtyRefetch()
      }

      if (apiName === apinameForBundleInCarton) {
        BundleInCartonRefetch()
      }

      if (apiName === apinameForPrintingType) {
        PrintingTypeRefetch()
      }

      if (apiName === apinameForProductBy) {
        ProductByRefetch()
      }

      if (apiName === apinameForProductOurOther) {
        ProductOurOtherRefetch()
      }

      if (apiName === apinameForTapeRollType) {
        TapeRollTypeRefetch()
      }

      if (apiName === apinameForProductStar) {
        ProductStarRefetch()
      }

      if (apiName === apinameForProductLocation) {
        ProductLocationRefetch()
      }
       
       
    // else if(apiName === apinameForTaluka){
    //   TalukaRefetch()
    // }
    }


    useEffect(() => {
      if (OpenEditDropDownModel && valueMaterialType && apiName === apinameForMaterialType) {
        setValueMaterialType(null)
        setValueMaterialTypeCreatable(null)
      }

      if (OpenEditDropDownModel && valueGumName && apiName === apinameForGumName) {
        setValueGumName(null)
        setValueGumNameCreatable(null)
      }

      
      if (OpenEditDropDownModel && valueBundleQty && apiName === apinameForBundleQty) {
        setValueBundleQty(null)
        setValueBundleQtyCreatable(null)
      }

      if (OpenEditDropDownModel && valueBundleInCarton && apiName === apinameForBundleInCarton) {
        setValueBundleInCarton(null)
        setValueBundleInCartonCreatable(null)
      }


      if (OpenEditDropDownModel && valuePrintingType && apiName === apinameForPrintingType) {
        setValuePrintingType(null)
        setValuePrintingTypeCreatable(null)
      }


      if (OpenEditDropDownModel && valueProductBy && apiName === apinameForProductBy) {
        setValueProductBy(null)
        setValueProductByCreatable(null)
      }

      if (OpenEditDropDownModel && valueProductOurOther && apiName === apinameForProductOurOther) {
        setValueProductOurOther(null)
        setValueProductOurOtherCreatable(null)
      }

      if (OpenEditDropDownModel && valueTapeRollType && apiName === apinameForTapeRollType) {
        setValueTapeRollType(null)
        setValueTapeRollTypeCreatable(null)
      }

      if (OpenEditDropDownModel && valueProductStar && apiName === apinameForProductStar) {
        setValueProductStar(null)
        setValueProductStarCreatable(null)
      }

      if (OpenEditDropDownModel && valueProductLocation && apiName === apinameForProductLocation) {
        setValueProductLocation(null)
        setValueProductLocationCreatable(null)
      }
     
     
  
    
  
    
      if(deletesucess && apiName === apinameForMaterialType){
        setValueMaterialType(null)
        setValueMaterialTypeCreatable(null)
      }
      if(deletesucess && apiName === apinameForGumName){
        setValueGumName(null)
        setValueGumNameCreatable(null)
      }

      if(deletesucess && apiName === apinameForBundleQty){
        setValueBundleQty(null)
        setValueBundleQtyCreatable(null)
      }

      if(deletesucess && apiName === apinameForBundleInCarton){
        setValueBundleInCarton(null)
        setValueBundleInCartonCreatable(null)
      }

      if(deletesucess && apiName === apinameForPrintingType){
        setValuePrintingType(null)
        setValuePrintingTypeCreatable(null)
      }

      if(deletesucess && apiName === apinameForProductBy){
        setValueProductBy(null)
        setValueProductByCreatable(null)
      }

      if(deletesucess && apiName === apinameForProductOurOther){
        setValueProductOurOther(null)
        setValueProductOurOtherCreatable(null)
      }

      if(deletesucess && apiName === apinameForTapeRollType){
        setValueTapeRollType(null)
        setValueTapeRollTypeCreatable(null)
      }

      if(deletesucess && apiName === apinameForProductStar){
        setValueProductStar(null)
        setValueProductStarCreatable(null)
      }

      
      if(deletesucess && apiName === apinameForProductLocation){
        setValueProductLocation(null)
        setValueProductLocationCreatable(null)
      }

    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (
  
  
      <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
        <span>{label}</span>
        <div style={{ float: "right", justifyContent: "end" }}>
  
  
  
          {isExist ?
  
            <Custom_Actions_Buttons value={value}
              apiname={deleteApiName}
              mutate={singleDelete}
              // masterName={addButtonName}
              deleteid={deleteid}
              handleDeletedforce={handleDeletedforce}
              closereconfirmdelete={closereconfirmdelete}
              deletemodalask={deletemodalask}
            setEditOpen={setOpenEditDropDownModel}
            // permissionClass={permissionClass}
            />
            : ""}
  
        </div>
      </div>
  
  
    )


    const customCreatableIndicator = {
      IndicatorsContainer: IndicatorsContainerCreate,
      Option: CustomOption
    }


 
    useEffect(() => {
      if (deletesucess === true) { }
      // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
      return () => {
        setDeleteid(undefined);
      };
    }, []);
    const handleConfirmDelete = () => {
      setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
      setConfirmforcemodal(false);
      setDeleteMultipleid([]);
    };
  
    const confirmmodalclose = () => {
      setDeleteConfirmsingle(false);
      setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
      setDeleteConfirmsingle(false);
      singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
      setDeleteConfirmsingle(true);
      setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
      setConfirmforcemodal(false);
      if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
      else if (deleteMultipleSuccess) {
        deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
      }
    };
    useEffect(() => {
      if (forcefullysuccess) {
        setShow(true);
        setshowmessage("success");
      } else if (forcefullyerror) {
        setShow(true);
        setshowmessage("danger");
      } else if (forcefullysingledeletesuccess) {
        setShow(true);
        setshowmessage("success");
      } else if (forcefullydeleteerror) {
        setShow(true);
        setshowmessage("danger");
      }
    }, [
      forcefullysuccess,
      forcefullyerror,
      forcefullysingledeletesuccess,
      forcefullydeleteerror,
    ]);
  
    useEffect(() => {
      if (deleteMultipleSuccess) {
        const showdepenedancymsg = deleteMultiple?.data?.data;
        const dependacyfound = showdepenedancymsg?.filter((color: any) => {
          if (
            color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
          ) {
            return color;
          }
        });
        if (dependacyfound?.length === deleteMultipleid?.length) {
          deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        } else {
          setConfirmforcemodal(true);
        }
      } else if (deleteMultipleIsError) {
        setShow(true);
        setshowmessage("danger");
      } else if (deletesucess) {
        if (deletedata.data?.data?.length > 0) {
          setConfirmforcemodal(true);
        } else if (deleteid) {
          setShow(true);
          setshowmessage("success");
          deleteforce({ deleteid, apiname: deleteApiName });
        }
      }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);
  
    const handleMultipleDeleted = () => {
      deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
      setDeleteConfirm(false);
    };

  return (
    <div>

      <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>Edit Tape Roll</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Product Details</Link></li>
                  <li className="breadcrumb-item"><Link to="/listTaperoll" className="text-decoration-none text-black">Tape Roll</Link></li>
                  <li className="breadcrumb-item active">Edit Tape Roll</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Panni</span></h5>
                            </div> */}
              <form ref={focus}
                onKeyUp={event => onEnterKey(event)}
                onSubmit={handleSubmit(onSubmit)}>
                <div
                  className="card-body"
                  style={{ maxHeight: "80vh", overflow: "auto" }}
                >

                  {show && showmessage === "danger" ? (
                    <Alert
                      color={showmessage}
                      message={error?.response?.data?.message?.name}
                    />
                  ) : (
                    ""
                  )}
                  {/* {show && showmessage === "success" && <Alert color={showmessage} message="Brand successfully Updated" />} */}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Material Name & Type & Category & HSN & GST% <span style={{ color: "red" }}>*</span></label>
                          <div className="d-flex row" >
                            <Select
                              placeholder={'Select Material Name & Type'}
                              {...register("material_name_and_type_and_hsn_code", { required: { value: false, message: "Material Name & Type & Category & HSN & GST% is required" } })}
                              onChange={handleChangeHSN}
                              options={optionsHSN}
                              components={customIndicator}
                              value={
                                selHSN === ""
                                  ? optionsHSN?.find(
                                    (obj: any) =>
                                      obj.value === selectedHSN
                                  )
                                  : selHSN
                              }
                              // value={forHSNValue}
                              // value={selHSN === "" ? optionsHSN?.find((obj: any) => obj.value === selectedHSN) : selHSN}
                              className="dropdown-select"
                            />
                            <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                              <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                setOpenHSN(true)
                              }}><AiOutlinePlus /></Button>
                            </div>

                          </div>
                          <span className='text-red w-100'>{errors?.material_name_and_type_and_hsn_code?.message}</span>


                        </div>

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Material Type <span style={{ color: 'red' }}>*</span></label>
                          <CreatableSelect
                            isClearable={() => {
                              setValueMaterialType(null)
                              setValue(null)
                            }}
                            {...register("material_type", { required: { value: true, message: "Material Type is required" } })}
                            placeholder={'Select Material Type'}
                            // menuIsOpen={true}
                            components={customCreatableIndicator}
                            options={optionsMaterialType}
                            onMenuOpen={()=>MaterialTypeRefetch()}
                            onCreateOption={handleCreateMaterialType}
                            onChange={handleChangeMaterialType}
                            // value={valueMaterialTypeCreatable == null ? valueMaterialType : valueMaterialTypeCreatable}

                            value={valueMaterialTypeCreatable == null ? valueMaterialType == null ? optionsMaterialType?.filter((obj: any) => obj.value === selectedMaterialType) : valueMaterialType : valueMaterialTypeCreatable}


                          />
                          <span className='text-red w-100'>{errors?.material_type?.message}</span>
                        </div>

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Product Color Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="d-flex row" >
                            <Select
                              // id="Panni Name & Grade"
                              {...register("product_color", {
                                required: {
                                  value: true,
                                  message: "Field is required",
                                },
                              })}
                              placeholder={"Product Color Name"}
                              onChange={handleChangeColor}
                              components={customIndicator}

                              options={options}
                              value={
                                selColorName === ""
                                  ? options?.find(
                                    (obj: any) =>
                                      obj.value === selectedColorName
                                  )
                                  : selColorName
                              }
                              className="dropdown-select"

                            />
                            <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                              <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                setColorOpen(true)
                              }}><AiOutlinePlus /></Button>
                            </div>
                          </div>
                          <span className="text-red w-100">
                            {errors?.product_color?.message}
                          </span>
                        </div>

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Product Width mm <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              placeholder="Width mm"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("product_width", {
                                required: {
                                  value: true,
                                  message: "Width mm is required",
                                },
                              })}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine color="white" />
                              </span>
                            </div>
                          </div>
                          <span className="text-red w-100">
                            {errors?.product_width?.message}
                          </span>
                        </div>



                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Product 1 Roll Length Meter <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              placeholder="Width mm"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("product_1_roll_length_meter", {
                                required: {
                                  value: true,
                                  message: "Product 1 Roll Length Meter is required",
                                },
                              })}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine color="white" />
                              </span>
                            </div>
                          </div>
                          <span className="text-red w-100">
                            {errors?.product_1_roll_length_meter?.message}
                          </span>
                        </div>

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Thickness Film Micron
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              placeholder="Thickness Film Micron"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("thickness_film_micron", {
                                onChange: handleThicknessFilmMicron,
                                required: {
                                  value: true,
                                  message: "Thickness Film Micron is required",
                                },
                              })}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine color="white" />
                              </span>
                            </div>
                          </div>
                          <span className="text-red w-100">
                            {errors?.thickness_film_micron?.message}
                          </span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Thickness Gauge
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              disabled
                              placeholder="Thickness Film Micron"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("thickness_gauge", {
                                onChange: handleValue,
                                required: {
                                  value: false,
                                  message: "Thickness Film Micron is required",
                                },
                              })}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine color="white" />
                              </span>
                            </div>
                          </div>
                          <span className="text-red w-100">
                            {errors?.thickness_gauge?.message}
                          </span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            1 Meter Film Net Weight Gram
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              disabled
                              placeholder="Thickness Film Micron"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("tape_roll_1_meter_film_net_weight_gram", {
                                onChange: handleValue,
                                required: {
                                  value: false,
                                  message: "Thickness Film Micron is required",
                                },
                              })}
                              value={0}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine color="white" />
                              </span>
                            </div>
                          </div>
                          <span className="text-red w-100">
                            {errors?.tape_roll_1_meter_film_net_weight_gram?.message}
                          </span>
                        </div>







                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Tape Roll Gum Name<span style={{ color: 'red' }}>*</span></label>
                          <CreatableSelect
                            isClearable={() => {
                              setValueGumName(null)
                              setValue(null)
                            }}
                            {...register("gumname", { required: { value: true, message: "Tape Roll Gum Name is required" } })}
                            placeholder={'Select Tape Roll Gum Name'}
                            // menuIsOpen={true}
                            components={customCreatableIndicator}
                            options={optionsGumName}
                            onMenuOpen={()=>GumNameRefetch()}
                            onCreateOption={handleCreateGumName}
                            onChange={handleChangeGumName}
                            // value={valueGumNameCreatable == null ? valueGumName : valueGumNameCreatable}
                            value={valueGumNameCreatable == null ? valueGumName == null ? optionsGumName?.filter((obj: any) => obj.value === selectedGumName) : valueGumName : valueGumNameCreatable}


                          />
                          <span className='text-red w-100'>{errors?.gumname?.message}</span>
                        </div>

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Gum Ler Micron
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              // disabled
                              placeholder="1 Meter Weight"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("gum_ler_micron", {
                                onChange: handleGumLerMicron,
                                required: {
                                  value: false,
                                  message: "Field is required",
                                },
                              })}
                            />
                          </div>
                          <span className="text-red w-100">
                            {errors?.gum_ler_micron?.message}
                          </span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            1 Meter Fil In Gum Net Gram
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              disabled
                              placeholder="1 Meter Weight"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("tape_roll_1_meter_film_in_gum_net_gram", {
                                onChange: handleValue,
                                required: {
                                  value: false,
                                  message: "Field is required",
                                },
                              })}
                            />
                          </div>
                          <span className="text-red w-100">
                            {errors?.tape_roll_1_meter_film_in_gum_net_gram?.message}
                          </span>
                        </div>



                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            1 Tape Roll Film Net Weight Gram
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              disabled
                              placeholder="1 Meter Weight"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("one_tape_roll_film_net_weight_gram", {
                                onChange: handleValue,
                                required: {
                                  value: false,
                                  message: "Field is required",
                                },
                              })}
                            />
                          </div>
                          <span className="text-red w-100">
                            {errors?.one_tape_roll_film_net_weight_gram?.message}
                          </span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            1 Tape Roll Gum Ler Weight Gram
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              disabled
                              placeholder="1 Meter Weight"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("one_tape_roll_gum_ler_weight_gram", {
                                onChange: handleValue,
                                required: {
                                  value: false,
                                  message: "Field is required",
                                },
                              })}
                            />
                          </div>
                          <span className="text-red w-100">
                            {errors?.one_tape_roll_gum_ler_weight_gram?.message}
                          </span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            1 Tape Roll Film + Gum Weight Gram
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              disabled
                              placeholder="1 Meter Weight"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("one_tape_roll_film_pluse_gum_weight_gram", {
                                onChange: handleValue,
                                required: {
                                  value: false,
                                  message: "Field is required",
                                },
                              })}
                            />
                          </div>
                          <span className="text-red w-100">
                            {errors?.one_tape_roll_film_pluse_gum_weight_gram?.message}
                          </span>
                        </div>



                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Paper Conr Weight Gram <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input

                              placeholder="1 Nos Weight"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("paper_conr_weight_gram", {
                                onChange: handleValue,
                                required: {
                                  value: true,
                                  message: "1 Nos Weight is required",
                                },
                              })}
                            />

                          </div>
                          <span className="text-red w-100">
                            {errors?.paper_conr_weight_gram?.message}
                          </span>
                        </div>

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Tape Roll Bundle Qty<span style={{ color: 'red' }}>*</span></label>
                          <CreatableSelect
                            isClearable={() => {
                              setValueBundleQty(null)
                              setValue(null)
                            }}
                            {...register("bundle_qty", { required: { value: true, message: "Tape Roll Bundle Qty is required" } })}
                            placeholder={'Select Tape Roll Bundle Qty'}
                            // menuIsOpen={true}
                            components={customCreatableIndicator}
                            options={optionsBundleQty}
                            onMenuOpen={()=>BundleQtyRefetch()}
                            onCreateOption={handleCreateBundleQty}
                            onChange={handleChangeBundleQty}
                            // value={valueBundleQtyCreatable == null ? valueBundleQty : valueBundleQtyCreatable}
                            value={valueBundleQtyCreatable == null ? valueBundleQty == null ? optionsBundleQty?.filter((obj: any) => obj.value === selectedBundleQty) : valueBundleQty : valueBundleQtyCreatable}


                          />
                          <span className='text-red w-100'>{errors?.bundle_qty?.message}</span>
                        </div>

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Tape Roll Bundle In Carton<span style={{ color: 'red' }}>*</span></label>
                          <CreatableSelect
                            isClearable={() => {
                              setValueBundleInCarton(null)
                              setValue(null)
                            }}
                            {...register("bundle_in_carton", { required: { value: true, message: "Tape Roll Bundle In Carton is required" } })}
                            placeholder={'Select Tape Roll Bundle In Carton'}
                            // menuIsOpen={true}
                            components={customCreatableIndicator}
                            options={optionsBundleInCarton}
                            onMenuOpen={()=>BundleInCartonRefetch()}
                            onCreateOption={handleCreateBundleInCarton}
                            onChange={handleChangeBundleInCarton}
                            // value={valueBundleInCartonCreatable == null ? valueBundleInCarton : valueBundleInCartonCreatable}
                            value={valueBundleInCartonCreatable == null ? valueBundleInCarton == null ? optionsBundleInCarton?.filter((obj: any) => obj.value === selectedBundleInCarton) : valueBundleInCarton : valueBundleInCartonCreatable}


                          />
                          <span className='text-red w-100'>{errors?.bundle_in_carton?.message}</span>
                        </div>



                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Tape Roll Net KG. In Carton 
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              disabled
                              // placeholder="1 Nos Weight"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("tape_roll_net_kg_in_carton", {
                                onChange: ((e: any) => setTaperollNetKG(e.target.value)),
                                required: {
                                  value: false,
                                  message: "Tape Roll Net KG. In Carton is required",
                                },
                              })}
                              value={0.000}
                            />

                          </div>
                          <span className="text-red w-100">
                            {errors?.tape_roll_net_kg_in_carton?.message}
                          </span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Film Net 1 KG. In Meter 
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              disabled
                              placeholder="Film Net 1 KG. In Meter"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("film_net_1kg_in_meter", {
                                onChange: ((e: any) => setFilmnetKg(e.target.value)),
                                required: {
                                  value: false,
                                  message: "Film Net 1 KG. In Meter is required",
                                },
                              })}
                              value={0.000}
                            />

                          </div>
                          <span className="text-red w-100">
                            {errors?.film_net_1kg_in_meter?.message}
                          </span>
                        </div>



                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Gum 1 Kg. In Ler Meter 
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              disabled
                              placeholder="1 Nos Weight"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("gum_1kg_in_ler_meter", {
                                onChange: handleValue,
                                required: {
                                  value: false,
                                  message: "1 Nos Weight is required",
                                },
                              })}
                              value={0}
                            />

                          </div>
                          <span className="text-red w-100">
                            {errors?.gum_1kg_in_ler_meter?.message}
                          </span>
                        </div>



                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Film + Gum 1 Kg. In Meter 
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              disabled
                              placeholder="1 Nos Weight"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("tape_roll_1_nos_weight", {
                                onChange: handleValue,
                                required: {
                                  value: false,
                                  message: "1 Nos Weight is required",
                                },
                              })}
                              value={0}
                            />

                          </div>
                          <span className="text-red w-100">
                            {errors?.tape_roll_1_nos_weight?.message}
                          </span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Product Printing<span style={{ color: 'red' }}>*</span></label>

                          <select
                            className="form-select"
                            {...register("printing", {
                              onChange: onPrintingChange,
                              required: {
                                value: true,
                                message: "Printing is required",
                              },
                            })}
                          >
                            <option value={""} selected disabled>
                              Select Any
                            </option>
                            <option value={"yes"}>Yes</option>
                            <option value={"no"}>No</option>
                          </select>
                          <span className="text-red w-100">
                            {errors?.printing?.message}
                          </span>
                        </div>
                        {/* {panniPrinting === "yes" && (
                          <> */}




                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Product Printing Type<span style={{ color: 'red' }}>*</span></label>
                              <CreatableSelect
                                isClearable={() => {
                                  setValuePrintingType(null)
                                  setValue(null)
                                }}
                                {...register("printing_type", { required: { value: panniPrinting==='yes'?true:false, message: "Product Printing Type is required" } })}
                                placeholder={'Select Product Printing Type'}
                                // menuIsOpen={true}
                                components={customCreatableIndicator}
                                options={optionsPrintingType}
                                onMenuOpen={()=>PrintingTypeRefetch()}
                                onCreateOption={handleCreatePrintingType}
                                isDisabled={panniPrinting === "no" || ''}
                                onChange={handleChangePrintingType}
                                // value={valuePrintingTypeCreatable == null ? valuePrintingType : valuePrintingTypeCreatable}
                                value={valuePrintingTypeCreatable == null ? valuePrintingType == null ? optionsPrintingType?.filter((obj: any) => obj.value === selectedPrintingType) : valuePrintingType : valuePrintingTypeCreatable}


                              />
                              <span className='text-red w-100'>{errors?.printing_type?.message}</span>
                            </div>






                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Printing Color Name</label>
                              <div className="d-flex row" >
                                <Select


                                  {...register("printing_color", {
                                    required: {
                                      value: panniPrinting==='yes'?true:false,
                                      message: "Color is required",
                                    },
                                  })}
                                  placeholder={"Select Color"}
                                  // value={forColorsValue}
                                  options={optionsPrinting}
                                  onChange={handleChangeColorPrinting}
                                  isMulti={true}
                                  isDisabled={panniPrinting === "no" || ''}

                                  // value={
                                  //   selPrintingColor === ""
                                  //     ? optionsPrinting?.filter(
                                  //         (obj: any) =>
                                  //           obj.value === selectedPrintingColor
                                  //       )
                                  //     : selPrintingColor
                                  // }

                                  value={selPrintingColor.length === 0 ? optionsPrinting?.filter((obj: any) => selectedPrintingColor.includes(obj.value)) : selPrintingColor}


                                  components={customIndicator}

                                  className="dropdown-select"




                                />
                                <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                  <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                    setPrintingColorOpen(true)
                                  }}><AiOutlinePlus /></Button>
                                </div>

                              </div>
                              <span className='text-red w-100'>{errors?.printing_color?.message}</span>


                            </div>






                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Printing Total Color
                              </label>
                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                                <input
                                  type="text"
                                  // value={ColorTotal}
                                  disabled
                                  className="form-control"
                                  id="exampleInputBrand"
                                  placeholder=" 0"
                                  {...register("printing_total_color", {
                                    required: false,
                                  })}
                                />
                              </div>
                              <span className="text-red w-100">
                                {errors?.printing_total_color?.message}
                              </span>
                            </div>






                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Printing Brand Name

                              </label>
                              <div className="d-flex row" >

                                <Select
                                  {...register("brand", {
                                    required: {
                                      value: panniPrinting==='yes'?true:false,
                                      message: "Brand is required",
                                    },
                                  })}
                                  placeholder={"Select Brand Name"}
                                  value={
                                    selPrintingBrand === ""
                                      ? optionsbrand?.find(
                                        (obj: any) =>
                                          obj.value === selectedPrintingBrand
                                      )
                                      : selPrintingBrand
                                  }
                                  components={customIndicator}
                                  onChange={handleChangeBrand}
                                  options={optionsbrand}
                                  isDisabled={panniPrinting === "no" || ''}

                                  className="dropdown-select"

                                />
                                <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                  <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                    setBrandOpen(true)
                                  }}><AiOutlinePlus /></Button>
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.brand?.message}
                              </span>
                            </div>


                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Brand Our-Other</label>
                              <select disabled className="form-select" {...register("brand_our_other", { required: { value: false, message: "Brand Type is required" } })}>
                                <option value={''}>Select Any</option>
                                <option selected={colorNamePanni?.type === "our" ? true : false} value="our">Our</option>
                                <option selected={colorNamePanni?.type === "other" ? true : false} value="other">Other</option>
                              </select>
                              {/* <span className='text-red w-100'>{errors?.color?.message}</span> */}
                            </div>
                          {/* </>
                        )} */}






                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Carton Stock Minimum Qty<span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              placeholder="1 Bundle Nos"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("carton_stock_minimum_qty", {
                                onChange: handleValue,
                                required: {
                                  value: true,
                                  message: "1 Bundle Nos is required",
                                },
                              })}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine color="white" />
                              </span>
                            </div>
                          </div>
                          <span className="text-red w-100">
                            {errors?.carton_stock_minimum_qty?.message}
                          </span>
                        </div>
                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Carton Stock Maximum Qty<span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              placeholder="1 Bundle Nos"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("carton_stock_maximum_qty", {
                                onChange: handleValue,
                                required: {
                                  value: true,
                                  message: "1 Bundle Nos is required",
                                },
                              })}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine color="white" />
                              </span>
                            </div>
                          </div>
                          <span className="text-red w-100">
                            {errors?.carton_stock_maximum_qty?.message}
                          </span>
                        </div>

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Bursting Strength<span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              placeholder="1 Bundle Nos"
                              type="text"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("bursting_strength", {
                                onChange: handleValue,
                                required: {
                                  value: true,
                                  message: "1 Bundle Nos is required",
                                },
                              })}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine color="white" />
                              </span>
                            </div>
                          </div>
                          <span className="text-red w-100">
                            {errors?.bursting_strength?.message}
                          </span>
                        </div>



                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Product By<span style={{ color: 'red' }}>*</span></label>
                          <CreatableSelect
                            isClearable={() => {
                              setValueProductBy(null)
                              setValue(null)
                            }}
                            {...register("product_by", { required: { value: true, message: "Product By is required" } })}
                            placeholder={'Select Product By'}
                            // menuIsOpen={true}
                            components={customCreatableIndicator}
                            options={optionsProductBy}
                            onMenuOpen={()=>ProductByRefetch()}
                            onCreateOption={handleCreateProductBy}
                            onChange={handleChangeProductBy}
                            // value={valueProductByCreatable == null ? valueProductBy : valueProductByCreatable}
                            value={valueProductByCreatable == null ? valueProductBy == null ? optionsProductBy?.filter((obj: any) => obj.value === selectedProductBy) : valueProductBy : valueProductByCreatable}


                          />
                          <span className='text-red w-100'>{errors?.product_by?.message}</span>
                        </div>




                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Product Our-Other<span style={{ color: 'red' }}>*</span></label>
                          <CreatableSelect
                            isClearable={() => {
                              setValueProductOurOther(null)
                              setValue(null)
                            }}
                            {...register("product_our_other", { required: { value: true, message: "Product Our-Other is required" } })}
                            placeholder={'Select Product Our-Other'}
                            // menuIsOpen={true}
                            components={customCreatableIndicator}
                            onMenuOpen={()=>ProductOurOtherRefetch()}
                            options={optionsProductOurOther}
                            onCreateOption={handleCreateProductOurOther}
                            onChange={handleChangeProductOurOther}
                            // value={valueProductOurOtherCreatable == null ? valueProductOurOther : valueProductOurOtherCreatable}

                            value={valueProductOurOtherCreatable == null ? valueProductOurOther == null ? optionsProductOurOther?.filter((obj: any) => obj.value === selectedOurOther) : valueProductOurOther : valueProductOurOtherCreatable}


                          />
                          <span className='text-red w-100'>{errors?.product_our_other?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Tape Roll Type<span style={{ color: 'red' }}>*</span></label>
                          <CreatableSelect
                            isClearable={() => {
                              setValueTapeRollType(null)
                              setValue(null)
                            }}
                            {...register("taperoll_type", { required: { value: true, message: "Tape Roll Type is required" } })}
                            placeholder={'Select Tape Roll Type'}
                            // menuIsOpen={true}
                            components={customCreatableIndicator}
                            onMenuOpen={()=>TapeRollTypeRefetch()}
                            options={optionsTapeRollType}
                            onCreateOption={handleCreateTapeRollType}
                            onChange={handleChangeTapeRollType}
                            // value={valueTapeRollTypeCreatable == null ? valueTapeRollType : valueTapeRollTypeCreatable}

                            value={valueTapeRollTypeCreatable == null ? valueTapeRollType == null ? optionsTapeRollType?.filter((obj: any) => obj.value === selectedTapeRollType) : valueTapeRollType : valueTapeRollTypeCreatable}


                          />
                          <span className='text-red w-100'>{errors?.taperoll_type?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Product Maximum Debit Day<span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              placeholder="1 Bundle Nos"
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("product_maximum_debit_day", {
                                onChange: handleValue,
                                required: {
                                  value: true,
                                  message: "1 Bundle Nos is required",
                                },
                              })}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine color="white" />
                              </span>
                            </div>
                          </div>
                          <span className="text-red w-100">
                            {errors?.product_maximum_debit_day?.message}
                          </span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Product Star<span style={{ color: 'red' }}>*</span></label>
                          <CreatableSelect
                            isClearable={() => {
                              setValueProductStar(null)
                              setValue(null)
                            }}
                            {...register("product_star", { required: { value: true, message: "Product Star is required" } })}
                            placeholder={'Select Product Star'}
                            // menuIsOpen={true}
                            components={customCreatableIndicator}
                            options={optionsProductStar}
                            onMenuOpen={()=>ProductStarRefetch()}
                            onCreateOption={handleCreateProductStar}
                            onChange={handleChangeProductStar}
                            // value={valueProductStarCreatable == null ? valueProductStar : valueProductStarCreatable}

                            value={valueProductStarCreatable == null ? valueProductStar == null ? optionsProductStar?.filter((obj: any) => obj.value === selectedProductStar) : valueProductStar : valueProductStarCreatable}


                          />
                          <span className='text-red w-100'>{errors?.product_star?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Product Location<span style={{ color: 'red' }}>*</span></label>
                          <CreatableSelect
                            isClearable={() => {
                              setValueProductLocation(null)
                              setValue(null)
                            }}
                            {...register("product_location", { required: { value: true, message: "Product Location is required" } })}
                            placeholder={'Select Product Location'}
                            // menuIsOpen={true}
                            components={customCreatableIndicator}
                            onMenuOpen={()=>ProductLocationRefetch()}
                            options={optionsProductLocation}
                            onCreateOption={handleCreateProductLocation}
                            onChange={handleChangeProductLocation}
                            // value={valueProductLocationCreatable == null ? valueProductLocation : valueProductLocationCreatable}

                            value={valueProductLocationCreatable == null ? valueProductLocation == null ? optionsProductLocation?.filter((obj: any) => obj.value === selectedProductLocation) : valueProductLocation : valueProductLocationCreatable}


                          />
                          <span className='text-red w-100'>{errors?.product_location?.message}</span>
                        </div>

















                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Re-Mark
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              type="text"
                              placeholder="Re-Mark "
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("remark", {
                                onChange: handleValue,
                                required: {
                                  value: false,
                                  message: "Remark is required",
                                },
                              })}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine color="white" />
                              </span>
                            </div>
                          </div>
                          <span className="text-red w-100">
                            {errors?.remark?.message}
                          </span>
                        </div>

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Status <span className="text-red">*</span>
                          </label>
                          <select
                            className="form-select"
                            {...register("status", {
                              required: {
                                value: true,
                                message: "Status is required",
                              },
                              maxLength: 30,
                            })}
                          >
                            <option
                              value=""
                              selected
                            >
                              -Status-
                            </option>
                            <option
                              value="active"
                            >
                              Active
                            </option>
                            <option
                              value="inactive"
                            >
                              Inactive
                            </option>
                          </select>

                          <span className="text-red">
                            {errors?.status?.message}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex">
                    <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                      <div className="row d-flex ">
                        <div className="col-sm-3 mt-2 image-upload">
                          <label htmlFor="exampleInputEmail1" className="col-12">Carton Packing Image All</label>


                          {uploadCartonPackingImage?.length > 0 ?

                            <>
                              <Upload
                                {...register("carton_packing_image")}
                                accept="image/png, image/jpeg"
                                listType="picture"
                                onChange={cartonPackingChange}
                                multiple={true}
                                defaultFileList={[...uploadCartonPackingImage]}
                                onPreview={(e: any) => { return "" }}
                                key={"image"}

                              >
                                <img src={cameraLogo} alt='' className="uploaded-image" />
                              </Upload>
                            </> :
                            <>
                              <Upload
                                {...register("carton_packing_image")}
                                accept="image/png, image/jpeg"
                                listType="picture"
                                onChange={cartonPackingChange}
                                multiple={true}
                                onPreview={(e: any) => { return "" }}

                              >
                                <img src={cameraLogo} alt='' className="uploaded-image" />
                              </Upload>
                            </>
                          }


                          {/* <Upload
                                                        {...register("carton_packing_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={cartonPackingChange}
                                                        multiple={true}
                                                        defaultFileList={uploadFile}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload> */}
                        </div>

                        <div className="col-sm-3 mt-2 image-upload">
                          <label htmlFor="exampleInputEmail1" className="col-12">Product Packet Packing Image All</label>

                          {uploadProductPacketPacking?.length > 0 ?

                            <>
                              <Upload
                                {...register("product_packet_packing_image")}
                                accept="image/png, image/jpeg"
                                listType="picture"
                                onChange={ProductPacketPackingOnChange}
                                multiple={true}
                                defaultFileList={[...uploadProductPacketPacking]}
                                onPreview={(e: any) => { return "" }}
                                key={"image"}

                              >
                                <img src={cameraLogo} alt='' className="uploaded-image" />
                              </Upload>
                            </> :
                            <>
                              <Upload
                                {...register("product_packet_packing_image")}
                                accept="image/png, image/jpeg"
                                listType="picture"
                                onChange={ProductPacketPackingOnChange}
                                multiple={true}
                                onPreview={(e: any) => { return "" }}

                              >
                                <img src={cameraLogo} alt='' className="uploaded-image" />
                              </Upload>
                            </>
                          }

                          {/* <Upload
                                                        {...register("product_packet_packing_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={ProductOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadProductImage}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload> */}
                        </div>

                        <div className="col-sm-3 mt-2 image-upload">
                          <label htmlFor="exampleInputEmail1" className="col-12">Product Image All</label>

                          {uploadProductImage?.length > 0 ?

                            <>
                              <Upload
                                {...register("product_image")}
                                accept="image/png, image/jpeg"
                                listType="picture"
                                onChange={ProductImageOnChange}
                                multiple={true}
                                defaultFileList={[...uploadProductImage]}
                                onPreview={(e: any) => { return "" }}
                                key={"image"}

                              >
                                <img src={cameraLogo} alt='' className="uploaded-image" />
                              </Upload>
                            </> :
                            <>
                              <Upload
                                {...register("product_image")}
                                accept="image/png, image/jpeg"
                                listType="picture"
                                onChange={ProductImageOnChange}
                                multiple={true}
                                onPreview={(e: any) => { return "" }}

                              >
                                <img src={cameraLogo} alt='' className="uploaded-image" />
                              </Upload>
                            </>
                          }
                          {/* <Upload
                                                        {...register("product_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={ProductDrawingOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadProductDrawing}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload> */}
                        </div>
                        <div className="col-sm-3 mt-2 image-upload">
                          <label htmlFor="exampleInputEmail1" className="col-12">Product PDF Drawing All</label>

                          {uploadProductPDF?.length > 0 ?
                            <>
                              <Upload
                                {...register("product_pdf_drawing")}
                                accept=".doc,.docx,.xml,.pdf"
                                listType="text"
                                onChange={ProductPDFOnChange}
                                multiple={true}
                                defaultFileList={[...uploadProductPDF]}
                                onPreview={(e: any) => { return "" }}
                                key={"pdf"}
                              >
                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                {/* <p>{logoPdf == null ? data?.pdf?.split("/").pop() : logoPdf}</p> */}

                              </Upload>
                            </> : <>
                              <Upload
                                {...register("product_pdf_drawing")}
                                accept=".doc,.docx,.xml,.pdf"
                                listType="text"
                                onChange={ProductPDFOnChange}
                                multiple={true}
                                onPreview={(e: any) => { return "" }}
                              >
                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                {/* <p>{logoPdf == null ? data?.pdf?.split("/").pop() : logoPdf}</p> */}

                              </Upload>
                            </>
                          }
                          {/* <Upload
                                                        {...register("product_pdf_drawing")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={DocOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadFileDoc}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex  ">
                    <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                      <div className="row d-flex ">
                        <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">

                          <button
                            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                            onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>
                          {/* <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button> */}

                          <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listTaperoll")}>Cancel</button>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      {/* <Footer /> */}
      <Material_Density_Model refetchForm={materialdensityRefetch} open={open} setOpen={setOpen} setSubmitted={setSubmitted} />
      <Color_Model refetchForm={colorRefetch} open={colorOpen} setOpen={setColorOpen} setSubmitted={setSubmittedColor} />
      <Color_Model refetchForm={colorRefetch} open={printingColorOpen} setOpen={setPrintingColorOpen} setSubmitted={setSubmittedPrintingColor} />
      <Brand_Model refetchForm={brandRefetch} open={brandOpen} setOpen={setBrandOpen} setSubmitted={setSubmittedBrand} />
      <HSN_Model refetchForm={HSNRefetch} open={openHSN} setOpen={setOpenHSN} setSubmitted={setSubmitted} />






      <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
        centered    >
        <Modal.Header closeButton>
          <Modal.Title>Select a Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
            <div className="row">
              <div className="col-sm-12 mt-2">
                <label htmlFor="exampleInputEmail1">Choose Date</label>
                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
          <button
            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
            onClick={handleSubmit(handleConfirm)}
          >Confirm</button>
        </Modal.Footer>
      </Modal>
      <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
        yesupdatemodal={yesupdatemodal}
        cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />



    {/* Edit Modal HTML */}
    <Modal
        show={deleteConfirm}
        onHide={() => setDeleteConfirm(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete {""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {" "}
            <span className="text-center">Sure you want to delete it?</span>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleteConfirm(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="info"
            className="text-white"
            onClick={handleMultipleDeleted}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Deleteforce
        key={renderKey}
        handleDeletedforce={handleDeletedforce}
        reconfirmdelete={confirmforcemodal}
        masterName={""}
        closereconfirmdelete={closereconfirmdelete}
        referance={
          deletesucess
            ? deletedata
            : ""
        }
        apiname={apiName}
      />
      <Deletesingle
        key={renderKey}
        confirmmodal={deleteConfirmsingle}
        confirmmodalclose={confirmmodalclose}
        masterName={""}
        confirmmodalsubmit={confirmmodalsubmit}
        apiname={apiName}
      />

      <SimpleInput_Edit key={renderKey} 
      refetchForm={handleRefetch} 
      open={OpenEditDropDownModel} 
      setOpen={setOpenEditDropDownModel} 
      id={editId} 
      // superAccountId={superAccountId} 
      // optionsSuperAccount={optionsSuperAccount}
      // isSuperAccount={isSuperAccount}
      apiname={apiName} />





    </div>
  )
}

export default Edit_TapeRoll

// function colorRefetch() {
//   throw new Error("Function not implemented.");
// }

