import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Add_Leave from '../pages/HRMS/Leave Policy/Add_Leave'
import List_Leave from '../pages/HRMS/Leave Policy/List_Leave'
import Add_Account from '../pages/HRMS/Account/Add_Account'
import List_Account from '../pages/HRMS/Account/List_Account'
import Add_Announcement from '../pages/HRMS/Announcement/Add_Announcement'
import List_Announcement from '../pages/HRMS/Announcement/List_Announcement'
import Add_Project from '../pages/HRMS/Project/Add_Project'
import List_Project from '../pages/HRMS/Project/List_Project'
import List_Milestone from '../pages/HRMS/Milestone/List_Milestone'
import Add_Milestone from '../pages/HRMS/Milestone/Add_Milestone'
import Add_Task_Priority from '../pages/HRMS/Task Priority/Add_Task_Priority'
import List_Task_Priority from '../pages/HRMS/Task Priority/List_Task_Priority'
import List_Taskstatus from '../pages/HRMS/Task Status/List_Taskstatus'
import Add_Taskstatus from '../pages/HRMS/Task Status/Add_Taskstatus'
import Add_Attendance from '../pages/HRMS/Attendance/Add_Attendance'
import List_Attendance from '../pages/HRMS/Attendance/List_Attendance'
import Add_Award from '../pages/HRMS/Award/Add_Award'
import List_Award from '../pages/HRMS/Award/List_Award'
import Add_Email from '../pages/HRMS/Email/Add_Email'
import List_Email from '../pages/HRMS/Email/List_Email'
import List_Company_Setting from '../pages/HRMS/Company Setting/List_Company_Setting'
import Add_Company_Setting from '../pages/HRMS/Company Setting/Add_Company_Setting'
import Add_Task from '../pages/HRMS/Task/Add_Task'
import List_Task from '../pages/HRMS/Task/List_Task'
import Add_Weekly_Holiday from '../pages/HRMS/Weekly Holiday/Add_Weekly_Holiday'
import List_Weekly_Holiday from '../pages/HRMS/Weekly Holiday/List_Weekly_Holiday'
import Add_Public_Holiday from '../pages/HRMS/Public Holiday/Add_Public_Holiday'
import List_Public_Holiday from '../pages/HRMS/Public Holiday/List_Public_Holiday'
import List_Team from '../pages/HRMS/Project Team/List_Team'
import Add_Team from '../pages/HRMS/Project Team/Add_Team'
import List_Leave_Application from '../pages/HRMS/Leave Application/List_Leave_Application'
import Add_Leave_Application from '../pages/HRMS/Leave Application/Add_Leave_Application'
import Add_Transaction from '../pages/HRMS/Transaction/Add_Transaction'
import List_Transaction from '../pages/HRMS/Transaction/List_Transaction'
import Edit_Account from '../pages/HRMS/Account/Edit_Account'
import Edit_Announcement from '../pages/HRMS/Announcement/Edit_Announcement'
import Edit_Company_Setting from '../pages/HRMS/Company Setting/Edit_Company_Setting'
import Edit_Project from '../pages/HRMS/Project/Edit_Project'
import Edit_Milestone from '../pages/HRMS/Milestone/Edit_Milestone'
import Edit_Task_Priority from '../pages/HRMS/Task Priority/Edit_Task_Priority'
import Edit_Taskstatus from '../pages/HRMS/Task Status/Edit_Taskstatus'
import Edit_Leave from '../pages/HRMS/Leave Policy/Edit_Leave'
import Edit_Award from '../pages/HRMS/Award/Edit_Award'
import Edit_Email from '../pages/HRMS/Email/Edit_Email'
import Edit_Leave_Application from '../pages/HRMS/Leave Application/Edit_Leave_Application'
import Edit_Attendance from '../pages/HRMS/Attendance/Edit_Attendance'
import Accounts from './Accounts'
import Edit_Team from '../pages/HRMS/Project Team/Edit_Team'
import Edit_Public_Holiday from '../pages/HRMS/Public Holiday/Edit_Public_Holiday'
import Edit_Weekly_Holiday from '../pages/HRMS/Weekly Holiday/Edit_Weekly_Holiday'
import Edit_Transaction from '../pages/HRMS/Transaction/Edit_Transaction'
import Task_Board from '../pages/HRMS/Task/Task_Board'
import Calculate_Payroll from '../pages/HRMS/Payroll/Calculate_Payroll'



const HRMS = () => {
    return (
        <div>
            <div className='wrapper'>
                <Routes>
                    <Route path='addHrmsAccount' element={
                        <Add_Account />
                    } />
                    <Route path='hrms/listAccount' element={
                        <List_Account />
                    } />
                    <Route path='editsubaccount/:id' element={
                        <Edit_Account />
                    } />

                    <Route path='addAnnouncement' element={
                        <Add_Announcement />
                    } />
                    <Route path='listAnnouncement' element={
                        <List_Announcement />
                    } />
                    <Route path='editannouncement/:id' element={
                        <Edit_Announcement />
                    } />

                    <Route path='addLeavePolicy' element={
                        <Add_Leave />
                    } />
                    <Route path='listLeavePolicy' element={
                        <List_Leave />
                    } />
                    <Route path='editleavePolicy/:id' element={
                        <Edit_Leave />
                    } />

                    <Route path='addCompanySetting' element={
                        <Add_Company_Setting />
                    } />
                    <Route path='listCompanySetting' element={
                        <List_Company_Setting />
                    } />
                    <Route path='editappsetting/:id' element={
                        <Edit_Company_Setting />
                    } />

                    <Route path='addProject' element={
                        <Add_Project />
                    } />
                    <Route path='listProject' element={
                        <List_Project />
                    } />
                    <Route path='editproject/:id' element={
                        <Edit_Project />
                    } />

                    <Route path='addMilestone' element={
                        <Add_Milestone />
                    } />
                    <Route path='listMilestone' element={
                        <List_Milestone />
                    } />
                    <Route path='editmilestone/:id' element={
                        <Edit_Milestone />
                    } />

                    <Route path='addTaskPriority' element={
                        <Add_Task_Priority />
                    } />
                    <Route path='listTaskPriority' element={
                        <List_Task_Priority />
                    } />
                    <Route path='edithrmsPriority/:id' element={
                        <Edit_Task_Priority />
                    } />

                    <Route path='addTaskStatus' element={
                        <Add_Taskstatus />
                    } />
                    <Route path='listTaskStatus' element={
                        <List_Taskstatus />
                    } />
                    <Route path='edittaskStatus/:id' element={
                        <Edit_Taskstatus />
                    } />

                    <Route path='addAttendance' element={
                        <Add_Attendance />
                    } />
                    <Route path='listAttendance' element={
                        <List_Attendance />
                    } />
                    <Route path='editattendance/:id' element={
                        <Edit_Attendance />
                    } />

                    <Route path='addAward' element={
                        <Add_Award />
                    } />
                    <Route path='listAward' element={
                        <List_Award />
                    } />
                    <Route path='editaward/:id' element={
                        <Edit_Award />
                    } />

                    <Route path='addEmail' element={
                        <Add_Email />
                    } />
                    <Route path='listEmail' element={
                        <List_Email />
                    } />
                    <Route path='editemailConfig/:id' element={
                        <Edit_Email />
                    } />

                    <Route path='addWeeklyHoliday' element={
                        <Add_Weekly_Holiday />
                    } />
                    <Route path='listWeeklyHoliday' element={
                        <List_Weekly_Holiday />
                    } />
                    <Route path='editweeklyHoliday/:id' element={
                        <Edit_Weekly_Holiday />
                    } />

                    <Route path='addPublicHoliday' element={
                        <Add_Public_Holiday />
                    } />
                    <Route path='listPublicHoliday' element={
                        <List_Public_Holiday />
                    } />
                    <Route path='editpublicHoliday/:id' element={
                        <Edit_Public_Holiday />
                    } />

                    <Route path='addTeam' element={
                        <Add_Team />
                    } />
                    <Route path='listTeam' element={
                        <List_Team />
                    } />
                    <Route path='editprojectTeam/:id' element={
                        <Edit_Team />
                    } />

                    <Route path='addLeaveApplication' element={
                        <Add_Leave_Application />
                    } />
                    <Route path='listLeaveApplication' element={
                        <List_Leave_Application />
                    } />
                    <Route path='editleaveApplication/:id' element={
                        <Edit_Leave_Application />
                    } />

                    <Route path='addTransaction' element={
                        <Add_Transaction />
                    } />
                    <Route path='listTransaction' element={
                        <List_Transaction />
                    } />
                    <Route path='edittransaction/:id' element={
                        <Edit_Transaction />
                    } />
                    
                    {/* <Route path='addHrmsTask' element={
                        <Add_Task />
                    } />
                    <Route path='listHrmsTask' element={
                        <List_Task />
                    } /> */}
                    <Route path='TaskBoard/:id' element={
                        <Task_Board />
                    } />
                    <Route path='CalculatePayroll' element={
                        <Calculate_Payroll />
                    } />



                    <Route path='/*' element={

                        <Accounts />

                    } />

                </Routes>
            </div>

        </div>
    )
}

export default HRMS
