import { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import InputField from '../../../components/HRMS Components/Inpute/input';
import { useCreate } from '../../../hooks/Api';
import useFocusOnEnter from '../../../components/focus';
import SubmitField from '../../../components/HRMS Components/Button/Submit';
import { Alert } from '../../../components/Model/Model';
import ImageUplaod from '../../../components/HRMS Components/Upload/Image';
import type { UploadFile } from 'antd/es/upload/interface';

const Add_Company_Setting = () => {

  let pageName = "Company Setting"
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_HRMS_APP_SETTING_API?.toString();

  const { mutate: gst, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname);
  const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

  const handleReset = () => {
    reset();
  };

  const onSubmit = (branddetail: any) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(branddetail)) {
      const formvalue: any = value;
      if (key === "logo") {
        for (let i = 0; i < logo.length; i++) {

          formData.append('logo', logo[i])
        }
        continue
      }
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue[0]);
      }
      if (typeof value === "object" && value !== null) {
        formData.append(key, "");
      }
    }
    gst(formData);
  };


  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setShow(true);
      setshowmessage("success");
      navigate("/listCompanySetting", { state: { showToast: true } });
    } else if (isError) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [data, error]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);

  const [uploadFile, setUploadFile] = useState<UploadFile[]>([])
  const [logo, setlogo]: any = useState([])

  const FileOnChange = (e: any) => {
    e.file.status = "done"

    const tempFiles = e.fileList.map((file: any) => {
      return file.originFileObj
    })
    setlogo(tempFiles)
    setUploadFile(e.fileList)

  }

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/listColor" className="text-decoration-none text-black">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black"> HRMS </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/listCompanySetting" className="text-decoration-none text-black">{pageName}</Link>
                  </li>
                  <li className="breadcrumb-item">Add {pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form ref={focus} onKeyUp={(event) => onEnterKey(event)} onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body" >
                  {show && showmessage === "danger" ? (<Alert color={showmessage} message={error?.response?.data?.message} />) : ("")}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        <InputField type='text' name='company_name' label='Company Name' isPencil={true} message='Company Name is required' register={register} isRequired={true} hrmserrors={errors?.companyname?.message} />
                        <InputField type='text' name='tagline' label='Tag Line' isPencil={true} message='Tag Line is required' register={register} isRequired={true} hrmserrors={errors?.tagline?.message} />
                        <InputField type='text' name='address' label='Address' isPencil={true} message='Address is required' register={register} isRequired={true} hrmserrors={errors?.address?.message} />
                        <InputField type='number' name='phone' label='Phone Number' isPencil={true} message='Phone Number is required' register={register} isRequired={true} hrmserrors={errors?.phone?.message} />
                        <InputField type='email' name='email' label='Email Address' isPencil={true} message='Email Address is required' register={register} isRequired={true} hrmserrors={errors?.email?.message} />
                        <InputField type='text' name='website' label='Website' isPencil={true} message='Website is required' register={register} isRequired={true} hrmserrors={errors?.website?.message} />
                        <InputField type='text' name='footer' label='Footer' isPencil={true} message='Footer is required' register={register} isRequired={true} hrmserrors={errors?.footer?.message} />
                        <div className="row d-flex">
                          <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                            <div className="row d-flex ">
                              <ImageUplaod label='Upload Logo' name="logo" message='Upload Logo is required' register={register} isRequired={false} isMulti={false} onChange={(e: any) => FileOnChange(e)} Selvalue={uploadFile} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <SubmitField Submit={handleSubmit(onSubmit)} Reset={handleReset} Cancel={() => navigate("/listCompanySetting")} />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Add_Company_Setting